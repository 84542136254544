import React, { useState } from 'react'
import { Box, Collapse } from '@material-ui/core'
import { Avatar } from 'packages/eid-ui'
import config from 'config'
import { isNilOrEmpty, useAuthState } from 'packages/core'
import {
    useCommentStyles,
    IconContainer,
    Conatiner,
    RepliesConatiner,
    InputContainer,
    Label,
    ShowHideRepliesButton,
    ShowReplyFieldButton,
} from './utils'
import CommentValue from './CommentValue'
import CommentDetails from './CommentDetails'
import Replies from './Replies'

const Comment = ({
    commentorPersonFriendlyName,
    commentorPersonId,
    pathToImage,
    id,
    value,
    participantDecisionFriendlyName,
    participantTypeFriendlyName,
    step,
    commentStepFriendlyName,
    createdDateUtc,
    lastEditDateUtc,
    myComment,
    shouldAllowReplies,
    loading,
    replies,
    handleReply,
    handleEditComment,
    handleDeleteComment,
    decisionAction,
}) => {
    const [{ currentUserId }] = useAuthState()
    const [editComment, setEditComment] = useState(false)
    const classes = useCommentStyles(myComment, participantDecisionFriendlyName)

    const [collapse, setCollapse] = useState(false)

    const dateTimeToShow = lastEditDateUtc ? lastEditDateUtc : createdDateUtc

    const editable =
        !editComment && commentorPersonId === currentUserId && handleEditComment
    const deletable =
        commentorPersonId === currentUserId &&
        isNilOrEmpty(decisionAction) &&
        replies?.length === 0 &&
        handleDeleteComment

    const actionRow = (
        <Box className={classes.actionRowContainer}>
            <Box width="160px" display="flex" alignItems="center">
                {replies?.length > 0 && (
                    <ShowHideRepliesButton
                        collapse={collapse}
                        setCollapse={setCollapse}
                        count={replies?.length}
                    />
                )}
            </Box>

            <Box className={classes.actionRowLabel}>
                <Label className={classes.stepLabel}>{step}</Label>
                <Label className={classes.stepValue}>
                    {commentStepFriendlyName}
                </Label>
            </Box>

            <Box width="170px" display="flex">
                {!collapse && (
                    <ShowReplyFieldButton setCollapse={setCollapse} />
                )}
            </Box>
        </Box>
    )

    const editIcon = (
        <IconContainer
            name="Edit"
            onClick={() => setEditComment((prev) => !prev)}
        />
    )
    const deleteIcon = (
        <IconContainer name="Delete" onClick={() => handleDeleteComment(id)} />
    )

    return (
        <Conatiner className={classes.container}>
            <InputContainer className={classes.input} width="40%">
                <CommentDetails
                    classes={classes}
                    loading={loading}
                    commentorPersonFriendlyName={commentorPersonFriendlyName}
                    participantDecisionFriendlyName={
                        participantDecisionFriendlyName
                    }
                    participantTypeFriendlyName={participantTypeFriendlyName}
                    dateTimeToShow={dateTimeToShow}
                    lastEditDateUtc={lastEditDateUtc}
                    editIcon={editable && editIcon}
                    deleteIcon={deletable && deleteIcon}
                />

                <CommentValue
                    id={id}
                    value={value}
                    commentStepFriendlyName={commentStepFriendlyName}
                    classes={classes}
                    loading={loading}
                    step={step}
                    editComment={editComment}
                    setEditComment={setEditComment}
                    handleEditComment={handleEditComment}
                />

                {shouldAllowReplies && (
                    <RepliesConatiner>
                        {actionRow}

                        <Collapse
                            in={Boolean(collapse)}
                            timeout={300}
                            unmountOnExit
                        >
                            <Replies
                                currentUserId={currentUserId}
                                replies={replies}
                                handleDeleteComment={handleDeleteComment}
                                handleEditComment={handleEditComment}
                                handleReply={handleReply}
                                collapse={collapse}
                                setCollapse={setCollapse}
                            />
                        </Collapse>

                        <Box className={classes.avatarContainer}>
                            {pathToImage && (
                                <Avatar
                                    src={`${config.BASE_EID_URL}${pathToImage}`}
                                    size="large"
                                />
                            )}
                        </Box>
                    </RepliesConatiner>
                )}
            </InputContainer>

            {!shouldAllowReplies && (
                <Box marginLeft="15px" marginRight={myComment ? '0px' : '15px'}>
                    {pathToImage && (
                        <Avatar
                            src={`${config.BASE_EID_URL}${pathToImage}`}
                            size="large"
                        />
                    )}
                </Box>
            )}
        </Conatiner>
    )
}

export default Comment
