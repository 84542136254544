import { CommentsList } from 'components'
import {
    useAddComment,
    useDeleteComment,
    useEditComment,
    useRequestViewItemComments,
} from 'hooks'
import { smallScreenWidth } from 'utils'

const commentsContainerProps = {
    style: {
        maxHeight: '350px',
        overflow: 'auto',
        paddingRight: '20px',
        marginRight: '-15px',
        paddingBottom: '30px',
    },
    width: 'auto',
}

export const ItemComments = ({ requestId, itemId }) => {
    const {
        data: comments,
        isLoading: loadingComments,
    } = useRequestViewItemComments(requestId, itemId)

    const [addComment, { isLoading: isSubmitting }] = useAddComment(
        'Requests',
        requestId,
        itemId,
    )

    const [deleteComment] = useDeleteComment('Requests', requestId, itemId)

    const [editComment] = useEditComment('Requests', requestId, itemId)

    return (
        <CommentsList
            page="Requests"
            commentsContainerProps={commentsContainerProps}
            requestId={requestId}
            itemId={itemId}
            list={comments}
            loading={loadingComments}
            isSubmitting={isSubmitting}
            handleAddComment={(value) =>
                addComment({
                    businessRequestId: requestId,
                    businessRequestItemId: itemId,
                    value,
                })
            }
            handleDeleteComment={(id) => {
                deleteComment(id)
            }}
            handleEditComment={(id, value) => {
                editComment({
                    id,
                    value,
                })
            }}
        />
    )
}
