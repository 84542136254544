const attributes = [
    {
        placement: 'TagRow',
        component: {
            componentName: 'StatusIndicator',
            props: {
                size: 'small',
            },
            name: 'status',
        },
    },

    {
        placement: 'TagRow',
        component: {
            componentName: 'FulfillmentStatusIndicator',
            props: {
                size: 'small',
            },
            name: 'fulfillmentStatusFriendlyName',
        },
    },

    {
        placement: 'TagRow',
        renderBrStatus: true,
    },

    {
        isHeader: true,
        name: 'itemTypeActionFriendlyName',
        label: 'MyTasks_Name',
        sortBy: 'FriendlyName',
        sortable: true,
        tableStyles: {
            headerCellStyles: {
                width: '100%',
                minWidth: '180px',
            },
            styles: {
                width: '100%',
                minWidth: '180px',
                position: 'relative',
                overflow: 'visible',
                color: '#01ae8f',
                fontWeight: 500,
                '&:hover': {
                    textDecoration: 'underline',
                },
            },
        },
        link: false,
    },
    {
        name: 'resourceRequested',
        label: 'MyTasks_TargetResource',
        sortBy: 'ResourceRequested',
        sortable: true,
        tableStyles: {
            headerCellStyles: {
                width: '160px',
                minWidth: '160px',
            },
            styles: {
                width: '160px',
                color: '#282828',
                fontSize: '13px',
            },
        },
    },
    {
        name: 'initiatorPersonFriendlyName',
        label: 'MyTasks_RequestedBy',
        sortBy: 'InitiatorPersonFriendlyName',
        hideValue: true,
        sortable: true,
        avatar: true,
        imgProp: 'initiatorPersonThumbnailUrl',
        tableStyles: {
            headerCellStyles: {
                width: '240px',
                minWidth: '240px',
            },
            styles: {
                width: '240px',
            },
            textStyles: {
                color: '#282828',
                fontSize: '13px',
            },
        },
    },
    {
        name: 'targetPersonFriendlyName',
        label: 'MyTasks_RequestedFor',
        sortBy: 'SubjectFriendlyName',
        hideValue: true,
        sortable: true,
        avatar: false,
        tableStyles: {
            headerCellStyles: {
                width: '240px',
                minWidth: '240px',
            },
            styles: {
                width: '240px',
            },
        },
        component: {
            componentName: 'SubjectType',
            props: {
                size: 'small',
                style: {
                    color: '#282828',
                    fontSize: '13px',
                },
            },
        },
    },
    {
        name: 'businessRequestName',
        label: 'MyTasks_BusinessRequestName',
        sortBy: 'BusinessRequestName',
        sortable: true,
        tableStyles: {
            headerCellStyles: {
                width: '170px',
                minWidth: '170px',
            },
            styles: {
                width: '170px',
                fontSize: '13px',
            },
        },
        cardStyles: {
            color: '#262fa1 !important',
            fontWeight: 'bold ',
        },
    },
    {
        name: 'businessRequestNumber',
        label: 'MyTasks_BusinessRequestNumber',
        sorBy: 'BusinessRequestNumber',
        sortable: true,

        tableStyles: {
            headerCellStyles: {
                width: '150px',
                minWidth: '150px',
            },
            styles: {
                width: '150px',
                color: '#282828',
                fontSize: '13px',
            },
        },
    },
    {
        name: 'createdDateUtc',
        hideValue: true,
        label: 'MyTasks_CreatedDate',
        sortable: true,
        sortBy: 'CreatedDateUtc',
        tableStyles: {
            headerCellStyles: {
                width: '130px',
                minWidth: '130px',
            },
            styles: {
                width: '130px',
            },
        },
        component: { componentName: 'CellDateTime' },
    },
    {
        name: 'Risk',
        hideValue: true,
        label: 'MyTasks_RiskLevel',
        sortable: true,
        sortBy: 'RiskLevel',

        styles: {
            minWidth: '120px',
        },
        component: { componentName: 'RiskIndicator', name: 'riskLevel' },
    },
]

export default attributes
