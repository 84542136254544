import React, { FC } from 'react'
import { Box, Typography } from '@material-ui/core'
import { Avatar } from 'packages/eid-ui'

const styles = {
    name: {
        fontSize: '14px',
        color: '#5d6870',
        fontWeight: 'bold',
    },
    position: {
        textTransform: 'uppercase',
        color: '#9b9b9b',
        fontSize: '12px',
        fontWeight: 'bold',
    },
    status: {
        color: '#01ae8f',
        fontSize: '14px',
    },
    divider: {
        margin: '0px 10px',
        color: '#d8d8dd',
        width: '16px',
        height: '1px',
    },
}

const PersonDetails: FC<any> = (props) => {
    const {
        avatarId,
        imageUrl,
        name,
        moreInfo,
        requestedBy,
        size = 'large',
        statusStyles,
    } = props
    return (
        <Box position="relative" width="100%">
            <Box marginY="7.5px" display="flex" alignItems="center">
                <Box
                    id={avatarId}
                    minWidth={size}
                    marginRight="10px"
                    style={{
                        boxShadow: 'rgb(110 110 111) 0px 0px 8px 0px',
                        borderRadius: '50%',
                    }}
                >
                    <Avatar size={size} src={imageUrl} />
                </Box>
                <Box>
                    {/*@ts-ignore*/}
                    <Typography style={styles.name}>{name}</Typography>

                    <Box display="flex" alignItems="center">
                        <Typography
                            style={{ ...styles.status, ...statusStyles }}
                        >
                            {requestedBy}
                        </Typography>

                        {moreInfo && moreInfo}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default PersonDetails
