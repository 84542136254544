import React from 'react'
import PropTypes from 'prop-types'
import { styled, Box } from '@material-ui/core'
import { TextFormatter } from 'components'

const AttributeLabel = styled(Box)({
    fontSize: '12px',
    lineHeight: 1.33,
    color: '#9b9b9b',
    textTransform: 'uppercase',
    minWidth: '204px',
    display: 'block',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    fontWeight: 'bold',
})

const AttributeValue = styled(Box)({
    fontSize: '14px',
    lineHeight: 1.33,
    color: '#000000',
    width: '100%',
    display: 'block',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
})

const Attribute = ({ label, value, orientation, labelProps, valueProps }) => (
    <Box
        display="flex"
        flexDirection={orientation === 'vertical' ? 'column' : 'row'}
        paddingY="8.5px"
        width="100%"
    >
        <AttributeLabel {...labelProps}>{label}</AttributeLabel>
        <AttributeValue
            marginTop={orientation === 'vertical' ? '8px' : '0px'}
            {...valueProps}
        >
            <TextFormatter value={value} maxCharacters={100} />
        </AttributeValue>
    </Box>
)

Attribute.propTypes = {
    label: PropTypes.any,
    value: PropTypes.any,
    orientation: PropTypes.oneOf(['vertical', 'horizontal']),
}

Attribute.defaultProps = {
    orientation: 'horizontal',
}

export default Attribute
