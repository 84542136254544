import React from 'react'
import { Layout, ScrollToTopButton } from 'packages/eid-ui'
import AppHeader from './AppHeader'
import AppGlobalMenu from './AppGlobalMenu'
import { useApplicationSettings } from 'hooks'
import SubHeader from 'components/SubHeader'
import { useIsSmallScreen, useQuery } from 'packages/core'
import { mobileScreenWidth, tabletScreenWidth } from 'utils'
import { Box, styled } from '@material-ui/core'
import ScrollToTop from 'react-scroll-up'
import { useTranslation } from 'react-i18next'
import config from 'config'

const subHeaderHeight = 70

const SubHeaderContainer = styled(Box)({
    position: 'fixed',
    top: 70,
    width: '100%',
    maxWidth: '1920px',

    display: 'flex',

    paddingTop: '10px',
    paddingRight: '20px',
    paddingLeft: '90px',

    backgroundColor: '#eef0f4',

    zIndex: 5,
})

const createStyles = (headerConfig) => {
    return {
        rootStyles: {
            position: 'relative',
            paddingBottom: '30px',

            overflowY: 'overlay !important',

            //'ms-overflow-y': 'scroll !important',
            [`@media (max-width:${mobileScreenWidth})`]: {
                top: headerConfig.mobile?.height,
            },
            [`@media (min-width:${tabletScreenWidth})`]: {
                top: headerConfig.desktop?.height,
            },
            '@media (min-width:1023px)': {
                top: `calc(${headerConfig.desktop?.height} + ${subHeaderHeight}px)`,
            },
        },

        rootMenuStyles: {
            top: headerConfig.desktop?.height,
            // '@media (max-width:1024px)': {
            //     minWidth: '60px',
            // },
            // '@media (max-width:1024px) and (orientation: landscape)': {
            //     minWidth: '80px',
            // },
        },
    }
}

const scrollToTopButtonStyles = { bottom: 20, right: 20, zIndex: 2 }
const AppLayout = ({ children, totalCount, viewSwitcher }) => {
    const { t } = useTranslation()
    const { data: appConfig } = useApplicationSettings()

    const isSmallScreen = useIsSmallScreen()

    const query = useQuery()
    const id = query.get('id')

    const headerConfig = appConfig.style.header
    const styles = createStyles(headerConfig)
    const globalMenuMobile = id ? <></> : <AppGlobalMenu />

    const scrollToTopComponent = (
        <ScrollToTop showUnder={160} style={scrollToTopButtonStyles}>
            <ScrollToTopButton size="medium" label={t('MyTasks_BackToTop')} />
        </ScrollToTop>
    )

    return (
        <Layout
            header={<AppHeader />}
            globalMenu={
                <Box style={{ marginTop: '24px' }}>
                    {isSmallScreen ? globalMenuMobile : <AppGlobalMenu />}
                </Box>
            }
            rootMenuStyles={styles.rootMenuStyles}
            rootStyles={styles.rootStyles}
            subHeader={
                !isSmallScreen && (
                    <SubHeaderContainer>
                        <SubHeader
                            totalCount={totalCount}
                            viewSwitcher={viewSwitcher}
                        />
                    </SubHeaderContainer>
                )
            }
            children={children}
            scrollToTopComponent={
                config.SHOW_SCROLL_TO_TOP && scrollToTopComponent
            }
        />
    )
}

export default AppLayout
