import React from 'react'
import { Box, styled } from '@material-ui/core'
import { WarningIcon, InfoIcon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'

const MessageContainer = styled(Box)({
    position: 'relative',
    width: '100%',
    minHeight: '48px',
    paddingLeft: '48px',

    backgroundCOlor: '#ffffff',
    color: '#5d6870',
    display: 'flex',
    alignItems: 'center',

    boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',

    borderRadius: '5px',
    border: 'solid 1px #ebebed',
})

const IconContainer = styled(Box)({
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '48px',

    padding: '12px',
    display: 'flex',
    alignItems: 'center',

    borderRadius: '5px 0px 0px 5px',
})

const styles = {
    warning: {
        backgroundImage: 'linear-gradient(135deg, #d19549, #c56539)',
    },
    info: {
        backgroundImage: 'linear-gradient(135deg, #01ae8f, #30c17c)',
    },
}

const ApproversInfoMessage = ({ visibleCount, totalCount }) => {
    const { t } = useTranslation()

    if (totalCount === 0) {
        return (
            <>
                <MessageContainer>
                    <IconContainer style={styles.warning}>
                        <WarningIcon width="24" height="23" color="#ffffff" />
                    </IconContainer>
                    <Box mx="18px">{t('MyTasks_NoApproversFound')}</Box>
                </MessageContainer>
            </>
        )
    } else if (visibleCount < totalCount) {
        return (
            <>
                <MessageContainer>
                    <IconContainer style={styles.info}>
                        <InfoIcon width="24" height="23" color="#ffffff" />
                    </IconContainer>
                    <Box mx="18px">
                        {t('MyTasks_NotAllApproversVisible', {
                            totalCount,
                            visibleCount,
                        })}
                    </Box>
                </MessageContainer>
            </>
        )
    } else {
        return <></>
    }
}

export default ApproversInfoMessage
