import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

const width = 200

const ProgressIndicator = ({ percentageCompleted }) => {
    return (
        <>
            <Box
                border="1px solid #d8d8dd"
                borderRadius="8px"
                bgcolor="#efeff1"
                height="16px"
                width={`${width}px`}
            >
                <Box
                    width={`${width * (percentageCompleted / 100)}px`}
                    height="14px"
                    style={{
                        borderRadius: '8px',
                        boxShadow: '0 2px 8px 0 rgba(46, 192, 124, 0.33)',
                        backgroundImage:
                            'linear-gradient(103deg, #01ae8f, #30c17c)',
                    }}
                />
            </Box>
        </>
    )
}

ProgressIndicator.propTypes = {
    percentageCompleted: PropTypes.number.isRequired,
}

ProgressIndicator.defaultProps = {
    percentageCompleted: 0,
}

export default ProgressIndicator
