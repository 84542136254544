import React from 'react'
import { Box } from '@material-ui/core'

const ApiError = ({ showDetails, error, ...rest }) => {
    
    return (
        <Box {...rest}>
            {!showDetails ? (
                <>Error</>
            ) : (
                <>
                    <Box>
                        Status Code: {error.response.status}, Status Text:{' '}
                        {error.response.statusText}
                    </Box>
                    <Box>Details: {error.response.data}</Box>
                </>
            )}
        </Box>
    )
}

export default ApiError
