import React from 'react'
import { Box, makeStyles, styled } from '@material-ui/core'
import { HorizontalTabs } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { smallScreenWidth } from 'utils'

export const Tabs = styled(({ disabled, ...rest }) => (
    <HorizontalTabs {...rest} />
))(({ disabled, theme }) => ({
    '& .MuiTabs-flexContainer': {
        height: theme.mixins.navBar.minHeight,
    },
    '& .MuiTabs-indicator': {
        backgroundColor: disabled ? 'transparent' : '#307fc1',
        height: '2px',
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
        fontWeight: disabled ? 'normal' : 'bold',
        color: '#000000 !important',
    },

    '& .MuiTab-textColorInherit': {
        color: '#4a4a4a!important',
        opacity: `${1} !important`,
    },
    '& .MuiTab-textColorInherit.Mui-disabled': {
        opacity: `${1} !important`,
        color: '#4a4a4a!important',
    },
}))

export const useTableStyles = makeStyles({
    table: {
        backgroundColor: '#fbfbfd',
    },
    tableRow: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        border: 'none',
        borderBottom: 'solid 1px #d8dadd',
        '& :first-child': {
            textTransform: 'uppercase',
        },
        [`@media (max-width:${smallScreenWidth})`]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            paddingTop: '24px',
        },
    },
    tableHeaderRow: {
        border: 'none',
        borderBottom: 'solid 1px #d8dadd',
        borderTop: 'solid 1px #d8dadd',
    },
    tableRowExpanded: {
        border: ' solid 1px #efeff1',
        borderBottom: 'none',
    },

    tabsCell: {
        padding: '0px !important',
        width: '100%',
        [`@media (max-width:${smallScreenWidth})`]: {
            display: 'flex',
            justifyContent: 'center',
        },
    },

    tableCell: {
        overflowWrap: 'break-word',
        maxWidth: '260px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '0px 10px',
        fontSize: '14px',
        border: 'none',
        borderBottom: '0px',
        color: '#5d6870',
        fontWeight: 'bold',
        '& :first-child': {},
    },

    firstCell: {
        overflowWrap: 'break-word',
        maxWidth: '260px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '0px 10px',
        fontSize: '14px',
        border: 'none',
        borderBottom: '0px',
        color: '#5d6870',
        fontWeight: 'bold',
        '& :first-child': {},
        width: '370px',
        minWidth: '200px',
        paddingLeft: '31px',
        paddingRight: '40px',

        [`@media (max-width:${smallScreenWidth})`]: {
            paddingLeft: '20px',
        },
    },

    expansionCell: {
        padding: '0px',
        border: 'none',
    },

    expansionCellActive: {
        padding: '0px',
        border: 'none',
    },
    expansionRow: {
        border: 'none',
    },
    expansionRowActive: {
        borderBottom: 'none',
        borderTop: 'none',
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },

    tableHeaderCell: {
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        textTransform: 'uppercase',
        color: '#000000 !important',
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '16px',
        padding: '24px 31px',
        fontWeight: 'bold',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',
        '& svg': {
            margin: '0px !important',
        },
    },
    expandButton: {
        textTransform: 'uppercase',
        '&:hover': {
            cursor: 'pointer',
        },
        [`@media (max-width:${smallScreenWidth})`]: {
            position: 'absolute',
            right: '24px',
            top: '24px',
        },
    },
    expandIcon: {
        width: '18px',
        height: '18px',
        marginLeft: '8px',
        borderRadius: '50%',
        border: '2px solid #307fc1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        color: '#307fc1',
        lineHeight: 1.1,
    },
})

export const RoleCount = ({ label, count, color }) => (
    <span className="tabsLabel" style={{ color: '#4a4a4a' }}>
        {label}
        <span style={{ color: color ? color : '#000000', fontWeight: 'bold' }}>
            {' '}
            ({count})
        </span>
    </span>
)

export const ExpandIcon = ({ expanded, onClick }) => {
    const classes = useTableStyles()
    const { t } = useTranslation()
    return (
        <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            fontSize="12px"
            fontWeight="bold"
            color="#307fc1"
            minHeight="20px"
        >
            <Box
                component="span"
                display="flex"
                className={classes.expandButton}
                onClick={onClick}
            >
                {expanded ? t('MyTasks_Hide') : t('MyTasks_Show')}
                <Box
                    classes={{
                        root: classes.expandIcon,
                    }}
                >
                    {expanded ? '-' : '+'}
                </Box>
            </Box>
        </Box>
    )
}
