import React from 'react'
import { InputBase, withStyles } from '@material-ui/core'

const StyledInput = withStyles(() => ({
    root: {
        width: '100% !important',
        borderRadius: '5px',
        border: 'solid 1px #d8d8dd',
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff',
        '&:hover': {
            border: 'solid 1px #307fc1',
        },
        '&:focus-within': {
            boxShadow: '0 4px 8px 0 rgba(48, 127, 193, 0.33)',
            border: 'solid 1px #307fc1 !important',
        },
    },
    input: {
        position: 'relative',
        fontSize: 15,
        lineHeight: 1.33,
        color: '#8b909a',
        padding: '11px 15px',
    },

    error: {
        border: 'solid 1px #f44336 !important',
        '&:focus-within': {
            border: 'solid 1px #f44336 !important',
        },
    },
}))(InputBase)

const InputField = ({ value, placeholder, onChange, ...rest }) => {
    return (
        <StyledInput
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            {...rest}
        />
    )
}

export default InputField
