import React, { useEffect } from 'react'
import { Collapse, Box, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import attributes from './attributes'
import RequestItemCard from './RequestItemCard'
import { Card } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { AppLayout, useEidGrid, CardViewLoader } from 'components'
import { useIsSmallScreen, useQuery, useSessionStorage } from 'packages/core'
import { useHistory } from 'react-router'
import StickyContainer from 'components/StickyContainer'
import {
    RequestItemsTable,
    RequestItemsTableHead,
    RequestItemRow,
} from './RequestItemsTableComponents'
import {
    styles,
    Filters,
    ListingGrid,
    GridContainer,
    FiltersColumn,
    FilterContainer,
    DesktopContainer,
    MobileFiltersScreen,
    SmallScreenContainer,
    MobileStickyContentListing,
} from 'components'
import DetailsDrawer from './DetailsDrawer'
import { SubPathLevel } from 'appConfigurations'

const useStyles = makeStyles(() => ({
    filtersearch: {
        width: '100%',
        maxWidth: '400px',
        '@media (max-width:1700px)': {
            maxWidth: '300px',
        },
    },
    datefiltersearchCollapse: {
        width: '100%',
        padding: '0px 5px',
        height: '40px',
        maxWidth: '100%',
    },
    datefiltersearch: {
        width: '100%',
        padding: '0px 5px',
        height: '40px',
    },
    filtermainsearch: {
        display: 'flex',
        width: '100%',
    },
}))

const RequestItemsLayout = ({
    list,
    totalCount,
    isLoading,
    isLoadingMore,
    pagination,
    filters = [],
    refetch,
}) => {
    const { t } = useTranslation()
    const [openFilters, setOpenFilters] = useSessionStorage(
        'FILTERS_EXPANDED',
        true,
    )
    const isSmallScreen = useIsSmallScreen()
    const defaultView = isSmallScreen ? 'card' : 'table'
    const { viewSwitcher, setCurrentView, viewToRender } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: t('MyTasks_NoBusinessRequestItemsFound'),
        pagination,
        attributes,
        cardViewConfig: {
            LoadingIndicatorComponent: CardViewLoader,
            CardComponent: RequestItemCard,
        },
        tableViewConfig: {
            TableComponent: RequestItemsTable,
            TableHeadComponent: RequestItemsTableHead,
            TableRowComponent: RequestItemRow,
        },
        defaultView,
    })

    useEffect(() => {
        if (isSmallScreen) {
            setCurrentView('card')
        }
    }, [isSmallScreen])

    const query = useQuery()
    const id = query.get('id')
    const history = useHistory()

    const {
        location: { pathname },
    } = history
    const classes = useStyles()
    const areaName = pathname.split('/')[1 + SubPathLevel]
    const routeName = pathname.split('/')[2 + SubPathLevel]

    const showFilters = query.get('filters') === 'visible'

    const mobileFiltersScreen = (
        <MobileFiltersScreen
            totalCount={totalCount}
            filters={filters}
            handleApplyFilters={() => {
                query.delete('filters')
                history.push(`${history.location.pathname}?${query.toString()}`)
            }}
        />
    )

    const mobileStickyContentListing = (
        <MobileStickyContentListing
            totalCount={totalCount}
            title={areaName}
            subTitle={routeName}
            handleApplyFilters={() => {
                query.set('filters', 'visible')
                history.push(`${history.location.pathname}?${query.toString()}`)
            }}
        />
    )

    let content
    if (isSmallScreen) {
        content = (
            <SmallScreenContainer>
                <Box display={showFilters ? '' : 'none'}>
                    {mobileFiltersScreen}
                </Box>

                <Box display={!showFilters ? '' : 'none'}>
                    <Box display={!id ? '' : 'none'}>
                        <StickyContainer>
                            <Box width="100%" padding="12px 14px">
                                {mobileStickyContentListing}
                            </Box>
                        </StickyContainer>
                        {viewToRender}
                    </Box>
                </Box>
            </SmallScreenContainer>
        )
    } else {
        content = (
            <DesktopContainer>
                <FiltersColumn>
                    <FilterContainer>
                        <Card.CardHeader
                            collapsible
                            fontColor="black"
                            cardTitle={t('MyTasks_ShowMyFilters')}
                            handleExpandClick={() =>
                                setOpenFilters((prev) => !prev)
                            }
                            expanded={openFilters}
                            icon={
                                <Icon name="AdvancedSearch" color="#959598" />
                            }
                            style={styles.filterCardHeader}
                        />
                    </FilterContainer>
                    <Collapse in={openFilters} timeout={300} unmountOnExit>
                        <>
                            {filters.map((f) => {
                                const FilterToRender = Filters.filtersMap[f]
                                return (
                                    <FilterContainer key={f}>
                                        <FilterToRender />
                                    </FilterContainer>
                                )
                            })}
                        </>
                    </Collapse>
                </FiltersColumn>

                <Box>
                    <Box classes={{ root: classes.filtermainsearch }}>
                        <Box classes={{ root: classes.filtersearch }}>
                            <Filters.TextSearch />
                        </Box>
                        <Box
                            classes={{
                                root: openFilters
                                    ? classes.datefiltersearch
                                    : classes.datefiltersearchCollapse,
                            }}
                        >
                            <Filters.DateFilter
                                label={`${t('MyTasks_CreatedBetween')}:`}
                            />
                        </Box>
                    </Box>

                    <ListingGrid>
                        <GridContainer openFilters={openFilters}>
                            {viewToRender}
                        </GridContainer>
                    </ListingGrid>
                </Box>
            </DesktopContainer>
        )
    }

    return (
        <AppLayout totalCount={totalCount} viewSwitcher={viewSwitcher}>
            {id && (
                <DetailsDrawer
                    id={id}
                    onClose={() => {
                        history.push(history.location.pathname)
                        refetch()
                    }}
                />
            )}

            {content}
        </AppLayout>
    )
}

export default RequestItemsLayout
