import React, { useState } from 'react'
import { ApproversList } from 'components'
import { useRequestApprovers } from 'hooks'

const RequestApprovers = ({ totalCount, id }) => {
    const take = 9

    const [searchTerm, setSearchTerm] = useState('')
    const [page, setPage] = useState(1)

    const handlePageChange = (value) => {
        setPage(value)
    }

    const { latestData } = useRequestApprovers(
        id,
        (page - 1) * take,
        take,
        searchTerm,
    )

    const isLoading = !Boolean(latestData)

    const data = latestData ? latestData.data : undefined

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / take)
        : 0

    return (
        <ApproversList
            totalCount={totalCount}
            visibleCount={latestData && latestData.totalCount}
            isLoading={isLoading}
            data={data}
            page={page}
            handlePageChange={handlePageChange}
            numberOfPages={numberOfPages}
            searchTerm={searchTerm}
            handleSearch={setSearchTerm}
        />
    )
}

export default RequestApprovers
