import { Box, createStyles, makeStyles } from '@material-ui/core'
import { useRequestRisks } from 'hooks'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@material-ui/lab'
import { ItemWithRisks } from 'components/PendingRiskItem/ItemWithRisks'
import { FC } from 'react'
import { IItemWithRisks } from 'types'
import { Container } from 'components/DetailsComponents'

const useStyles = makeStyles(
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            marginBottom: '8px',
        },
        itemContainer: {
            margin: '20px 20px',
            width: '100%',
            paddingLeft: '24px',
            paddingRight: '24px',
        },
    }),
)

export type ViolationsByItemsProps = {
    requestId: string
}

export const ViolationsByItems: FC<ViolationsByItemsProps> = (props) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const { requestId } = props

    const {
        data,
        isLoading,
    }: { data: IItemWithRisks[]; isLoading: boolean } = useRequestRisks(
        requestId,
        'Items',
    )

    if (isLoading || !data) {
        return (
            <Box marginX={4} marginY={4}>
                <Skeleton animation="wave" variant="rect" height={24} />
                <Box marginY={1} />
                <Skeleton animation="wave" variant="rect" height={24} />
            </Box>
        )
    } else if (data.length === 0) {
        return (
            <Container>
                <Box
                    marginX={4}
                    marginY={4}
                    display={'flex'}
                    justifyContent={'center'}
                >
                    {t('MyTasks_NoDataFound')}
                </Box>
            </Container>
        )
    }
    return (
        <Container>
            <Box className={classes.root}>
                {data.map((item) => (
                    <Box key={item.id} className={classes.itemContainer}>
                        <ItemWithRisks
                            id={item.id}
                            requestId={item.requestId}
                            risks={item.risks}
                            inverted={item.inverted}
                            left={item.left}
                            right={item.right}
                        />
                    </Box>
                ))}
            </Box>
        </Container>
    )
}
