import React from 'react'
import { Box, Divider, styled, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { smallScreenWidth } from 'utils'

const StyledSkeleton = styled(Skeleton)({
    marginTop: '23px',
    height: 28,
})



export const useStyles = makeStyles({
    root: {
        [`@media (max-width:${smallScreenWidth})`]: {
            justifyContent: 'center',

            '& .OverviewSectionDivider': {
                display: 'none',
            },
            '& .SecondColumn': {
                display: 'none',
            },
            '& .FirstColumn': {
                alignItems: 'center',
            },

            '& .AttributeSkeleton': {
                width: '200px',
            },
        },
    },
})
const OverviewSectionSkeleton = () => {
    const classes = useStyles()

    return (
        <Box display="flex" width="100%" className={classes.root}>
            <Box
                padding="0px 30px"
                width="40%"
                display="flex"
                flexDirection="column"
                className="FirstColumn"
            >
                <Box display="flex" justifyContent="center">
                    <Skeleton variant="circle" width={152} height={152} />
                </Box>
                <StyledSkeleton variant="text" className="AttributeSkeleton" />
                <StyledSkeleton variant="text" className="AttributeSkeleton" />
                <StyledSkeleton variant="text" className="AttributeSkeleton" />
            </Box>

            <Divider
                orientation="vertical"
                flexItem
                className="OverviewSectionDivider"
            />
            <Box
                padding="0px 30px"
                width="60%"
                display="flex"
                flexDirection="column"
                className="SecondColumn"
            >
                <StyledSkeleton variant="text" />
                <StyledSkeleton variant="text" />
                <StyledSkeleton variant="text" />

                <StyledSkeleton variant="text" />
                <StyledSkeleton variant="text" />
                <StyledSkeleton variant="text" />
            </Box>
        </Box>
    )
}

export default OverviewSectionSkeleton
