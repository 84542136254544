import { ChangeEvent } from 'react'
import { withStyles, Tabs as MuiTabs } from '@material-ui/core'

export type PillTabsProps = {
    value: string | number
    onChange: (event: ChangeEvent<{}>, newValue: string) => void
}

export const PillTabs = withStyles({
    root: {
        backgroundColor: '#fbfbfd',
    },
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            backgroundColor: 'transparent',
            width: '100%',
        },
    },
    flexContainer: {
        borderBottom: 'solid 1px #d8d8dd',
        backgroundColor: '#fbfbfd !important',
        paddingTop: '30px',
        paddingLeft: '20px',
    },
})((props: PillTabsProps) => (
    <MuiTabs
        {...props}
        TabIndicatorProps={{ children: <span /> }}
        variant="scrollable"
    />
))
