import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePageContext } from 'pageContext'
import { isNilOrEmpty, useAuthState } from 'packages/core'
import PersonFilter from './PersonFilter'
import ApproverPersonFilter from './ApproverPersonFilter'
import Search from './Search'
import DateTimeFilter from './DateTimeFilter'
import ChecklistFilter from './EidFilter/GenericFilter/ChecklistFilter'
import AdvancedFilter from 'components/Filters/EidFilter/AdvancedFilter'
import SubjectTypeFilter from './SubjectTypeFilter'
import AuditFilter from './AuditFilter'
import { Card, ToggleSwitch } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { Box } from '@material-ui/core'
import { RiskLevelFilter } from './RiskLevelFilter'
import { FulfillmentStatusFilter } from './FulfillmentStatusFilter'
import BusinessRequestTypeFilter from './BusinessRequestTypeFilter'

const PreApprovedFilter = () => {
    const { t } = useTranslation()
    const [{ showOnlyPreApproved }, dispatch] = usePageContext()
    const [toggle, setToggle] = useState(showOnlyPreApproved)

    useEffect(() => {
        if (toggle !== showOnlyPreApproved) {
            setToggle(showOnlyPreApproved)
        }
    }, [showOnlyPreApproved])

    const handleChange = () => {
        setToggle((prev) => !prev)
        dispatch({
            type: 'SET_PROP',
            payload: {
                key: 'showOnlyPreApproved',
                value: !toggle,
            },
        })
    }

    return (
        <Card
            cardTitle={t('MyTasks_ShowOnlyPreApproved')}
            collapsible
            expanded={showOnlyPreApproved}
        >
            <Box padding="20px">
                <ToggleSwitch
                    onChange={handleChange}
                    value={toggle}
                    label={t('MyTasks_ShowOnlyPreApproved')}
                />
            </Box>
        </Card>
    )
}

const RequestedByFilter = () => {
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()
    const [{ currentUserId, friendlyName }] = useAuthState()

    return (
        <Card
            cardTitle={t('MyTasks_RequestedBy')}
            color="#fff"
            collapsible
            expanded={state.requestedBy !== null}
        >
            <PersonFilter
                value={state.requestedBy}
                onClear={() =>
                    dispatch({
                        type: 'SET_PROP',
                        payload: {
                            key: 'requestedBy',
                            value: {
                                id: currentUserId,
                                friendlyName: friendlyName,
                            },
                        },
                    })
                }
                onChange={(payload) => {
                    dispatch({
                        type: 'SET_PROP',
                        payload: {
                            key: 'requestedBy',
                            value: payload,
                        },
                    })
                }}
            />
        </Card>
    )
}

const ApproverFilter = () => {
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()

    return (
        <ApproverPersonFilter
            title={t('MyTasks_Approver')}
            approverType={state.approverType}
            approver={state.approver}
            handleChange={(approverType, approver) => {
                switch (approverType) {
                    case 'past':
                        dispatch({
                            type: 'SET_MULTIPLE_PROPS',
                            payload: {
                                approverType: approverType,
                                approver: approver,
                                showOnlyWhereNoApprovers: null,
                            },
                        })
                        break
                    case 'noApprover':
                        dispatch({
                            type: 'SET_MULTIPLE_PROPS',
                            payload: {
                                approverType: approverType,
                                approver: null,
                                showOnlyWhereNoApprovers: true,
                            },
                        })
                        break
                    case 'current':
                    default:
                        dispatch({
                            type: 'SET_MULTIPLE_PROPS',
                            payload: {
                                approverType: approverType,
                                approver: approver,
                                showOnlyWhereNoApprovers: null,
                            },
                        })
                }
            }}
        />
    )
}

const RequestsStatusFilter = () => {
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()

    return (
        <Card
            cardTitle={t('MyTasks_FilterByStatus')}
            collapsible
            expanded={state.status && state.status.length > 0}
        >
            <ChecklistFilter
                transformResponse={(data) =>
                    data.map((i) => ({
                        id: i,
                        name: i,
                        friendlyName: t(`MyTasks_${i}`),
                    }))
                }
                url="/api/businessRequests/statuses"
                value={state.status}
                onChange={(payload) => {
                    dispatch({
                        type: 'SET_PROP',
                        payload: {
                            key: 'status',
                            value: payload,
                        },
                    })
                }}
                contextProp="status"
                selectedValueIndicatorProp="status"
            />
        </Card>
    )
}

const RequestItemsStatusFilter = () => {
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()

    return (
        <Card
            cardTitle={t('MyTasks_FilterByStatus')}
            collapsible
            expanded={state.status}
        >
            <ChecklistFilter
                transformResponse={(data) =>
                    data.map((i) => ({
                        id: i,
                        name: i,
                        friendlyName: t(`MyTasks_${i}`),
                    }))
                }
                url="/api/businessRequestItems/statuses"
                value={state.status}
                onChange={(payload) => {
                    dispatch({
                        type: 'SET_PROP',
                        payload: {
                            key: 'status',
                            value: payload,
                        },
                    })
                }}
                contextProp="status"
                selectedValueIndicatorProp="status"
            />
        </Card>
    )
}

const RequestItemTypesFilter = () => {
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()

    return (
        <Card
            cardTitle={t('MyTasks_FilterByItemType')}
            collapsible
            expanded={state.itemType && state.itemType.length > 0}
        >
            <ChecklistFilter
                transformResponse={(data) =>
                    data.map((i) => ({
                        id: i.id,
                        name: i.name,
                        friendlyName: i.localizedFriendlyName,
                    }))
                }
                url="/api/businessRequestItems/itemTypes"
                value={state.itemType}
                onChange={(payload) => {
                    dispatch({
                        type: 'SET_PROP',
                        payload: {
                            key: 'itemType',
                            value: payload,
                        },
                    })
                }}
                contextProp="itemType"
                selectedValueIndicatorProp="itemType"
            />
        </Card>
    )
}

const RiskLevelFilterCard = () => {
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()

    return (
        <Card
            cardTitle={t('MyTasks_FilterByRiskLevel')}
            collapsible
            expanded={state.riskLevels && state.riskLevels.length > 0}
        >
            <RiskLevelFilter
                value={state.riskLevels}
                onChange={(values) =>
                    dispatch({
                        type: 'SET_PROP',
                        payload: {
                            key: 'riskLevels',
                            value: values,
                        },
                    })
                }
            />
        </Card>
    )
}

const TextSearchFilter = () => {
    const [state, dispatch] = usePageContext()

    return (
        <Search
            handleSearch={(searchKey) =>
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'searchTerm',
                        value: searchKey,
                    },
                })
            }
            handleClear={(searchKey) =>
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'searchTerm',
                        value: searchKey,
                    },
                })
            }
            value={state.searchTerm}
        />
    )
}

const StartDateFilter = () => {
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()

    return (
        <DateTimeFilter
            disableFuture
            showLabelWithin
            placeholder={t('MyTasks_BetweenDate')}
            value={
                isNilOrEmpty(state.startDate)
                    ? null
                    : state.startDate.clone().local()
            }
            onChange={(date) => {
                const dateToSet = date.clone()
                dateToSet.startOf('day')
                dateToSet.utc()

                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'startDate',
                        value: dateToSet,
                    },
                })
            }}
            handleClear={() =>
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'startDate',
                        value: null,
                    },
                })
            }
        />
    )
}

const EndDateFilter = () => {
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()

    return (
        <DateTimeFilter
            disableFuture
            showLabelWithin
            placeholder={t('MyTasks_AndDate')}
            value={
                isNilOrEmpty(state.endDate)
                    ? null
                    : state.endDate.clone().local()
            }
            onChange={(date) => {
                const dateToSet = date.clone().endOf('day')
                dateToSet.utc()

                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'endDate',
                        value: dateToSet,
                    },
                })
            }}
            handleClear={() =>
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'endDate',
                        value: null,
                    },
                })
            }
        />
    )
}

const dateFilterStyles = {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '15px',
    boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
    border: 'solid 1px #ebebed',
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    color: '#7D7C7C',
    height: '40px',
}

const DateFilter = ({ label }) => {
    return (
        <Box style={dateFilterStyles}>
            <Box minWidth="120px">{label}</Box>
            <StartDateFilter />
            <EndDateFilter />
        </Box>
    )
}

const requestsAdvancedSearchForms = {
    colorCode: {
        primary: '#2920ad',
        secondary: '#ebeaf7',
    },
    filters: [
        {
            name: 'description',
            type: 'text',
            label: 'MyTasks_Name',
            placeholder: 'MyTasks_Name',
            contextProp: 'advancedSearch.forms.description',
        },
        {
            name: 'requestNumber',
            type: 'text',
            label: 'MyTasks_BusinessRequestNumber',
            placeholder: 'MyTasks_BusinessRequestNumber',
            contextProp: 'advancedSearch.forms.requestNumber',
        },
    ],
}

const RequestsAdvancedSearchFilters = () => {
    const { t } = useTranslation()
    const [state] = usePageContext()
    return (
        <Card
            cardTitle={t('MyTasks_AdvancedSearch')}
            collapsible
            icon={<Icon name="AdvancedSearch" color="#959598" />}
            expanded={
                state['advancedSearch.forms.description'] ||
                state['advancedSearch.forms.requestNumber']
            }
        >
            <AdvancedFilter forms={requestsAdvancedSearchForms} tags={null} />
        </Card>
    )
}

const requestItemsAdvancedSearchForms = {
    colorCode: {
        primary: '#2920ad',
        secondary: '#ebeaf7',
    },
    filters: [
        {
            name: 'name',
            type: 'text',
            label: 'MyTasks_Name',
            placeholder: 'MyTasks_Name',
            contextProp: 'advancedSearch.forms.friendlyName',
        },
        {
            name: 'requestName',
            type: 'text',
            label: 'MyTasks_BusinessRequestName',
            placeholder: 'MyTasks_BusinessRequestName',
            contextProp: 'advancedSearch.forms.requestName',
        },
        {
            name: 'requestNumber',
            type: 'text',
            label: 'MyTasks_BusinessRequestNumber',
            placeholder: 'MyTasks_BusinessRequestNumber',
            contextProp: 'advancedSearch.forms.requestNumber',
        },
    ],
}

const RequestItemsAdvancedSearchFilters = () => {
    const { t } = useTranslation()

    return (
        <Card
            cardTitle={t('MyTasks_AdvancedSearch')}
            collapsible
            icon={<Icon name="AdvancedSearch" color="#959598" />}
        >
            <AdvancedFilter
                forms={requestItemsAdvancedSearchForms}
                tags={null}
            />
        </Card>
    )
}

const Filters = () => <></>
Filters.BusinessRequestType = BusinessRequestTypeFilter
Filters.RequestedBy = RequestedByFilter
Filters.PreApproved = PreApprovedFilter
Filters.Approver = ApproverFilter
Filters.RequestsStatus = RequestsStatusFilter
Filters.BusinessRequestItemsStatus = RequestItemsStatusFilter
Filters.BusinessRequestItemTypes = RequestItemTypesFilter
Filters.TextSearch = TextSearchFilter
Filters.StartDate = StartDateFilter
Filters.EndDate = EndDateFilter
Filters.DateFilter = DateFilter
Filters.RequestsAdvancedSearch = RequestsAdvancedSearchFilters
Filters.RequestItemsAdvancedSearch = RequestItemsAdvancedSearchFilters
Filters.SubjectType = SubjectTypeFilter
Filters.Audit = AuditFilter
Filters.RiskLevelFilter = RiskLevelFilterCard
Filters.FulfillmentStatusFilter = FulfillmentStatusFilter

const filtersMap = {
    businessRequestType: BusinessRequestTypeFilter,
    subjectType: SubjectTypeFilter,
    audit: AuditFilter,
    requestedBy: RequestedByFilter,
    approver: ApproverFilter,
    preApproved: PreApprovedFilter,
    requestStatus: RequestsStatusFilter,
    requestItemStatus: RequestItemsStatusFilter,
    itemType: RequestItemTypesFilter,
    riskLevel: RiskLevelFilterCard,
    requestsAdvancedSearch: RequestsAdvancedSearchFilters,
    requestItemsAdvancedSearch: RequestItemsAdvancedSearchFilters,
    fulfillmentStatus: FulfillmentStatusFilter,
}

Filters.filtersMap = filtersMap

export default Filters
