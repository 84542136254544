import { FC, useState } from 'react'
import {
    Box,
    createStyles,
    makeStyles,
    styled,
    TextField,
} from '@material-ui/core'
import { APPROVAL_COMMENT_MAX_LENGTH, mobileScreenWidth } from 'utils'
import { isWhitespace } from 'packages/core'
import MitigationControlList from './MitigationControlList'
import Button from '../../Button'
import { Icon } from 'packages/eid-icons'
import { Spinner, ValidationMessage, ValidationMessages } from 'packages/eid-ui'
import DecisionComment from '../../DecisionComment'
import { NativeDatePicker } from 'packages/eid-ui/DateTimePickers'
import { useTranslation } from 'react-i18next'
import { IMitigatingControl } from 'types'
import useSubcomponents from 'useSubcomponents'
import { Moment } from 'moment'
import moment from 'moment'

const useStyles = makeStyles(
    createStyles({
        title: {
            paddingTop: '12px',
            paddingBottom: '12px',

            fontSize: '14px',
            fontWeight: 'bold',
            color: '#000000',
        },
        submitButton: {
            '& >button': {
                backgroundColor: '#307fc1',
                '&:hover': {
                    backgroundColor: '#307fc1',
                    opacity: 0.95,
                },
                '& svg': {
                    color: '#ffffff',
                },
            },
        },
        cancelButton: {
            '& >button': {
                backgroundColor: '#d9d9d9',
                '&:hover': {
                    backgroundColor: '#d9d9d9',
                    opacity: 0.95,
                },

                '& svg': {
                    color: '#ffffff',
                    width: '14px',
                    height: '14px',
                },
            },
        },
        endDateContainer: {
            display: 'flex',
            width: '100%',
            alignItems: 'flex-end',
            '& svg': {
                color: '#307fc1',
                margin: '4px 12px 4px 0px',
            },
        },
        datePicker: {
            width: '100%',
            borderBottom: '1px solid transparent',
            '&:hover': {
                borderBottom: '1px solid black',
            },
        },
    }),
)

const PopoverContentContainer = styled(Box)({
    borderRadius: '5px',
    padding: '12px 24px',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.15)',
    [`@media (min-width:${mobileScreenWidth})`]: {
        minWidth: '420px',
    },
    [`@media (max-width:${mobileScreenWidth})`]: {
        width: '100%',
    },
})

export interface MitigationPopoverProps {
    globalRiskId: string
    decisionFriendlyName: string
    onSubmit: (value: any) => Promise<any>
    isLoading?: boolean
    handleClose?: () => void
}

export const MitigateRisk: FC<MitigationPopoverProps> = (props) => {
    const { t } = useTranslation()

    const classes = useStyles()

    const [decisionComment, setDecisionComment] = useState('')

    const [showErrors, setShowErrors] = useState(false)

    const [
        selectedMitigatingControls,
        setSelectedMitigatingControls,
    ] = useState<IMitigatingControl[]>([])
    const [selectedEndDate, setSelectedEndDate] = useState<Moment | null>(null)

    const { hasAccessToRiskMitigationEndDateControl } = useSubcomponents()

    const noMitigationControlSelected = selectedMitigatingControls.length === 0

    const invalidComment =
        decisionComment.length > APPROVAL_COMMENT_MAX_LENGTH ||
        isWhitespace(decisionComment)

    const notSubmittable = noMitigationControlSelected || invalidComment

    return (
        <PopoverContentContainer>
            <Box className={classes.title}>{props.decisionFriendlyName}</Box>

            <MitigationControlList
                onChange={setSelectedMitigatingControls}
                globalRiskId={props.globalRiskId}
            />

            {hasAccessToRiskMitigationEndDateControl && (
                <Box className={classes.endDateContainer}>
                    <Icon name="EndTime" />
                    <Box className={classes.datePicker}>
                        <NativeDatePicker
                            minDate={moment(new Date()).format('YYYY-MM-DD')}
                            value={
                                selectedEndDate
                                    ? selectedEndDate
                                          .clone()
                                          .local()
                                          .format('YYYY-MM-DD')
                                    : null
                            }
                            handleChange={(e: any) => {
                                const date = moment(e)
                                const dateToSet = date
                                    .clone()
                                    .endOf('day')
                                    .set('milliseconds', 0)
                                    .utc()
                                setSelectedEndDate(dateToSet)
                            }}
                        >
                            <TextField
                                fullWidth
                                type="text"
                                value={
                                    selectedEndDate
                                        ? selectedEndDate
                                              .clone()
                                              .local()
                                              .format('D MMMM YYYY')
                                        : '-- -- ----'
                                }
                                label={t('MyTasks_EndDate')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </NativeDatePicker>
                    </Box>
                </Box>
            )}

            <Box my={3}>
                <DecisionComment
                    initialValue={decisionComment}
                    maxCharacters={APPROVAL_COMMENT_MAX_LENGTH}
                    onChange={(comment: any) => setDecisionComment(comment)}
                />
            </Box>

            {showErrors && notSubmittable && (
                <ValidationMessages>
                    {selectedMitigatingControls.length === 0 && (
                        <ValidationMessage
                            message={t('MyTasks_MitigationControlRequired')}
                        />
                    )}
                    {invalidComment && (
                        <ValidationMessage
                            message={t('MyTasks_InvalidComment')}
                        />
                    )}
                </ValidationMessages>
            )}

            <Box my={2} display="flex">
                <Box className={classes.cancelButton}>
                    <Button size="medium" onClick={props.handleClose}>
                        <Icon name="Close" />
                    </Button>
                </Box>
                <Box mx={2} className={classes.submitButton}>
                    <Button
                        size="medium"
                        onClick={() => {
                            if (notSubmittable) {
                                setShowErrors(true)
                                return
                            }

                            let itemToSubmit: any = {
                                mitigatingControlIds: selectedMitigatingControls.map(
                                    (m) => m.id,
                                ),
                                comment: decisionComment,
                            }
                            if (hasAccessToRiskMitigationEndDateControl) {
                                itemToSubmit.endDateUtc = selectedEndDate
                            }

                            props.onSubmit(itemToSubmit)
                        }}
                    >
                        {props.isLoading ? (
                            <Spinner size={0.6} color="#ffffff" />
                        ) : (
                            <Icon name="Approve" />
                        )}
                    </Button>
                </Box>
            </Box>
        </PopoverContentContainer>
    )
}
