import { styled } from '@material-ui/core'
import { HorizontalTabs } from 'packages/eid-ui'

const Tabs = styled(HorizontalTabs)({
    '& .MuiTabs-flexContainer': {
        height: '61px',
    },
    '& .MuiTabs-indicator': {
        backgroundColor: '#307fc1',
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
        fontWeight: 'bold',
    },
})

export default Tabs
