import React, { Fragment } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { Tooltip } from 'packages/eid-ui'
import ItemTypeSummaryDetailsTooltip from './ItemTypeSummaryDetailsTooltip'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import TextFormatter from '../TextFormatter'
import classNames from 'classnames'

const useStyles = makeStyles({
    attribute: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: '2px 10px 2px 10px',
        fontSize: '13px !important',
        color: '#5d6870 !important',
        '& > svg': {
            marginBottom: '1px',
        },
        '&:hover': {},
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        marginRight: '6px',
        fontWeight: 'bold',
    },
    iconContainerDefault: {
        '&:hover': {
            '& > div': {
                borderColor: '#cdcdd1',
            },
        },
    },
    iconContainerResourceType: {
        '&:hover': {
            '& > svg': {
                color: '#000000',
            },
        },
    },
    totalCount: {
        fontWeight: 'bold',
        marginRight: '6px',
    },
})

const ItemTypeCount = ({ requestId, itemTypeCount }) => {
    const { t } = useTranslation()

    const classes = useStyles()

    return (
        <Fragment>
            {itemTypeCount.map((itemType) => (
                <Box key={itemType.itemTypeId} className={classes.attribute}>
                    <Tooltip
                        key={itemType.itemTypeId}
                        title={
                            <ItemTypeSummaryDetailsTooltip
                                requestId={requestId}
                                itemTypeId={itemType.itemTypeId}
                            />
                        }
                        enterDelay={800}
                    >
                        <Box
                            component={'span'}
                            className={classNames(classes.iconContainer, {
                                [classes.iconContainerDefault]:
                                    itemType.resourceType === 'Unknown',
                                [classes.iconContainerResourceType]:
                                    itemType.resourceType !== 'Unknown',
                            })}
                        >
                            {itemType.resourceType !== 'Unknown' ? (
                                <Icon
                                    name={itemType.resourceType}
                                    color="#919193"
                                />
                            ) : (
                                <Box
                                    height="16px"
                                    width="16px"
                                    border="4px solid #d8d8dd"
                                    bgcolor="transparent"
                                    borderRadius="50%"
                                />
                            )}
                        </Box>
                    </Tooltip>
                    <Box component={'span'} className={classes.totalCount}>
                        {itemType.totalCount}
                    </Box>

                    {itemType.resourceType !== 'Unknown' ? (
                        t(`MyTasks_${itemType.resourceType}`)
                    ) : (
                        <Box component={'span'}>
                            <TextFormatter
                                value={itemType.categoryName}
                                maxCharacters={40}
                                style={{
                                    fontSize: '13px',
                                }}
                            />
                        </Box>
                    )}
                </Box>
            ))}
        </Fragment>
    )
}

export default ItemTypeCount
