import React from 'react'
import Masonry from 'react-masonry-component'
import { Box } from '@material-ui/core'
import { Loader } from 'packages/eid-ui'

const masonryOptions = {
    transitionDuration: 0,
    horizontalOrder: true,
}

const EidCardList = ({
    data,
    isLoading,
    LoadingIndicatorComponent = Loader,
    isLoadingMore,
    noDataMessage = 'No data available',
    attributes,
    pagination,
    CardComponent,
}) => {
    if (isLoading) return <LoadingIndicatorComponent attributes={attributes} />
    if (data.length === 0)
        return (
            <Box
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                paddingY="20px"
            >
                {noDataMessage}
            </Box>
        )
    return (
        <>
            <Masonry options={masonryOptions} enableResizableChildren>
                {data.map((item, index) => (
                    <CardComponent
                        key={index}
                        item={item}
                        attributes={attributes}
                    />
                ))}
            </Masonry>
            <Box display="flex" justifyContent="center">
                {pagination}
            </Box>
            {isLoadingMore && <Loader height="1in" width="100%" />}
        </>
    )
}

export default EidCardList
