import { Box } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InputField from './InputField'
import { isNilOrEmpty, isWhitespace } from 'packages/core'
import config from 'config'

const DecisionComment = (props) => {
    const {
        initialValue = '',
        onBlur,
        onChange = () => {},
        maxCharacters,
        isRejected,
    } = props
    const { t } = useTranslation()
    const [comment, setComment] = useState(initialValue)

    const checkError = () => {
        return (
            comment.length > maxCharacters ||
            isWhitespace(comment) ||
            (config.MANDATORY_REJECTION_COMMENTS &&
                isRejected &&
                isNilOrEmpty(comment))
        )
    }
    return (
        <Box position="relative">
            <InputField
                value={comment}
                multiline={true}
                rows={2}
                placeholder={t('MyTasks_AddADecisionComment')}
                onChange={(e) => {
                    setComment(e.target.value)
                    onChange(e.target.value)
                }}
                onBlur={onBlur}
                error={checkError()}
            />

            <Box position="absolute" bottom="-20px" right={2} fontSize="10px">
                {comment.length}/{maxCharacters}
            </Box>
        </Box>
    )
}

export default DecisionComment
