import { Box, styled } from '@material-ui/core'

const IconLabel = styled(Box)({
    fontSize: '14px',
    fontWeight: 'bold',

    width: '120px',

    marginLeft: '10px',
})

export default IconLabel
