import React from 'react'
import { isNilOrEmpty } from 'packages/core'
import AutocompleteFilter from './AutocompleteFilter'
import TextualFilter from './TextualFilter'
import CheckboxFilter from './CheckboxFilter'
import ChecklistFilter from './ChecklistFilter'
import TreeFilter from './TreeFilter'
import { useTranslation } from 'react-i18next'
import { usePageContext } from 'pageContext'

const defaultValues = {
    text: '',
    checkbox: false,
    autocomplete: null,
    tree: null,
    checklist: [],
}

const GenericFilter = ({
    type,
    title,
    label,
    colorCode,
    contextProp,
    value,
    placeholder,
    searchPlaceholder,
    onChange,
    ...rest
}) => {
    const { t } = useTranslation()

    const [state, dispatch] = usePageContext()

    let initialValue = undefined

    const contextValue = state[contextProp] ?? defaultValues[type]

    const dispatchContextChange = (value) =>
        dispatch({
            type: 'SET_PROP',
            payload: {
                key: contextProp,
                value: value,
            },
        })

    const resolvedValue = value ?? contextValue
    const resolvedOnChange = onChange ?? dispatchContextChange

    if (isNilOrEmpty(type)) return <></>

    switch (type) {
        case 'text':
            return (
                <TextualFilter
                    value={resolvedValue}
                    onChange={resolvedOnChange}
                    placeholder={t(placeholder)}
                    label={t(label)}
                    {...rest}
                />
            )
        case 'checkbox':
            return (
                <CheckboxFilter
                    value={resolvedValue}
                    onChange={resolvedOnChange}
                    label={t(label)}
                    {...rest}
                />
            )
        case 'autocomplete':
            return (
                <AutocompleteFilter
                    value={resolvedValue}
                    onChange={resolvedOnChange}
                    placeholder={t(placeholder)}
                    label={t(label)}
                    {...rest}
                />
            )
        case 'tree':
            return (
                <TreeFilter
                    initialValue={initialValue}
                    value={resolvedValue}
                    onChange={resolvedOnChange}
                    searchPlaceholder={t(searchPlaceholder)}
                    {...rest}
                />
            )
        case 'checklist':
            return (
                <ChecklistFilter
                    value={resolvedValue}
                    onChange={resolvedOnChange}
                    searchPlaceholder={t(searchPlaceholder)}
                    {...rest}
                />
            )
        default:
            return <></>
    }
}

export default GenericFilter
