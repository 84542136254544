import React from 'react'
import Button from './index'
import { Typography } from '@material-ui/core'

const styles = {
    normal: {
        minWidth: '160px',
        width: '100%',
        backgroundColor: '#ffffff',
        color: '#363636',
        border: 'solid 1px #d2d2d9',

        '& >span': { display: 'flex', flexDirection: 'column' },
        '&:hover': {
            backgroundColor: '#ffffff',
            opacity: 0.95,
        },
        '&:active': {
            backgroundColor: '#ffffff',
        },
    },
    disabled: {
        backgroundColor: '#aab0b4 !important',
        color: '#ffffff !important',
    },

    label: {
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: 1.15,
    },
    subLabel: {
        fontSize: '11px',
        color: '#8b909a',
        lineHeight: 1.15,
    },
}

const FiltersButton = ({ children, label, subLabel, ...rest }) => {
    return (
        <Button
            style={styles.normal}
            size="medium"
            disabledStyles={styles.disabled}
            {...rest}
        >
            {!children ? (
                <>
                    <Typography style={styles.label}>{label}</Typography>

                    <Typography style={styles.subLabel}>{subLabel}</Typography>
                </>
            ) : (
                children
            )}
        </Button>
    )
}

export default FiltersButton
