import React from 'react'
import Button from './index'

const styles = {
    normal: {
        width: '100%',
        minWidth: '87px',
        backgroundColor: '#307fc1',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#307fc1',
            opacity: 0.95,
        },
        '&:active': {
            backgroundColor: '#307fc1',
        },
    },
    disabled: {
        backgroundColor: '#aab0b4 !important',
        color: '#ffffff !important',
    },
}

const SubmitButton = ({ ...rest }) => {
    return (
        <Button
            style={styles.normal}
            size="medium"
            disabledStyles={styles.disabled}
            {...rest}
        />
    )
}

export default SubmitButton
