import React from 'react'
import { Box, styled } from '@material-ui/core'
import { ItemCardSkeleton, Button, Filters } from 'components'
import { Typography, Divider } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'
import StickyContainer from 'components/StickyContainer'
import { mobileScreenWidth, tabletScreenWidth } from 'utils'

import SubHeader from 'components/SubHeader'

export const masonaryOptions = {
    transitionDuration: 0,
    horizontalOrder: true,
}

export const maxWidth = '100%'
export const filtersColumnWidth = 325

export const TableContainer = styled(({ openFilters, ...rest }) => (
    <Box {...rest} />
))(({ openFilters }) => ({
    position: 'relative',
    marginLeft: !openFilters && -filtersColumnWidth + -20,
    marginTop: 28,
    transition: `all 0.5s ease-out`,
}))

export const GridContainer = styled(({ openFilters, ...rest }) => (
    <Box {...rest} />
))(({ openFilters }) => ({
    position: 'relative',
    marginLeft: !openFilters && -filtersColumnWidth + -20,
    marginTop: 28,
    transition: `all 0.5s ease-out`,
}))

export const DesktopContainer = styled(Box)({
    display: 'flex',
    paddingTop: '30px',
})

export const TabletContainer = styled(Box)(({ theme }) => ({
    padding: '24px 0px',
    [theme.breakpoints.down('md')]: {
        marginLeft: '70px',
    },
}))

export const SmallScreenContainer = styled(Box)({
    [`@media (min-width:${tabletScreenWidth})`]: {
        marginLeft: '70px',
    },
})

export const PaddedDiv = styled(Box)({
    padding: '25px 40px',
    [`@media (max-width:${mobileScreenWidth})`]: {
        padding: '25px 28px',
    },
})

export const ItemsContainerTablet = styled(Box)({
    height: 'inline-block',
    width: '100%',
    overflow: 'hidden',

    '@media (min-width:0px) and (orientation: landscape)': {
        width: '50%',
    },
})

export const ItemsContainerMobile = styled(Box)(({ top }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: top ? top : '16px',
    maxWidth: maxWidth,
    margin: 'auto',
    paddingLeft: '6px',
    paddingRight: '6px',
}))

export const FiltersColumn = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 12px',
    marginRight: '20px',
    minWidth: filtersColumnWidth,
})

export const FilterContainer = styled(Box)({
    marginBottom: '28px',
})

export const ListingGrid = styled(Box)({
    width: '100%',
})

export const CardViewLoader = () => (
    <Box display="flex" flexWrap="wrap" width="100%" overflow="hidden">
        <ItemCardSkeleton />
        <ItemCardSkeleton />
        <ItemCardSkeleton />
        <ItemCardSkeleton />
    </Box>
)

export const styles = {
    listTitle: {
        fontSize: '12px',
        color: '#307fc1',
        padding: '0px 14px 6px 14px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    divider: {
        backgroundColor: '#e8e8e8',
        margin: '6px 8px',
    },
    title: {
        color: '#363636',
        fontSize: '20px',
        fontWeight: 'bold',
        paddingLeft: '3px',
        lineHeight: 1.33,
    },
    subTitle: { color: '#363636', fontSize: '14px', lineHeight: 1.33 },
    icon: {
        transform: 'rotate(90deg)',
    },

    filtersScreenHeader: {
        margin: 'auto',
        [`@media (max-width:${mobileScreenWidth})`]: {
            backgroundColor: '#ffffff',
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
        },
    },

    flexCenterStyles: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    filterCardHeader: {
        borderRadius: '5px',
        height: '40px',
        
    },

    mobileFiltersContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        paddingTop: '20px !important',
        margin: 'auto',
        padding: '0px 20px',
        maxWidth: '600px',
        marginBottom: '28px',
        marginTop: '20px',
    },
}

const TabsSubHeader = styled(Box)({
    paddingBottom: '24px',
    [`@media (max-width:${mobileScreenWidth})`]: {
        display: 'none',
    },
})

const MobileSubHeader = styled(Box)({
    [`@media (min-width:${tabletScreenWidth})`]: {
        display: 'none',
    },
})

const FiltersSearchContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& .FiltersButton': {
        width: '100%',
        paddingTop: '20px',
    },
    '& .Search': {
        width: '100%',
    },

    [`@media (min-width:${tabletScreenWidth})`]: {
        flexDirection: 'row',
        '& .FiltersButton': {
            paddingTop: '0px',
            paddingLeft: '14px',
            width: '50%',
        },
        '& .Search': {
            width: '50%',
            paddingRight: '14px',
        },
    },
})

const pageTitleMap = {
    all: 'MyTasks_All',
    mytasks: 'MyTasks_ToDo',
    myrequests: 'MyTasks_MyRequests',
    businessrequests: 'MyTasks_RequestView',
    businessrequestitems: 'MyTasks_ItemView',
}

export const MobileStickyContentListing = ({
    title: areaName,
    subTitle: routeName,
    handleApplyFilters,
    totalCount,
}) => {
    const { t } = useTranslation()
    return (
        <>
            <TabsSubHeader>
                <SubHeader totalCount={totalCount} />
            </TabsSubHeader>

            <MobileSubHeader>
                <Box
                    {...styles.flexCenterStyles}
                    justifyContent="flex-start"
                    paddingLeft="12px"
                >
                    <Typography display="block" style={styles.title}>
                        {t(pageTitleMap[areaName.toLowerCase()])}
                    </Typography>

                    <Icon
                        name="Arrow"
                        color="#D8D8DD"
                        style={styles.icon}
                        width="20px"
                        height="20px"
                    />

                    <Typography display="block" style={styles.subTitle}>
                        {t(pageTitleMap[routeName.toLowerCase()])}
                    </Typography>
                </Box>

                <Box margin="14px 0px 14px 0px" padding="0px 8px">
                    <Divider color="#d2d2d9" />
                </Box>
            </MobileSubHeader>

            <FiltersSearchContainer>
                <Box className="Search">
                    <Filters.TextSearch />
                </Box>
                <Box className="FiltersButton">
                    <Button.Filters
                        label={t('MyTasks_Filters')}
                        subLabel={t('MyTasks_ApplyFilters')}
                        onClick={handleApplyFilters}
                    />
                </Box>
            </FiltersSearchContainer>
        </>
    )
}

export const MobileFiltersScreen = ({
    filters,
    handleApplyFilters,
    totalCount,
}) => {
    const { t } = useTranslation()
    return (
        <>
            <StickyContainer styles={styles.filtersScreenHeader}>
                {/* <TabsSubHeader>
                    <SubHeader totalCount={totalCount} />
                </TabsSubHeader> */}

                <Box width="100%" maxWidth="600px">
                    <PaddedDiv
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: '#363636',
                            }}
                        >
                            {t('MyTasks_Filters')}
                        </Typography>

                        <Box>
                            <Button.ApplyFilters onClick={handleApplyFilters}>
                                {t('MyTasks_ApplyFilters')}
                            </Button.ApplyFilters>
                        </Box>
                    </PaddedDiv>
                </Box>
            </StickyContainer>

            {filters.map((f) => {
                const FilterToRender = Filters.filtersMap[f]
                return (
                    <Box key={f} style={styles.mobileFiltersContainer}>
                        <FilterToRender />
                    </Box>
                )
            })}
        </>
    )
}
