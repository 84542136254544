import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { ListItem as MuiListItem, styled, withStyles } from '@material-ui/core'

const Item = withStyles({
    root: {
        padding: '5px 15px',
        margin: '0px 0px',
        border: 'solid 1px transparent',

        '&:hover': {
            backgroundColor: '#f8f8f8',
            borderRadius: '5px',
            border: 'solid 1px #d6d6d6',
        },
    },
})(MuiListItem)

const LinkWrapper = styled(({ active, ...rest }) => <RouterLink {...rest} />)(
    ({ active, styles }) => ({
        textDecoration: 'none',
        color: active ? '#000000' : '#919193',
        fontWeight: active ? 'bold' : 'normal',
        fontSize: '12px',
        textTransform: 'capitalize',
        ...styles,
    }),
)

const ListItem = ({ children, ...rest }) => {
    return (
        <LinkWrapper {...rest}>
            <Item>{children}</Item>
        </LinkWrapper>
    )
}

export default ListItem
