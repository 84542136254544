import { useQuery } from 'react-query'
import { useAxios } from 'packages/core'
import config from 'config'

export const APPLICATION_SETTINGS_KEY = 'APPLICATION_SETTINGS_KEY'
export const ALL_APPLICATIONS_KEY = 'ALL_APPLICATIONS_KEY'
export const ENVIRONMENT_NAME_KEY = 'ENVIRONMENT_NAME_KEY'
export const BUSINESS_REQUEST_TYPES = 'BUSINESS_REQUEST_TYPES'

export const useApplicationSettings = () => {
    const callApi = useAxios()
    return useQuery(
        APPLICATION_SETTINGS_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/application/settings',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}

export const useAllApplications = () => {
    const callApi = useAxios()
    return useQuery(
        ALL_APPLICATIONS_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/application/access/microservices',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}

export const useEnvironment = () => {
    const callApi = useAxios()
    return useQuery(
        ENVIRONMENT_NAME_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/application/environment',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}

export const GetBusinessRequestTypes = (fetchOptionsData) => {
    const callApi = useAxios()
    return useQuery(
        BUSINESS_REQUEST_TYPES,
        () =>
            callApi({
                method: 'POST',
                url: '/api/BusinessRequests/businessRequestTypes',
                data: JSON.stringify(fetchOptionsData)
            }).then((data) => data.data),
        {
            staleTime: Infinity,
            enabled: Boolean(fetchOptionsData)
        },
    )
}
