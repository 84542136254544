import { useState } from 'react'
import { CommentsList } from 'components'
import {
    useRequestItemComments,
    useAddComment,
    useDeleteComment,
    useEditComment,
} from 'hooks'
import CommentsSectionContainer from 'components/CommentsSectionContainer'

const RequestItemComments = ({ requestId, itemId }) => {
    const {
        data: comments,
        isLoading: loadingComments,
    } = useRequestItemComments(requestId, itemId)

    const [addComment, { isLoading: isSubmitting }] = useAddComment(
        'RequestItems',
        requestId,
        itemId,
    )

    const [deleteComment, { isLoading: isDeleting }] = useDeleteComment(
        'RequestItems',
        requestId,
        itemId,
    )

    const [editComment, { isLoading: isEditing }] = useEditComment(
        'RequestItems',
        requestId,
        itemId,
    )

    return (
        <CommentsSectionContainer comments={comments}>
            <CommentsList
                page="RequestItems"
                requestId={requestId}
                itemId={itemId}
                list={comments}
                loading={loadingComments}
                isSubmitting={isSubmitting}
                handleAddComment={(value) =>
                    addComment({
                        businessRequestId: requestId,
                        businessRequestItemId: itemId,
                        value,
                    })
                }
                handleDeleteComment={(id) => {
                    deleteComment(id)
                }}
                handleEditComment={(id, value) => {
                    editComment({
                        id,
                        value,
                    })
                }}
            />
        </CommentsSectionContainer>
    )
}

export default RequestItemComments
