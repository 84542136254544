import useSubcomponents from 'useSubcomponents'
import config from 'config'

export const useRouteOptions = () => {
    const {
        hasAccessToRequestViewMyRequestsPage,
        hasAccessToItemViewMyRequestsPage,
        hasAccessToRequestViewMyTasksPage,
        hasAccessToItemViewMyTasksPage,
        hasAccessToRequestViewAllRequestsPage,
        hasAccessToItemViewAllRequestsPage,
    } = useSubcomponents()

    const routeOptions = []
    if (
        hasAccessToRequestViewMyRequestsPage ||
        hasAccessToItemViewMyRequestsPage
    ) {
        routeOptions.push({
            name: 'MyRequests',
            title: 'MyTasks_MyRequests',
            icon: 'MTMyRequests',
            route: `${config.APP_SUBPATH}/myRequests`,

            children: [
                {
                    title: 'MyTasks_BusinessRequests',
                    icon: 'BusinessRolesSmall',
                    route: `${config.APP_SUBPATH}/myRequests/businessRequests`,
                },
                {
                    title: 'MyTasks_RequestedItems',
                    icon: 'ManagementRolesSmall',
                    route: `${config.APP_SUBPATH}/myRequests/businessRequestItems`,
                },
            ],
        })
    }
    if (hasAccessToRequestViewMyTasksPage || hasAccessToItemViewMyTasksPage) {
        routeOptions.push({
            name: 'MyTasks',
            title: 'MyTasks_ToDo',
            icon: 'MTTodo',
            route: `${config.APP_SUBPATH}/myTasks`,

            children: [
                {
                    title: 'MyTasks_BusinessRequests',
                    icon: 'BusinessRolesSmall',
                    route: `${config.APP_SUBPATH}/myTasks/businessRequests`,
                },
                {
                    title: 'MyTasks_RequestedItems',
                    icon: 'ManagementRolesSmall',
                    route: `${config.APP_SUBPATH}/myTasks/businessRequestItems`,
                },
            ],
        })
    }
    if (
        hasAccessToRequestViewAllRequestsPage ||
        hasAccessToItemViewAllRequestsPage
    ) {
        routeOptions.push({
            name: 'AllRequests',
            title: 'MyTasks_All',
            icon: 'MTAll',
            route: `${config.APP_SUBPATH}/all`,

            children: [
                {
                    title: 'MyTasks_BusinessRequests',
                    icon: 'BusinessRolesSmall',
                    route: `${config.APP_SUBPATH}/all/businessRequests`,
                },
                {
                    title: 'MyTasks_RequestedItems',
                    icon: 'ManagementRolesSmall',
                    route: `${config.APP_SUBPATH}/all/businessRequestItems`,
                },
            ],
        })
    }

    const hasAccessToRequestView = (name) => {
        if (name.toLowerCase() === 'myrequests')
            return hasAccessToRequestViewMyRequestsPage
        else if (name.toLowerCase() === 'mytasks')
            return hasAccessToRequestViewMyTasksPage
        else return hasAccessToRequestViewAllRequestsPage
    }
    const hasAccessToItemView = (name) => {
        if (name.toLowerCase() === 'myrequests')
            return hasAccessToItemViewMyRequestsPage
        else if (name.toLowerCase() === 'mytasks')
            return hasAccessToItemViewMyTasksPage
        else return hasAccessToItemViewAllRequestsPage
    }

    return { routeOptions, hasAccessToRequestView, hasAccessToItemView }
}
