import React from 'react'
import {
    AdditionalApproversForm,
    AssignCurrentStepForm,
    AssignTaskForm,
    Modal,
} from 'components'
import { useCurrentPerson } from 'hooks'
import { useTranslation } from 'react-i18next'
import useSubcomponents from 'useSubcomponents'

const DelegateForms = ({
    isAddingAdditionalApprovers,
    addAdditionalApprovers,
    isCreatingPermanentStepDelegation,
    createPermanentStepDelegation,
    isAssigning,
    assign,
    isClaiming,
    claim,
    setModalState,
    modalState,
    currentApprovalStepId,
}) => {
    const { data: currentPerson } = useCurrentPerson()
    const { t } = useTranslation()

    const { hasAccessToOutOfOfficeDelegateControl } = useSubcomponents()
    return (
        <>
            {modalState === 'Approvers' && (
                <Modal
                    open={modalState === 'Approvers'}
                    onClose={() => setModalState('')}
                    title={t('MyTasks_AddAdditionalApprovers')}
                >
                    <AdditionalApproversForm
                        onSave={(values) => {
                            if (
                                values &&
                                values.person &&
                                values.person.length > 0
                            ) {
                                const data = values.person.map((p) => p.id)
                                addAdditionalApprovers(data).then(() =>
                                    setModalState(''),
                                )
                            }
                        }}
                        isSaving={isAddingAdditionalApprovers}
                        onCancel={() => setModalState('')}
                    />
                </Modal>
            )}
            {modalState === 'AssignCurrentStep' && (
                <Modal
                    title={t('MyTasks_AlwaysDelegateThisStep')}
                    open={modalState === 'AssignCurrentStep'}
                    onClose={() => setModalState('')}
                >
                    <AssignCurrentStepForm
                        onSave={(values) => {
                            const data = {
                                stepId: currentApprovalStepId,
                                personIds: values.person.map((p) => p.id),
                                startDateUtc: values.startDate,
                                endDateUtc: values.endDate,
                            }
                            if (hasAccessToOutOfOfficeDelegateControl) {
                                data.onlyWhenOutOfOffice =
                                    values.activeOnlyIfOutOfOffice
                            }
                            createPermanentStepDelegation(data).then(() =>
                                setModalState(''),
                            )
                        }}
                        isSaving={isCreatingPermanentStepDelegation}
                        onCancel={() => setModalState('')}
                    />
                </Modal>
            )}
            {modalState === 'AssignTask' && (
                <Modal
                    open={modalState === 'AssignTask'}
                    onClose={() => setModalState('')}
                    title={t('MyTasks_AssignThisTask')}
                >
                    <AssignTaskForm
                        onSave={(values) => {
                            if (values && values.person) {
                                const personId = values.person.id
                                const stepId = currentApprovalStepId
                                const data = { personId, stepId }

                                if (personId === currentPerson.id) {
                                    claim(currentApprovalStepId).then(() =>
                                        setModalState(''),
                                    )
                                } else {
                                    assign(data).then(() => setModalState(''))
                                }
                            }
                        }}
                        isSaving={isAssigning || isClaiming}
                        onCancel={() => setModalState('')}
                    />
                </Modal>
            )}
        </>
    )
}
export default DelegateForms
