import React, { useState, useEffect } from 'react'
import { isNilOrEmpty, useDebounce } from 'packages/core'
import { InputBase, withStyles, makeStyles, Paper } from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'

const StyledInput = withStyles((theme) => ({
    root: {
        width: '100% !important',
        borderRadius: '5px',
    },
    input: {
        position: 'relative',
        fontSize: 16,
        color: '#aab0b4',
        padding: '9.5px 6px',
    },

    focused: {
        backgroundColor: theme.palette.common.white,
    },
}))(InputBase)

const useStyles = makeStyles({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#ffffff !important',
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        border: 'solid 1px #ebebed',
        borderRadius: '5px',
        height: '40px',
    },

    outlined: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#ffffff !important',
        boxShadow: 'none !important',
        border: 'solid 1px #d8d8dd',
        borderRadius: '3px',
        fontSize: '13px',
        paddingLeft: '10px',
        '&  .MuiInputBase-input': {
            fontSize: '13px !important',
        },
        '&:hover': {
            border: '1px solid #307fc1',
        },

        '&:focus-within': {
            border: '1px solid #307fc1',
            boxShadow: '0 4px 8px 0 rgba(48, 127, 193, 0.33)',
        },
    },

    icon: {
        margin: '0px 6px',
    },

    clearIcon: {
        margin: '0px 10px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
})

const Search = (props) => {
    const { handleSearch, handleClear, placeholder, value, outlined } = props
    const { t } = useTranslation()
    const [searchKey, setSearchKey] = useState(value)

    useEffect(() => {
        if (value !== searchKey) {
            setSearchKey(value)
        }
    }, [value])

    const handleChange = (event) => {
        const keyword = event.target.value
        setSearchKey(keyword)
    }
    const handleClearSearch = () => {
        setSearchKey('')
        handleClear('')
    }
    const debouncedValue = useDebounce(searchKey)

    useEffect(() => {
        handleSearch(debouncedValue)
    }, [debouncedValue])

    const classes = useStyles()

    return (
        <Paper className={!outlined ? classes.root : classes.outlined}>
            <Icon name="Search" color="#D8D8DD" className={classes.icon} />

            <StyledInput
                value={searchKey}
                onChange={handleChange}
                placeholder={placeholder ?? t('MyTasks_Search')}
            />

            {!isNilOrEmpty(searchKey) && (
                <Icon
                    name="Close"
                    color="#D8D8DD"
                    className={classes.clearIcon}
                    onClick={handleClearSearch}
                />
            )}
        </Paper>
    )
}

export default Search
