import React from 'react'
import Tabs from './Tabs'

const ItemDetails = () => {
    return <></>
}

ItemDetails.Tabs = Tabs

export default ItemDetails
