import { Box, Divider } from '@material-ui/core'
import { OverviewSectionSkeleton, Person, ProgressIndicator } from 'components'
import moment from 'moment'
import { StatusIndicator } from 'packages/eid-ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRequestAccessRecipient, usePerson } from 'hooks'
import {
    Container,
    AttributeContainer,
    AttributeLabel,
    AttributeValue,
    styles,
    VerticalAlignedLabel,
    useStyles,
} from 'components/DetailsComponents'
import { getFullImageUrl, roundOffNumber } from 'utils'
import { useRegistry } from 'core/useRegistry'

const statusStyles = {
    color: '#9b9b9b',
    maxWidth: '270px',
}

const Overview = ({ data }) => {
    const registry = useRegistry()
    const { t } = useTranslation()

    const classes = useStyles()

    const { data: accessRecipient } = useRequestAccessRecipient(
        data.id,
        data.targetPersonId,
    )

    const { data: initiatorPerson } = usePerson(data?.initiatorPersonId)

    const requestStatus = data?.preApproved ? 'PreApproved' : data?.status

    if (!data || !accessRecipient) {
        return (
            <Container>
                <OverviewSectionSkeleton />
            </Container>
        )
    }

    const subjectTypeInstance = registry.getSubjectType(
        accessRecipient.subjectType,
    )

    const SubjectDetailsComponent = subjectTypeInstance.detailComponent

    return (
        <Container>
            <Box classes={{ root: classes.profileContainer }}>
                <SubjectDetailsComponent accessRecipient={accessRecipient} />
            </Box>

            <Divider orientation="vertical" flexItem style={styles.divider} />

            <Box classes={{ root: classes.detailsContainer }}>
                {data?.currentApprovalStepName && (
                    <AttributeContainer>
                        <AttributeLabel>{t('MyTasks_Step')}</AttributeLabel>
                        <AttributeValue
                            style={{
                                fontWeight: 700,
                            }}
                            value={data?.currentApprovalStepName}
                        />
                    </AttributeContainer>
                )}

                <AttributeContainer
                    classes={{ root: classes.progressAttribute }}
                >
                    <VerticalAlignedLabel
                        style={{
                            color: '#5d6870',
                            fontWeight: '600',
                        }}
                    >
                        {t('MyTasks_PercentageCompleted', {
                            value: `${roundOffNumber(
                                data?.percentageCompleted * 100,
                            )} %`,
                        })}
                    </VerticalAlignedLabel>

                    <Box classes={{ root: classes.progressIndicatorContainer }}>
                        <Box>
                            <ProgressIndicator
                                percentageCompleted={
                                    data?.percentageCompleted * 100
                                }
                            />
                        </Box>
                        <Box display="flex">
                            <StatusIndicator
                                size="large"
                                variant={requestStatus}
                                label={t(`MyTasks_${requestStatus}`)}
                            />
                        </Box>
                    </Box>
                </AttributeContainer>

                <AttributeContainer>
                    <VerticalAlignedLabel>
                        {t('MyTasks_Requestor')}
                    </VerticalAlignedLabel>

                    <Box classes={{ root: classes.person }}>
                        <Person.Details
                            imgSrc={getFullImageUrl(
                                initiatorPerson?.imageThumbUrl,
                            )}
                            size="large"
                            name={
                                initiatorPerson
                                    ? initiatorPerson.friendlyName
                                    : data?.initiatorPersonFriendlyName
                            }
                            status={initiatorPerson?.login}
                            statusStyles={statusStyles}
                            showTooltip={true}
                        />
                    </Box>
                </AttributeContainer>

                <AttributeContainer>
                    <AttributeLabel>{t('MyTasks_RequestID')}</AttributeLabel>
                    <AttributeValue value={data?.number} />
                </AttributeContainer>

                <AttributeContainer>
                    <AttributeLabel>{t('MyTasks_CreatedOn')}</AttributeLabel>
                    <AttributeValue
                        value={
                            !data
                                ? ''
                                : moment
                                      .utc(data.createdDateUtc)
                                      .local()
                                      .format('LLL')
                        }
                    />
                </AttributeContainer>

                {data?.dueDateUtc && (
                    <AttributeContainer>
                        <AttributeLabel>{t('MyTasks_DueDate')}</AttributeLabel>
                        <AttributeValue
                            value={
                                !data || !data.dueDateUtc
                                    ? ''
                                    : moment
                                          .utc(data.dueDateUtc)
                                          .local()
                                          .format('LLL')
                            }
                        />
                    </AttributeContainer>
                )}

                <AttributeContainer>
                    <AttributeLabel>
                        {t('MyTasks_LastModifiedOn')}
                    </AttributeLabel>

                    <AttributeValue
                        value={
                            !data
                                ? ''
                                : moment
                                      .utc(data.modifiedDateUtc)
                                      .local()
                                      .format('LLL')
                        }
                    />
                </AttributeContainer>

                <AttributeContainer>
                    <AttributeLabel>
                        {t('MyTasks_ExpirationDate')}
                    </AttributeLabel>

                    <AttributeValue
                        value={
                            !data
                                ? ''
                                : moment
                                      .utc(data.expirationDateUtc)
                                      .local()
                                      .format('LLL')
                        }
                    />
                </AttributeContainer>

                <AttributeContainer>
                    <AttributeLabel>
                        {t('MyTasks_InactivityExpirationDate')}
                    </AttributeLabel>

                    <AttributeValue
                        value={
                            !data
                                ? ''
                                : moment
                                      .utc(data.inactivityExpirationDateUtc)
                                      .local()
                                      .format('LLL')
                        }
                    />
                </AttributeContainer>
            </Box>
        </Container>
    )
}

export default Overview
