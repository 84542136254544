import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { Box } from '@material-ui/core'

const PersonCardSkeleton = ({ ...rest }) => (
    <Box
        width={300}
        height={140}
        marginY="15px"
        padding="20px"
        boxShadow="rgba(0, 0, 0, 0.05) 0px 4px 16px 0px"
        borderRadius="8px"
        bgcolor="#ffffff"
        {...rest}
    >
        <Box display="flex" justifyContent="space-between">
            <Skeleton
                variant="circle"
                animation="wave"
                width={30}
                height={30}
            />
            <Skeleton variant="text" animation="wave" width="80%" height={24} />
        </Box>
        <Skeleton variant="text" animation="wave" height={24} />
        <Skeleton variant="text" animation="wave" height={24} />
        <Skeleton variant="text" animation="wave" height={24} />
    </Box>
)

export default PersonCardSkeleton
