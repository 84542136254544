import React, { Fragment } from 'react'
import { Box } from '@material-ui/core'
import { ApiError, Spinner } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { useRequestItemTypeSummaryByItemType } from 'hooks'
import { getStatusColor } from 'utils'
import config from 'config'

const StatusCountIndicator = ({ count, label, variant }) => {
    const color = getStatusColor(variant)

    return (
        <Box my="4px" fontSize="13px" color={color}>
            <Box component="span" fontWeight="bold" marginRight="8px">
                {count}
            </Box>
            {label}
        </Box>
    )
}

const ItemTypeSummaryDetailsTooltip = ({ requestId, itemTypeId }) => {
    const { t } = useTranslation()

    const {
        data,
        isLoading,
        isError,
        error,
    } = useRequestItemTypeSummaryByItemType(requestId, itemTypeId)

    return (
        <Box
            px="4px"
            py="4px"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            {isLoading ? (
                <Box my="8px" mx="8px">
                    <Spinner size={0.6} />
                </Box>
            ) : isError ? (
                <Box my="4px" color="#d0021b">
                    <ApiError
                        showDetails={config.SHOW_ERRORS}
                        error={error}
                        color="#ffffff"
                        fontSize="13px"
                    />
                </Box>
            ) : data.length > 0 ? (
                <Box>
                    <Box my="4px" fontSize="13px" color="#d0021b">
                        <StatusCountIndicator
                            label={t('MyTasks_Total')}
                            count={data[0].total}
                            variant="total"
                        />
                        {data[0].approved > 0 && (
                            <StatusCountIndicator
                                label={t('MyTasks_Approved')}
                                count={data[0].approved}
                                variant="approved"
                            />
                        )}
                        {data[0].rejected > 0 && (
                            <StatusCountIndicator
                                label={t('MyTasks_Rejected')}
                                count={data[0].rejected}
                                variant="rejected"
                            />
                        )}
                        {data[0].open > 0 && (
                            <StatusCountIndicator
                                label={t('MyTasks_Open')}
                                count={data[0].open}
                                variant="open"
                            />
                        )}
                    </Box>
                </Box>
            ) : (
                <Fragment>{t('MyTasks_NoDataFound')}</Fragment>
            )}
        </Box>
    )
}

export default ItemTypeSummaryDetailsTooltip
