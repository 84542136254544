import { Box, styled } from '@material-ui/core'

const IconContainer = styled(Box)({
    minWidth: '40px',
    width: '40px',
    height: '40px',

    borderRadius: '50%',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})

export default IconContainer
