import React, { useState } from 'react'
import {
    useAddAdditionalApprovers,
    useAssign,
    useClaim,
    useCreatePermanentStepDelegation,
    useCurrentPerson,
    useUnclaim,
} from 'hooks'
import DelegateForms from '../DelegateForms'
import { Dropdown, IconButton, Tooltip } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import {
    Box,
    makeStyles,
    MenuItem as MuiMenuItem,
    styled,
    Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { isNilOrEmpty } from 'packages/core'
import useSubcomponents from 'useSubcomponents'

const assignThisTaskOption = {
    title: 'MyTasks_AssignThisTask',
    name: 'AssignTask',
    helpText: 'MyTasks_AssignTaskHelpText',
}
const unassignThisTaskOption = {
    title: 'MyTasks_UnassignThisTask',
    name: 'UnassignTask',
    helpText: 'MyTasks_UnassignTaskHelpText',
}

const reassignThisTaskOption = {
    title: 'MyTasks_ReassignThisTask',
    name: 'AssignTask',
    helpText: 'MyTasks_ReassignTaskHelpText',
}

const addAdditionalApproverOption = {
    title: 'MyTasks_AddAdditionalApprovers',
    name: 'Approvers',
    helpText: 'MyTasks_AddAdditionalApproversHelpText',
}

const alwaysDelegateCurrentStepOption = {
    title: 'MyTasks_AlwaysDelegateThisStep',
    name: 'AssignCurrentStep',
    helpText: 'MyTasks_AlwaysDelegateThisStepHelpText',
}

const MenuItem = styled(MuiMenuItem)({
    position: 'relative',
    borderRadius: '5px',
    color: '#919193',
    whiteSpace: 'normal !important',
    '&:hover': {
        color: '#000000 !important',
    },
    '& p': {
        paddingRight: '30px',
    },

    '& svg': {
        position: 'absolute',
        right: 16,
        transform: 'translate(50%, -50%)',
        height: '16px !important',
        width: '16px !important',
        color: '#919193',
        '&:hover': {
            color: '#307fc1',
        },
    },

    '& .Tooltip-icon-container': {
        position: 'absolute',
        right: 20,
        transform: 'translate(50%, -50%)',
        width: '16px !important',
    },
})

const styles = {
    rootMenu: {
        paddingRight: '0px',
        marginTop: '10px',
        width: '270px',
    },
    list: {
        padding: '0px 6px',
    },
}

const AssignedToIndicator = styled(Box)({
    position: 'absolute',
    backgroundColor: '#5d6870',
    top: '-10px',
    left: '-5px',
    zIndex: '1',
    display: 'block',
    maxWidth: '130px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: '#ffffff',
    borderRadius: '6px',
    fontSize: '13px',
    padding: '0px 8px',
    textTransform: 'uppercase',
})

const useStyles = makeStyles({
    iconButton: ({ isClickable }) => ({
        borderRadius: '5px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',

        display: 'flex',
        marginLeft: '4px',
        marginRight: '4px',
        margin: 'auto',
        paddingLeft: '0px',
        paddingRight: '0px',
        minWidth: '60px',
        backgroundColor: 'inherit',
        '&:hover': {
            backgroundColor: isClickable ? '#efeff1 !important' : 'initial',
        },
    }),
})

const DelegateMenuIconButton = ({
    page,
    requestId,
    requestItemId,
    isApprover,
    currentApprovalStepId,
    assignedByPersonId,
    assignedByPersonFriendlyName,
    claimedApproverId,
    claimedApproverFriendlyName,
    allowAddApprover,
    allowAssignApprover,
}) => {
    const { t } = useTranslation()
    const [modalState, setModalState] = useState('')

    const { data: currentPerson } = useCurrentPerson()

    const [claim, { isLoading: isClaiming }] = useClaim(
        page,
        requestId,
        requestItemId,
    )
    const [unclaim, { isLoading: isUnclaiming }] = useUnclaim(
        page,
        requestId,
        requestItemId,
    )

    const [assign, { isLoading: isAssigning }] = useAssign(
        page,
        requestId,
        requestItemId,
    )
    const [
        addAdditionalApprovers,
        { isLoading: isAddingAdditionalApprovers },
    ] = useAddAdditionalApprovers(
        page,
        requestId,
        requestItemId,
        currentApprovalStepId,
    )
    const [
        createPermanentStepDelegation,
        { isLoading: isCreatingPermanentStepDelegation },
    ] = useCreatePermanentStepDelegation(page, requestId, requestItemId)

    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClose = () => {
        setAnchorEl(null)
        setOpen(false)
    }

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }

    const onChange = (option) => setModalState(option.name)

    const isAssigned = !isNilOrEmpty(assignedByPersonId)
    const isAssignedByMe = assignedByPersonId === currentPerson.id
    const isAssignedToMe = claimedApproverId === currentPerson.id
    const assignedTo = claimedApproverFriendlyName

    const {
        hasAccessToAdminAssignTaskDelegateControl,
        hasAccessToAssignTaskDelegateControl,
        hasAccessToAdminAddAdditionalApproversDelegateControl,
        hasAccessToAddAdditionalApproversDelegateControl,
        hasAccessToPermanentlyDelegateStepControl,
    } = useSubcomponents()

    const optionsToRender = []

    if (allowAssignApprover) {
        if (hasAccessToAdminAssignTaskDelegateControl) {
            if (isAssigned) {
                optionsToRender.push(unassignThisTaskOption)
                optionsToRender.push(reassignThisTaskOption)
            } else {
                optionsToRender.push(assignThisTaskOption)
            }
        } else {
            if (isApprover && hasAccessToAssignTaskDelegateControl) {
                if (isAssigned) {
                    optionsToRender.push(unassignThisTaskOption)
                    optionsToRender.push(reassignThisTaskOption)
                } else {
                    optionsToRender.push(assignThisTaskOption)
                }
            }
        }
    }

    if (allowAddApprover) {
        if (hasAccessToAdminAddAdditionalApproversDelegateControl) {
            optionsToRender.push(addAdditionalApproverOption)
        } else {
            if (
                isApprover &&
                hasAccessToAddAdditionalApproversDelegateControl
            ) {
                optionsToRender.push(addAdditionalApproverOption)
            }
        }
    }

    if (
        allowAssignApprover &&
        isApprover &&
        hasAccessToPermanentlyDelegateStepControl
    ) {
        optionsToRender.push(alwaysDelegateCurrentStepOption)
    }

    const assignedToDisplayName = isAssignedToMe ? t('MyTasks_You') : assignedTo
    const assignedByMessage = `${t(
        'MyTasks_AssignedBy',
    )}: ${assignedByPersonFriendlyName} ${t(
        'MyTasks_To',
    )} ${assignedToDisplayName}`

    const isClickable = optionsToRender.length > 0

    const classes = useStyles({ isClickable })

    return (
        <>
            <Box>
                {isAssigned && assignedTo ? (
                    <Box position="relative">
                        <Tooltip arrow={false} title={assignedByMessage}>
                            <Box>
                                <IconButton
                                    className={classes.iconButton}
                                    onClick={(e) => {
                                        if (!isClickable) return
                                        setOpen((prev) => !prev)
                                        setAnchorEl(e.currentTarget)
                                    }}
                                >
                                    <Icon
                                        name={isClickable ? 'Delegate' : 'Info'}
                                        color="#307fc1"
                                        width={isClickable ? 26 : 20}
                                        height={isClickable ? 26 : 20}
                                    />
                                </IconButton>
                            </Box>
                        </Tooltip>
                    </Box>
                ) : (
                    isClickable && (
                        <IconButton
                            className={classes.iconButton}
                            onClick={(e) => {
                                setOpen((prev) => !prev)
                                setAnchorEl(e.currentTarget)
                            }}
                        >
                            <Icon
                                name="Delegate"
                                color="#307fc1"
                                width="26"
                                height="26"
                            />
                        </IconButton>
                    )
                )}

                {isClickable && (
                    <Dropdown
                        open={open}
                        anchorEl={anchorEl}
                        rootStyles={styles.rootMenu}
                        listStyles={styles.list}
                        handleClose={handleClose}
                        handleListKeyDown={handleListKeyDown}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        {optionsToRender.map((option) => (
                            <MenuItem
                                key={option.name}
                                onClick={() => {
                                    if (option.name === 'UnassignTask') {
                                        unclaim(currentApprovalStepId).then(
                                            () => {
                                                setOpen(false)
                                            },
                                        )
                                    } else {
                                        setOpen(false)
                                        onChange(option)
                                    }
                                }}
                            >
                                <Typography>{t(option.title)}</Typography>
                                <Tooltip title={t(option.helpText)}>
                                    <span className="Tooltip-icon-container">
                                        <Icon
                                            name="Info"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                            }}
                                        />
                                    </span>
                                </Tooltip>
                            </MenuItem>
                        ))}
                    </Dropdown>
                )}
            </Box>

            <DelegateForms
                isAddingAdditionalApprovers={isAddingAdditionalApprovers}
                addAdditionalApprovers={addAdditionalApprovers}
                isCreatingPermanentStepDelegation={
                    isCreatingPermanentStepDelegation
                }
                createPermanentStepDelegation={createPermanentStepDelegation}
                isAssigning={isAssigning}
                assign={assign}
                isClaiming={isClaiming}
                claim={claim}
                setModalState={setModalState}
                modalState={modalState}
                currentApprovalStepId={currentApprovalStepId}
            />
        </>
    )
}
export default DelegateMenuIconButton
