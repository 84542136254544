import React from 'react'
import { Box, styled, Typography } from '@material-ui/core'
import { Tooltip } from 'packages/eid-ui'

const Line = styled(Box)({
    display: 'flex',

    height: '1px',
    width: '100%',

    backgroundColor: '#01ae8f',
})

const ArrowShape = styled(Box)({
    width: 0,
    height: 0,

    borderTop: '6px solid transparent',
    borderBottom: '6px solid transparent',
    borderLeft: '10px solid #01ae8f',

    borderTopLeftRadius: '2px',
    borderBottomLeftRadius: '2px',
})

const DelegationArrow = ({
    actionFriendlyName,
    stepFriendlyName,
    createdDateTime,
    variant = 'Continue',
    ...rest
}) => {
    return (
        <Box
            position="relative"
            display="flex"
            alignItems="center"
            width="50%"
            {...rest}
        >
            <Box position="absolute" left="50%">
                <Box
                    position="relative"
                    left="-50%"
                    padding="1px 8px"
                    borderRadius="5px"
                    style={{
                        backgroundImage:
                            'linear-gradient(97deg, #01ae8f, #30c17c)',
                    }}
                >
                    <Tooltip title={actionFriendlyName}>
                        <Typography
                            overflow="elipsis"
                            style={{
                                fontSize: '12px',
                                fontWeight: '600',
                                maxWidth: '300px',
                                color: '#ffffff',
                                textTransform: 'uppercase',
                                lineHeight: 1.33,
                            }}
                            noWrap={true}
                        >
                            {actionFriendlyName}
                        </Typography>
                    </Tooltip>
                </Box>
            </Box>
            <Box position="absolute" left="50%">
                <Box
                    position="relative"
                    left="-50%"
                    marginTop="44px"
                    padding="1px 8px"
                    borderRadius="5px"
                    border="1px solid #aab0b4"
                    bgcolor="#ffffff"
                >
                    <Tooltip title={stepFriendlyName}>
                        <Typography
                            overflow="elipsis"
                            style={{
                                fontSize: '12px',
                                fontWeight: 600,
                                maxWidth: '320px',
                                color: '#000000',
                                textTransform: 'uppercase',
                                lineHeight: 1.25,
                            }}
                            noWrap={true}
                        >
                            {stepFriendlyName}
                        </Typography>
                    </Tooltip>
                </Box>
            </Box>
            <Line
                style={{
                    backgroundColor: '#01ae8f',
                }}
            />
            <ArrowShape style={{ borderLeftColor: '#01ae8f' }} />
        </Box>
    )
}

export default DelegationArrow
