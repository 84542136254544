import { Box, createStyles, makeStyles } from '@material-ui/core'
import { RiskIndicator, Tooltip } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import RiskReason from './RiskReason'
import { IItemRisk } from 'types'
import classNames from 'classnames'
import { Fragment } from 'react'
import { RiskViolationStatus } from '../V2/RiskViolationStatus'

export const smallScreenWidth = 959

const useStyles = makeStyles(
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        riskDetails: {
            display: 'flex',
        },
        riskIndicator: {
            width: '90px',
        },
        details: {
            marginLeft: '16px',
            display: 'flex',
            flexDirection: 'column',
            [`@media (max-width:${smallScreenWidth}px)`]: {
                justifyContent: 'center',
            },
        },

        detailsHeading: {
            fontSize: '14px',
            fontWeight: 600,
            color: '#000000',
            lineHeight: 1.43,
        },
        detailsHeadingHover: {
            '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline',
            },
        },

        detailsLabel: {
            fontSize: '12px',
            color: '#5d6870',
            lineHeight: 1.33,
        },
        friendlyNameDivMobile: {
            fontSize: '14px',
            fontWeight: 'bold',
            lineHeight: 1.43,
            padding: '5px 0px',

            [`@media (min-width:${smallScreenWidth}px)`]: {
                display: 'none',
            },
        },
        friendlyNameDiv: {
            [`@media (max-width:${smallScreenWidth}px)`]: {
                display: 'none',
            },
        },
        textWrap: {
            wordBreak: 'break-word',
            wordWrap: 'break-word',
            overflow: 'auto',
        },
    }),
)

const tooltipStyles = {
    minWidth: '376px',
    boxShadow:
        '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    borderRadius: '5px',
}

export const RiskViolation = ({
    risk,
    requestId,
    showReason = false,
}: {
    risk: IItemRisk
    requestId: string
    showReason?: boolean
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const friendlyName = (
        <Box
            className={classNames(classes.detailsHeading, {
                [classes.detailsHeadingHover]: !showReason,
            })}
        >
            {risk.localRiskFriendlyName}
        </Box>
    )

    const renderFriendlyName = () => {
        if (risk.extendedRiskDescription) {
            return (
                <Tooltip title={risk.extendedRiskDescription}>
                    {friendlyName}
                </Tooltip>
            )
        } else {
            return <Fragment>{friendlyName}</Fragment>
        }
    }

    return (
        <Box className={classes.root}>
            <Box
                className={classNames(
                    classes.friendlyNameDivMobile,
                    classes.textWrap,
                )}
            >
                {renderFriendlyName()}
            </Box>

            <Box className={classes.riskDetails}>
                <Box className={classes.riskIndicator}>
                    <RiskIndicator
                        label={t(`MyTasks_${risk.riskLevel}Risk`)}
                        variant={risk.riskLevel}
                    />
                </Box>
                <Box className={classes.details}>
                    <Box
                        className={classNames(
                            classes.friendlyNameDiv,
                            classes.textWrap,
                        )}
                    >
                        {renderFriendlyName()}
                    </Box>

                    <Box className={classes.detailsLabel}>
                        {t(`MyTasks_${risk.riskType}`)}
                    </Box>
                </Box>
                <RiskViolationStatus risk={risk} />
            </Box>

            {showReason && <RiskReason risk={risk} requestId={requestId} />}
        </Box>
    )
}
