import { Box, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
    expandIcon: {
        width: '18px',
        height: '18px',
        marginLeft: '8px',
        borderRadius: '50%',
        border: '2px solid #307fc1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        color: '#307fc1',
    },

    expandButton: {
        textTransform: 'uppercase',
        '&:hover': {
            cursor: 'pointer',
        },
    },
})

const ShowHideButton = (props) => {
    const { expanded, onClick, withLabel = true } = props
    const classes = useStyles()
    const { t } = useTranslation()
    return (
        <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            fontSize="12px"
            fontWeight="bold"
            color="#307fc1"
        >
            <Box
                component="span"
                display="flex"
                className={classes.expandButton}
                onClick={onClick}
            >
                {withLabel &&
                    (expanded ? t('MyTasks_Hide') : t('MyTasks_Show'))}
                <Box
                    classes={{
                        root: classes.expandIcon,
                    }}
                >
                    {expanded ? '-' : '+'}
                </Box>
            </Box>
        </Box>
    )
}

export default ShowHideButton
