import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { Box, styled } from '@material-ui/core'

const ListItemSkeleton = styled(Skeleton)({
    width: '100%',
    margin: '8px',
    height: 20,
    borderRadius: '10px',
})

const SkeletonContainer = styled('div')({
    height: 'max-content',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
})

const Loader = () => {
    return (
        <Box width="100%" height="100%">
            <SkeletonContainer>
                <ListItemSkeleton animation="wave" variant="rect" />
            </SkeletonContainer>
        </Box>
    )
}

export default Loader
