import React from 'react'
import Button from './index'
import { Icon, ArrowIcon } from 'packages/eid-icons'

const styles = {
    normal: {
        width: '100%',
        minWidth: '95px',
        backgroundColor: '#ffffff',
        color: '#307fc1',
        border: '1px solid #307fc1',
        '&:hover': {
            filter: 'brightness(0.99)',
        },
        '&:active': {},
    },
    disabled: {
        backgroundColor: '#aab0b4 !important',
        color: '#ffffff !important',
    },
}

const BackButton = ({ ...rest }) => {
    return (
        <Button
            size="medium"
            style={styles.normal}
            disabledStyles={styles.disabled}
            startIcon={
                <ArrowIcon
                    style={{ transform: 'rotate(-90deg)' }}
                    color="#307fc1"
                />
            }
            {...rest}
        />
    )
}

export default BackButton
