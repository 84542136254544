import * as React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { CommentsList } from 'components'
import CommentsSectionContainer from '../CommentsSectionContainer'
import {
    useAddComment,
    useRequestViewItemComments,
    useDeleteComment,
    useEditComment,
} from 'hooks'

const useStyles = makeStyles({
    commentsListContainer: {
        paddingRight: '18px',
        paddingTop: '10px',
        width: '100%',
        maxWidth: '1000px',
    },
})

const commentsContainerProps = {
    style: {
        maxHeight: '400px',
        overflow: 'auto',
        paddingRight: '20px',
        marginRight: '-15px',
        paddingBottom: '36px',
    },
    width: 'auto',
}

export interface BusinessRequestItemCommentsProps {
    requestId: string
    itemId: string
    collapsible?: boolean
}

const BusinessRequestItemComments: React.FC<BusinessRequestItemCommentsProps> = ({
    requestId,
    itemId,
    collapsible,
}) => {
    const {
        data: comments,
        isLoading: loadingComments,
    } = useRequestViewItemComments(requestId, itemId)

    const [addComment, { isLoading: isSubmitting }]: any = useAddComment(
        'Requests',
        requestId,
        itemId,
    )

    const [deleteComment, { isLoading: isDeleting }] = useDeleteComment(
        'Requests',
        requestId,
        itemId,
    )

    const [editComment, { isLoading: isEditing }]: any = useEditComment(
        'Requests',
        requestId,
        itemId,
    )

    const classes = useStyles()

    const content = (
        <Box width="100%">
            <Box className={classes.commentsListContainer}>
                <CommentsList
                    page="Requests"
                    commentsContainerProps={commentsContainerProps}
                    requestId={requestId}
                    itemId={itemId}
                    list={comments}
                    isSubmitting={isSubmitting}
                    loading={loadingComments}
                    handleAddComment={(value: any) =>
                        addComment({
                            businessRequestId: requestId,
                            businessRequestItemId: itemId,
                            value,
                        })
                    }
                    handleDeleteComment={(id: any) => {
                        deleteComment(id)
                    }}
                    handleEditComment={(id: any, value: any) => {
                        editComment({
                            id,
                            value,
                        })
                    }}
                />
            </Box>
        </Box>
    )

    return collapsible ? (
        <CommentsSectionContainer comments={comments}>
            {content}
        </CommentsSectionContainer>
    ) : (
        content
    )
}

export default BusinessRequestItemComments
