import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import {
    ApproversInfoMessage,
    Person,
    PersonCardSkeleton,
    Search,
} from 'components'
import { Pagination } from 'packages/eid-ui'
import { smallScreenWidth } from 'utils'
import { useTranslation } from 'react-i18next'
import { isNilOrEmpty } from 'packages/core'
import { Container } from 'components/DetailsComponents'

const useStyles = makeStyles({
    root: {
        position: 'relative',
        bgcolor: '#ffffff',
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        [`@media (max-width:${smallScreenWidth})`]: {
            boxShadow: 'none',
        },
    },
    searchContainer: {
        maxWidth: '280px',
        padding: '24px 20px 0px 20px',
        [`@media (max-width:${smallScreenWidth})`]: {
            maxWidth: '340px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            margin: 'auto',
        },
    },
    approversContainer: {
        maxHeight: '460px',
        overflow: 'auto',
        padding: '0 10px 18px 10px',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        [`@media (max-width:${smallScreenWidth})`]: {
            flexDirection: 'column',
            alignItems: 'center',
            flexWrap: 'nowrap',
        },
    },

    paginationSteppersContainer: {
        width: '100%',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        bottom: 0,
        left: 0,
    },
})

const ApproversList = ({
    totalCount,
    visibleCount,
    isLoading,
    data,
    page,
    handlePageChange,
    numberOfPages,
    searchTerm,
    handleSearch,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const showInfoMessage = totalCount === 0 || totalCount !== visibleCount
    const showSearch = data?.length > 0 || searchTerm.length > 0

    return (
        <Container>
            <Box
                className={classes.root}
                paddingBottom={numberOfPages > 1 ? '50px' : '10px'}
            >
                {showSearch && (
                    <Box className={classes.searchContainer}>
                        <Search
                            fullWidth
                            outlined
                            placeholder={t('MyTasks_Search')}
                            value={searchTerm}
                            handleClear={(value) => {
                                handlePageChange(1)
                                handleSearch(value)
                            }}
                            handleSearch={(value) => {
                                if (searchTerm !== value) {
                                    handlePageChange(1)
                                    handleSearch(value)
                                }
                            }}
                        />
                    </Box>
                )}

                {numberOfPages > 1 && (
                    <Box className={classes.paginationSteppersContainer}>
                        <Pagination
                            count={numberOfPages}
                            size="small"
                            page={page}
                            onChange={(_, value) => handlePageChange(value)}
                        />
                    </Box>
                )}

                {isLoading && (
                    <Box className={classes.approversContainer}>
                        <Box className={classes.container}>
                            <PersonCardSkeleton marginX="10px" />
                            <PersonCardSkeleton marginX="10px" />
                            <PersonCardSkeleton marginX="10px" />
                        </Box>
                    </Box>
                )}

                {!isLoading && showInfoMessage && isNilOrEmpty(searchTerm) && (
                    <Box marginY="20px" marginRight="40px" paddingX="20px">
                        <ApproversInfoMessage
                            totalCount={totalCount}
                            visibleCount={visibleCount}
                        />
                    </Box>
                )}

                {data && data.length === 0 && (
                    <Box
                        marginY={'20px'}
                        display={'flex'}
                        justifyContent={'center'}
                    >
                        {t('MyTasks_NoDataFound')}
                    </Box>
                )}

                {!isLoading && (
                    <Box className={classes.approversContainer}>
                        <Box className={classes.container}>
                            {data.map((p) => (
                                <Box key={p.id} marginY="15px" paddingX="10px">
                                    <Person.Card person={p} />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                )}
            </Box>
        </Container>
    )
}

export default ApproversList
