import { useMutation } from 'react-query'
import { useAxios } from 'packages/core'
import { refreshComments } from './utils'

export const useAddComment = (page, requestId, itemId) => {
    const callApi = useAxios()

    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: '/api/comments/create',
                data,
            }),
        {
            onSuccess: () => refreshComments(page, requestId, itemId),
        },
    )
}

export const useReplyComment = (page, requestId, itemId) => {
    const callApi = useAxios()

    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: '/api/comments/reply',
                data,
            }),
        {
            onSuccess: () => refreshComments(page, requestId, itemId),
        },
    )
}

export const useEditComment = (page, requestId, itemId) => {
    const callApi = useAxios()

    return useMutation(
        (data) =>
            callApi({
                method: 'PUT',
                url: '/api/Comments/edit',
                data: data,
            }),
        {
            onSuccess: () => refreshComments(page, requestId, itemId),
        },
    )
}

export const useDeleteComment = (page, requestId, itemId) => {
    const callApi = useAxios()

    return useMutation(
        (id) =>
            callApi({
                method: 'DELETE',
                url: `/api/Comments/delete/${id}`,
            }),
        {
            onSuccess: () => {
                refreshComments(page, requestId, itemId)
            },
        },
    )
}
