import React from 'react'
import RequestItemsLayout from './RequestItemsLayout'
import {
    useMyRequestsItems,
    useMyRequestItemsTasks,
    useAllRequestsItems,
} from 'hooks'
import useSubcomponents from 'useSubcomponents'

const allFilters = {
    myRequests: [
        {
            name: 'requestedBy',
            requireAccess: 'MyTasks-MyRequestsRequestedByFilter-Control',
        },
        {
            name: 'subjectType',
            requireAccess: 'MyTasks-MyRequestsRequestedForFilter-Control',
        },
        {
            name: 'approver',
            requireAccess: 'MyTasks-MyRequestsApproverFilter-Control',
        },
        {
            name: 'preApproved',
            requireAccess: 'MyTasks-ShowPreApprovedFilter-Control',
        },
        {
            name: 'requestItemStatus',
            requireAccess: 'MyTasks-MyRequestsItemViewStatusFilter-Control',
        },
        {
            name: 'itemType',
            requireAccess: 'MyTasks-MyRequestsItemTypeFilter-Control',
        },
        {
            name: 'riskLevel',
            requireAccess: 'MyTasks-RiskLevelFilter-Control',
        },
        {
            name: 'fulfillmentStatus',
            requireAccess: 'MyTasks-FulfillmentStatusFilter-Control'
        },
        {
            name: 'requestItemsAdvancedSearch',
            requireAccess:
                'MyTasks-MyRequestsRequestItemViewAdvancedSearchFilter-Control',
        },
    ],
    myTasks: [
        {
            name: 'requestedBy',
            requireAccess: 'MyTasks-MyTasksRequestedByFilter-Control',
        },
        {
            name: 'subjectType',
            requireAccess: 'MyTasks-MyTasksRequestedForFilter-Control',
        },
        {
            name: 'itemType',
            requireAccess: 'MyTasks-MyTasksItemTypeFilter-Control',
        },
        {
            name: 'riskLevel',
            requireAccess: 'MyTasks-RiskLevelFilter-Control',
        },
        {
            name: 'fulfillmentStatus',
            requireAccess: 'MyTasks-FulfillmentStatusFilter-Control'
        },
        {
            name: 'requestItemsAdvancedSearch',
            requireAccess:
                'MyTasks-MyRequestsRequestItemViewAdvancedSearchFilter-Control',
        },
    ],
    allRequests: [
        {
            name: 'requestedBy',
            requireAccess: 'MyTasks-AllRequestsRequestedByFilter-Control',
        },
        {
            name: 'subjectType',
            requireAccess: 'MyTasks-AllRequestsRequestedForFilter-Control',
        },
        {
            name: 'approver',
            requireAccess: 'MyTasks-AllRequestsApproverFilter-Control',
        },
        {
            name: 'preApproved',
            requireAccess: 'MyTasks-ShowPreApprovedFilter-Control',
        },
        {
            name: 'requestItemStatus',
            requireAccess: 'MyTasks-AllRequestsItemViewStatusFilter-Control',
        },
        {
            name: 'itemType',
            requireAccess: 'MyTasks-AllRequestsItemTypeFilter-Control',
        },
        {
            name: 'riskLevel',
            requireAccess: 'MyTasks-RiskLevelFilter-Control',
        },
        {
            name: 'fulfillmentStatus',
            requireAccess: 'MyTasks-FulfillmentStatusFilter-Control'
        },
        {
            name: 'requestItemsAdvancedSearch',
            requireAccess:
                'MyTasks-MyRequestsRequestItemViewAdvancedSearchFilter-Control',
        },
    ],
}

const ItemViewMyRequests = () => {
    const {
        list,
        totalCount,
        loading,
        loadingMore,
        pagination,
        refetch,
    } = useMyRequestsItems()

    const { trimFiltersBasedOnAccess } = useSubcomponents()

    const filters = trimFiltersBasedOnAccess(allFilters.myRequests).map(
        (f) => f.name,
    )

    return (
        <RequestItemsLayout
            totalCount={totalCount}
            list={list}
            isLoading={loading}
            isLoadingMore={loadingMore}
            pagination={pagination}
            filters={filters}
            refetch={refetch}
        />
    )
}

const ItemViewMyTasks = () => {
    const {
        list,
        totalCount,
        loading,
        loadingMore,
        pagination,
        refetch,
    } = useMyRequestItemsTasks()

    const { trimFiltersBasedOnAccess } = useSubcomponents()

    const filters = trimFiltersBasedOnAccess(allFilters.myTasks).map(
        (f) => f.name,
    )

    return (
        <RequestItemsLayout
            totalCount={totalCount}
            list={list}
            isLoading={loading}
            isLoadingMore={loadingMore}
            pagination={pagination}
            filters={filters}
            refetch={refetch}
        />
    )
}

const ItemViewAllRequests = () => {
    const {
        list,
        totalCount,
        loading,
        loadingMore,
        pagination,
        refetch,
    } = useAllRequestsItems()

    const { trimFiltersBasedOnAccess } = useSubcomponents()

    const filters = trimFiltersBasedOnAccess(allFilters.allRequests).map(
        (f) => f.name,
    )

    return (
        <RequestItemsLayout
            totalCount={totalCount}
            list={list}
            isLoading={loading}
            isLoadingMore={loadingMore}
            pagination={pagination}
            filters={filters}
            refetch={refetch}
        />
    )
}

export { ItemViewMyRequests, ItemViewMyTasks, ItemViewAllRequests }
