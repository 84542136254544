const attributes = [
    {
        isHeader: true,
        name: 'description',
        sortable: true,
        sortBy: 'Description',
        label: 'MyTasks_Name',
        headerCellStyles: {
            width: '100%',
            minWidth: '180px',
        },
        styles: {
            color: '#9230c5',
            fontSize: '16px',
            fontWeight: 'bold',
            width: '100%',
            minWidth: '180px',
            marginTop: '6px',
            marginLeft: '10px',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        link: false,
    },
    {
        name: 'number',
        sortable: true,
        sortBy: 'Number',
        label: 'MyTasks_BusinessRequestNumber',

        headerCellStyles: {
            width: '110px',
            minWidth: '110px',
        },
        styles: {
            width: '100px',
            color: '#282828',
            fontSize: '13px',
            marginTop: '6px'
        },
    },
    {
        name: 'status',
        hideValue: true,
        label: 'MyTasks_Status',
        sortable: true,
        sortBy: 'Status',

        headerCellStyles: {
            width: '180px',
            minWidth: '180px',
        },
        styles: {
            padding: '20px 0px'
        },
        component: {
            componentName: 'StatusIndicator',
            name: 'status',
            props: {
                size: 'medium',
            },
        },
    },
    {
        name: 'initiatorPersonFriendlyName',
        label: 'MyTasks_RequestedBy',
        hideValue: true,
        sortable: true,
        sortBy: 'InitiatorPersonFriendlyName',
        avatar: true,
        imgProp: 'initiatorPersonThumbnailUrl',
        headerCellStyles: {
            width: '240px',
            minWidth: '240px',
        },
        styles: {
            width: '240px',
        },
        textStyles: {
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        name: 'targetPersonFriendlyName',
        label: 'MyTasks_RequestedFor',
        sortBy: 'SubjectFriendlyName',
        hideValue: true,
        avatar: false,
        sortable: true,
        headerCellStyles: {
            width: '240px',
            minWidth: '240px',
        },
        styles: {
            width: '240px',
        },
        component: {
            componentName: 'SubjectType',
            props: {
                size: 'small',
                style: {
                    color: '#282828',
                    fontSize: '13px',
                },
            },
        },
    },
    {
        name: 'createdDateUtc',
        hideValue: true,
        label: 'MyTasks_CreatedDate',
        sortable: true,
        sortBy: 'CreatedDateUtc',

        headerCellStyles: {
            width: '130px',
            minWidth: '130px',
        },
        styles: {
            width: '130px',
        },
        component: { componentName: 'CellDateTime' },
    },
    {
        name: 'Risk',
        hideValue: true,
        label: 'MyTasks_RiskLevel',
        sortable: true,
        sortBy: 'RiskLevel',

        styles: {
            minWidth: '120px',
        },
        component: { componentName: 'RiskIndicator', name: 'riskLevel' },
    },
]

export default attributes
