import React, { useState } from 'react'
import EidCardList from './EidCardList'
import EidTable from './EidTable'
import { useViewIndicator } from 'packages/eid-ui'

const useEidGrid = ({
    isLoading,
    data,
    isLoadingMore,
    noDataMessage = 'No data available',
    pagination,
    attributes,
    cardViewConfig,
    tableViewConfig,
    defaultView = 'table',
}) => {
    const [currentView, setCurrentView] = useState(defaultView)

    const { viewIndicator: viewSwitcher } = useViewIndicator({
        view: currentView,
        onCardViewClick: () => setCurrentView('card'),
        onListViewClick: () => setCurrentView('table'),
    })

    const viewToRender =
        currentView === 'card' ? (
            <EidCardList
                isLoading={isLoading}
                data={data}
                isLoadingMore={isLoadingMore}
                noDataMessage={noDataMessage}
                pagination={pagination}
                attributes={attributes}
                {...cardViewConfig}
            />
        ) : (
            <EidTable
                isLoading={isLoading}
                data={data}
                isLoadingMore={isLoadingMore}
                noDataMessage={noDataMessage}
                pagination={pagination}
                attributes={attributes}
                {...tableViewConfig}
            />
        )

    return { viewSwitcher, currentView, setCurrentView, viewToRender }
}

export { useEidGrid, EidCardList, EidTable }
