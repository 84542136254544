import React, { FC, Fragment, useState } from 'react'
import { Box, createStyles, makeStyles, Popover } from '@material-ui/core'
import { StatusIndicator } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import PersonDetails from 'components/Person/PersonDetails'
import { getFullImageUrl } from 'utils'
import { useMitigatingControls } from 'hooks'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(
    createStyles({
        statusIndicator: {
            display: 'flex',
            flex: 1,
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        root: {
            margin: '24px 24px 24px 24px',
            width: '320px',
        },
        heading: {
            fontWeight: 'bold',
        },
        attributes: {
            marginTop: '16px',
        },
        attribute: {
            marginTop: '16px',
        },
        label: {
            color: '#9b9b9b',
            fontSize: '12px',
            textTransform: 'uppercase',
            lineHeight: 1.33,
        },
        value: {
            color: '#000000',
            fontSize: '14px',
            lineHeight: 1.43,
        },
        list: {
            marginBlockStart: '8px',
            marginBlockEnd: '8px',
            paddingInlineStart: '32px',
        },
    }),
)

export type RiskStatusProps = {
    risk: any
}

const mitigationStatuses = ['Mitigated', 'PreMitigated', 'Rejected']

export const RiskViolationStatus: FC<RiskStatusProps> = (props) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const [anchorEl, setAnchorEl] = useState(null)

    const open = Boolean(anchorEl)

    const { data, isLoading } = useMitigatingControls(
        props.risk.globalRiskId,
        open &&
            Boolean(props.risk.riskViolationId) &&
            (props.risk.mitigationStatus === 'PreMitigated' ||
                props.risk.mitigationStatus === 'Mitigated'),
        props.risk.riskViolationId,
    )

    if (
        !props.risk.mitigationStatus ||
        !mitigationStatuses.includes(props.risk.mitigationStatus as string)
    )
        return <Fragment />

    const renderStatus = () => {
        if (props.risk.mitigationStatus === 'Mitigated')
            return (
                <Box className={classes.statusIndicator}>
                    <StatusIndicator
                        size="large"
                        variant={'Approved'}
                        label={t('MyTasks_Approved')}
                    />
                </Box>
            )
        else if (props.risk.mitigationStatus === 'PreMitigated') {
            return (
                <Box className={classes.statusIndicator}>
                    <StatusIndicator
                        size="large"
                        variant={'Approved'}
                        label={t('MyTasks_PreApproved')}
                    />
                </Box>
            )
        } else if (props.risk.mitigationStatus === 'Rejected') {
            return (
                <Box className={classes.statusIndicator}>
                    <StatusIndicator
                        size="large"
                        variant={'Rejected'}
                        label={t('MyTasks_Rejected')}
                    />
                </Box>
            )
        }
    }

    const renderMitigationControls = () => {
        return (
            <Box className={classes.attribute}>
                <Box className={classes.label}>
                    {t('MyTasks_MitigationControls')}
                </Box>
                <Box className={classes.value}>
                    {isLoading || !data ? (
                        <Skeleton height={40} />
                    ) : data.length > 0 ? (
                        <ul className={classes.list}>
                            {data.map((d: any) => (
                                <li key={d.id}>{d.friendlyName}</li>
                            ))}
                        </ul>
                    ) : (
                        <Fragment />
                    )}
                </Box>
            </Box>
        )
    }

    return (
        <Fragment>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                disableScrollLock={true}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Box className={classes.root}>
                    <Box className={classes.heading}>
                        {t('MyTasks_Decision')}
                    </Box>
                    <Box className={classes.attributes}>
                        {props.risk.mitigationOwnerFriendlyName && (
                            <Box className={classes.attribute}>
                                <Box className={classes.label}>
                                    {t('MyTasks_MitigationOwner')}
                                </Box>
                                <Box className={classes.value}>
                                    <PersonDetails
                                        size="large"
                                        name={
                                            props.risk
                                                .mitigationOwnerFriendlyName
                                        }
                                        imgSrc={getFullImageUrl(
                                            props.risk
                                                .mitigationOwnerThumbnailUrl,
                                        )}
                                    />
                                </Box>
                            </Box>
                        )}
                        {Boolean(props.risk.riskViolationId) &&
                            (props.risk.mitigationStatus === 'PreMitigated' ||
                                props.risk.mitigationStatus === 'Mitigated') &&
                            renderMitigationControls()}
                        {props.risk.mitigationValidFromUtc && (
                            <Box className={classes.attribute}>
                                <Box className={classes.label}>
                                    {t('MyTasks_StartDate')}
                                </Box>
                                <Box className={classes.value}>
                                    {moment
                                        .utc(props.risk.mitigationValidFromUtc)
                                        .local()
                                        .format('LLL')}
                                </Box>
                            </Box>
                        )}
                        {props.risk.mitigationValidUntilUtc && (
                            <Box className={classes.attribute}>
                                <Box className={classes.label}>
                                    {t('MyTasks_EndDate')}
                                </Box>
                                <Box className={classes.value}>
                                    {moment
                                        .utc(props.risk.mitigationValidUntilUtc)
                                        .local()
                                        .format('LLL')}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Popover>
            <Box className={classes.statusIndicator}>
                <Box onClick={(event: any) => setAnchorEl(event.currentTarget)}>
                    {renderStatus()}
                </Box>
            </Box>
        </Fragment>
    )

    return <Fragment />
}
