import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, styled } from '@material-ui/core'
import { HorizontalTabs } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { DropdownMenu } from 'components/DropdownMenu'
import { useRouteOptions } from 'hooks'
import { SubPathLevel } from 'appConfigurations'
import appConfig from 'config'

const SubHeaderTabs = styled(HorizontalTabs)(({ theme }) => ({
    '& .MuiTabs-flexContainer': {
        height: theme.mixins.navBar.minHeight,
    },
    '& .MuiTabs-indicator': {
        backgroundColor: '#307fc1',
        height: '4px',
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
        fontWeight: 'bold',
    },
}))

const SubHeader = ({
    showNavTabs = true,
    subHeaderButton,
    viewSwitcher,
    totalCount,
}) => {
    const {
        routeOptions,
        hasAccessToRequestView,
        hasAccessToItemView,
    } = useRouteOptions()

    const { t } = useTranslation()

    const history = useHistory()
    const {
        location: { pathname },
    } = history

    const areaName = pathname.split('/')[1 + SubPathLevel]

    let selectedRoute
    let selectedParentRoute

    for (let i = 0; i < routeOptions.length; i++) {
        const foundRoute = routeOptions[i].children.find((c) => {
            return c.route.toLowerCase() === pathname.toLowerCase()
        })

        if (foundRoute) {
            selectedRoute = foundRoute
            selectedParentRoute = routeOptions[i]
            break
        }
    }
    const selectedTab = pathname.split('/')[2 + SubPathLevel]

    const getTabLabelWithCount = (tabLabel) => {
        if (totalCount)
            return (
                <>
                    {tabLabel} ({totalCount})
                </>
            )
        return tabLabel
    }

    return (
        <Box
            padding="0 20px 0 20px"
            borderBottom="1px solid rgba(0, 0, 0, 0.1)"
            height="60px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            width="100%"
        >
            <Box display="flex" position="absolute" left="0px" bottom="0px">
                <DropdownMenu
                    routeOptions={routeOptions}
                    selectedRoute={selectedRoute}
                    selectedParentRoute={selectedParentRoute}
                />
            </Box>
            <Box display="flex" position="absolute" right="10px">
                {viewSwitcher}
            </Box>
            {subHeaderButton}

            {showNavTabs && (
                <SubHeaderTabs
                    variant="standard"
                    value={selectedTab}
                    onChange={(_, value) => {
                        history.push(
                            `${appConfig.APP_SUBPATH}/${areaName}/${value}`,
                        )
                    }}
                >
                    {hasAccessToRequestView(areaName) && (
                        <HorizontalTabs.Tab
                            key={1}
                            value="businessRequests"
                            label={
                                selectedTab === 'businessRequests'
                                    ? getTabLabelWithCount(
                                          t('MyTasks_RequestView'),
                                      )
                                    : t('MyTasks_RequestView')
                            }
                        />
                    )}
                    {hasAccessToItemView(areaName) && (
                        <HorizontalTabs.Tab
                            key={2}
                            value="businessRequestItems"
                            label={
                                selectedTab === 'businessRequestItems'
                                    ? getTabLabelWithCount(
                                          t('MyTasks_ItemView'),
                                      )
                                    : t('MyTasks_ItemView')
                            }
                        />
                    )}
                </SubHeaderTabs>
            )}
        </Box>
    )
}

export default SubHeader
