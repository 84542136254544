import { Box, styled } from '@material-ui/core'
import { DecisionConfirmationButton } from 'components'
import { useRequestApprovalDecisions, useSubmitRequestDecision } from 'hooks'
import React, { useState } from 'react'
import { Skeleton } from '@material-ui/lab'
import { APPROVAL_COMMENT_MAX_LENGTH, smallScreenWidth } from 'utils'

const RequestApprovalDecisionPromptContainer = styled(Box)({
    flex: '1',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '15px 30px',
    [`@media (max-width:${smallScreenWidth})`]: {
        flexWrap: 'wrap',
        '& button': {
            marginBottom: '12px',
            minWidth: '260px',
        },
    },
})

export const RequestApprovalDecisionPrompt = ({
    businessRequest,
}: any) => {
    const {
        data: approvalDecisions,
        isLoading: isLoadingApprovalDecisions,
    } = useRequestApprovalDecisions(
        businessRequest.id,
        businessRequest.isApprovable,
    )

    const [
        submitRequestDecision,
        { isLoading: isSubmittingRequestDecision },
    ] = useSubmitRequestDecision(businessRequest.id)

    const [decisionComment, setDecisionComment] = useState('')

    return (
        <RequestApprovalDecisionPromptContainer>
            {isLoadingApprovalDecisions ? (
                <Box marginX="30px">
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        width={192}
                        height={40}
                    />
                </Box>
            ) : (
                approvalDecisions.map((ad: any) => {
                    return (
                        <Box
                            component="span"
                            key={ad.decisionId}
                            marginX="15px"
                        >
                            <DecisionConfirmationButton
                                approvalDecision={ad}
                                decisionComment={decisionComment}
                                maxCharacters={APPROVAL_COMMENT_MAX_LENGTH}
                                setDecisionComment={setDecisionComment}
                                isLoading={isSubmittingRequestDecision}
                                onSuccess={() => {
                                    const reqData: any = {
                                        id: businessRequest.id,
                                        stepId:
                                            businessRequest.currentApprovalStepId,
                                        decisionId: ad.decisionId,
                                    }
                                    if (decisionComment) {
                                        reqData.comment = decisionComment
                                    }
                                    return submitRequestDecision(reqData).then(
                                        () => {
                                            setDecisionComment('')
                                        },
                                    )
                                }}
                            />
                        </Box>
                    )
                })
            )}
        </RequestApprovalDecisionPromptContainer>
    )
}
