import { Box, createStyles, makeStyles, Popover } from '@material-ui/core'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DecisionDetails } from './DecisionDetails'
import classNames from 'classnames'

const useStyles = makeStyles(
    createStyles({
        root: {
            '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline',
            },
        },
    }),
)

export const Status = ({ showDetails, status, autoApprove }: any) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const [anchorEl, setAnchorEl] = useState(null)

    const open = Boolean(anchorEl)

    return (
        <Fragment>
            {showDetails && (
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    disableScrollLock={true}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Box>{open && <DecisionDetails />}</Box>
                </Popover>
            )}
            <Box
                className={classNames({
                    [classes.root]: showDetails,
                })}
                onClick={
                    showDetails
                        ? (event: any) => setAnchorEl(event.currentTarget)
                        : undefined
                }
            >
                <Box>{t(`MyTasks_${status}`)}</Box>
                {autoApprove && <Box>{t('MyTasks_AutoApproved')}</Box>}
            </Box>
        </Fragment>
    )
}
