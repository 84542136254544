import { useGetPagesAccess, useGetControlsAccess } from 'packages/core'

const useSubcomponents = () => {
    const pages = useGetPagesAccess().map((p) => p.name)
    const controls = useGetControlsAccess().map((c) => c.name)

    const hasAccessToRequestViewMyRequestsPage = pages.includes(
        'MyTasks-RequestViewMyRequests-Page',
    )
    const hasAccessToItemViewMyRequestsPage = pages.includes(
        'MyTasks-ItemViewMyRequests-Page',
    )

    const hasAccessToRequestViewMyTasksPage = pages.includes(
        'MyTasks-RequestViewMyTasks-Page',
    )
    const hasAccessToItemViewMyTasksPage = pages.includes(
        'MyTasks-ItemViewMyTasks-Page',
    )

    const hasAccessToRequestViewAllRequestsPage = pages.includes(
        'MyTasks-RequestViewAllRequests-Page',
    )
    const hasAccessToItemViewAllRequestsPage = pages.includes(
        'MyTasks-ItemViewAllRequests-Page',
    )

    const trimFiltersBasedOnAccess = (filtersList) =>
        filtersList.filter((f) => controls.includes(f.requireAccess))

    const hasAccessToRequestProcessStepsControl = controls.includes(
        'MyTasks-RequestDetailsProcessSteps-Control',
    )
    const hasAccessToRequestCurrentStepApproversControl = controls.includes(
        'MyTasks-RequestDetailsCurrentStepApprovers-Control',
    )
    const hasAccessToRequestClaimControl = controls.includes(
        'MyTasks-RequestDetailsClaim-Control',
    )
    const hasAccessToRequestDelegateControl = controls.includes(
        'MyTasks-RequestDetailsDelegate-Control',
    )

    const hasAccessToRequestItemProcessStepsControl = controls.includes(
        'MyTasks-RequestItemDetailsProcessSteps-Control',
    )
    const hasAccessToRequestItemCurrentStepApproversControl = controls.includes(
        'MyTasks-RequestItemDetailsCurrentStepApprovers-Control',
    )
    const hasAccessToRequestItemClaimControl = controls.includes(
        'MyTasks-RequestItemDetailsClaim-Control',
    )
    const hasAccessToRequestItemDelegateControl = controls.includes(
        'MyTasks-RequestItemDetailsDelegate-Control',
    )

    const hasAccessToAdminAssignTaskDelegateControl = controls.includes(
        'MyTasks-AdminAssignTaskDelegate-Control',
    )
    const hasAccessToAssignTaskDelegateControl = controls.includes(
        'MyTasks-AssignTaskDelegate-Control',
    )
    const hasAccessToAdminAddAdditionalApproversDelegateControl = controls.includes(
        'MyTasks-AdminAddAdditionalApproversDelegate-Control',
    )
    const hasAccessToAddAdditionalApproversDelegateControl = controls.includes(
        'MyTasks-AddAdditionalApproversDelegate-Control',
    )
    const hasAccessToPermanentlyDelegateStepControl = controls.includes(
        'MyTasks-PermanentlyDelegateStepDelegate-Control',
    )
    const hasAccessToOutOfOfficeDelegateControl = controls.includes(
        'MyTasks-OutOfOfficeDelegate-Control',
    )
    const hasAccessToDelegationsHistoryControl = controls.includes(
        'MyTasks-ManualDelegations-Control',
    )
    const hasAccessDeleteCommentControl = controls.includes(
        'MyTasks-DeleteComment-Control',
    )

    const hasAccessEditCommentControl = controls.includes(
        'MyTasks-EditComment-Control',
    )

    const hasAccessToAdminCancelRequestControl = controls.includes(
        'MyTasks-AdminCancelRequest-Control',
    )
    const hasAccessToAdminCancelRequestItemControl = controls.includes(
        'MyTasks-AdminCancelRequestItem-Control',
    )
    const hasAccessToCancelRequestControl = controls.includes(
        'MyTasks-CancelRequest-Control',
    )
    const hasAccessToCancelRequestItemControl = controls.includes(
        'MyTasks-CancelRequestItem-Control',
    )
    const hasAccessToRefreshViolationsControl = controls.includes(
        'MyTasks-RefreshViolations-Control',
    )
    const hasAccessToRiskMitigationEndDateControl = controls.includes(
        'MyTasks-RiskMitigationEndDate-Control',
    )
    const hasAccessToFulfillmentScheduleDateControl = controls.includes(
        'MyTasks-FulfillmentScheduleDate-Control',
    )
    const hasAccessToEditFulfillmentScheduleDateControl = controls.includes(
        'MyTasks-EditFulfillmentScheduleDate-Control',
    )

    const canSeeRiskViolationsByItems = controls.includes(
        'MyTasks-RiskViolationsByItems-Control',
    )

    const canSeeRiskViolationsByRisks = controls.includes(
        'MyTasks-RiskViolationsByRisks-Control',
    )
    const canSeeEIDProfileLink=controls.includes('MyTasks-PersonSubjectType-EIDLink-Control',
    )

    const canSeeToDoListTimeConstraint = controls.includes(
        'MyTasks-ToDoItem-TimeConstraint-Control',
    )

    return {
        hasAccessToRequestViewMyRequestsPage,
        hasAccessToItemViewMyRequestsPage,

        hasAccessToRequestViewMyTasksPage,
        hasAccessToItemViewMyTasksPage,

        hasAccessToRequestViewAllRequestsPage,
        hasAccessToItemViewAllRequestsPage,

        trimFiltersBasedOnAccess,

        hasAccessToRequestProcessStepsControl,
        hasAccessToRequestCurrentStepApproversControl,
        hasAccessToRequestClaimControl,
        hasAccessToRequestDelegateControl,

        hasAccessToRequestItemProcessStepsControl,
        hasAccessToRequestItemCurrentStepApproversControl,
        hasAccessToRequestItemClaimControl,
        hasAccessToRequestItemDelegateControl,

        hasAccessToAdminAssignTaskDelegateControl,
        hasAccessToAssignTaskDelegateControl,
        hasAccessToAdminAddAdditionalApproversDelegateControl,
        hasAccessToAddAdditionalApproversDelegateControl,
        hasAccessToPermanentlyDelegateStepControl,
        hasAccessToOutOfOfficeDelegateControl,
        hasAccessToDelegationsHistoryControl,

        hasAccessDeleteCommentControl,
        hasAccessEditCommentControl,

        hasAccessToAdminCancelRequestControl,
        hasAccessToAdminCancelRequestItemControl,
        hasAccessToCancelRequestControl,
        hasAccessToCancelRequestItemControl,

        hasAccessToRefreshViolationsControl,

        hasAccessToRiskMitigationEndDateControl,

        hasAccessToFulfillmentScheduleDateControl,
        hasAccessToEditFulfillmentScheduleDateControl,

        canSeeRiskViolationsByItems,
        canSeeRiskViolationsByRisks,
        canSeeEIDProfileLink,
        canSeeToDoListTimeConstraint
    }
}

export default useSubcomponents
