import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Popover, styled } from '@material-ui/core'
import { Button, DecisionComment } from 'components'
import { Icon } from 'packages/eid-icons'
import { Spinner } from 'packages/eid-ui'
import { mobileScreenWidth } from 'utils'
import { isNilOrEmpty, isWhitespace } from 'packages/core'
import config from 'config'

const PopoverContentContainer = styled(Box)({
    [`@media (min-width:${mobileScreenWidth})`]: {
        minWidth: '400px',
    },
})

const DecisionConfirmationButton = ({
    approvalDecision,
    decisionComment,
    maxCharacters,
    setDecisionComment,
    onSuccess,
    isLoading,
}) => {
    const { t } = useTranslation()

    const ButtonComponent =
        approvalDecision.decisionAction === 'Continue'
            ? Button.Approve
            : Button.Revoke

    const [anchorEl, setAnchorEl] = React.useState(null)

    const open = Boolean(anchorEl)

    const showConfirmation = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setDecisionComment('')
    }

    const notSubmittable =
        decisionComment.length > maxCharacters ||
        isWhitespace(decisionComment) ||
        isLoading ||
        (config.MANDATORY_REJECTION_COMMENTS &&
            approvalDecision.decisionAction !== 'Continue' &&
            isNilOrEmpty(decisionComment))

    return (
        <>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                disableScrollLock={true}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <PopoverContentContainer>
                    <Box marginTop={4} mx={3} fontWeight="bold">
                        {t('MyTasks_ApprovalConfirmation', {
                            text: approvalDecision.decisionFriendlyName,
                        })}
                        {approvalDecision.decisionAction !== 'Continue' && (
                            <span style={{ color: 'red' }}> * </span>
                        )}
                    </Box>
                    <Box my={3} mx={3}>
                        <DecisionComment
                            initialValue={decisionComment}
                            maxCharacters={maxCharacters}
                            onChange={(comment) => setDecisionComment(comment)}
                            onBlur={(e) => {
                                let decisionComment = e.target.value
                                setDecisionComment(decisionComment)
                            }}
                            isRejected={
                                approvalDecision.decisionAction !== 'Continue'
                            }
                        />
                    </Box>
                    <Box my={2} mx={3} display="flex" justifyContent="flex-end">
                        <Box mx={2}>
                            <Button
                                size="medium"
                                onClick={handleClose}
                                style={{
                                    backgroundColor: '#307fc1',
                                    '&:hover': {
                                        backgroundColor: '#307fc1',
                                        opacity: 0.95,
                                    },
                                }}
                            >
                                <Icon
                                    name="Close"
                                    color="#ffffff"
                                    width="14px"
                                    height="14px"
                                />
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                size="medium"
                                disabled={notSubmittable}
                                onClick={() =>
                                    onSuccess().then(() => handleClose())
                                }
                                disabledStyles={{
                                    backgroundColor: '#d9d9d9',
                                }}
                                style={{
                                    backgroundColor: '#307fc1',
                                    '&:hover': {
                                        backgroundColor: '#307fc1',
                                        opacity: 0.95,
                                    },
                                }}
                            >
                                {isLoading ? (
                                    <Spinner size={0.6} color="#ffffff" />
                                ) : (
                                    <Icon name="Approve" color="#ffffff" />
                                )}
                            </Button>
                        </Box>
                    </Box>
                </PopoverContentContainer>
            </Popover>
            <ButtonComponent onClick={showConfirmation}>
                {approvalDecision.decisionFriendlyName}
            </ButtonComponent>
        </>
    )
}

export default DecisionConfirmationButton
