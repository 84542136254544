import { FC, Fragment, useState } from 'react'
import { createStyles, makeStyles, Popover } from '@material-ui/core'
import { mobileScreenWidth } from 'utils'
import ApproveButton from 'components/Button/ApproveButton'
import { MitigateRisk, MitigationPopoverProps } from './MitigateRisk'
import { Tooltip } from 'packages/eid-ui'

const usePopoverStyles = makeStyles(
    createStyles({
        paper: {
            [`@media (max-width:${mobileScreenWidth})`]: {
                width: '100%',
            },
        },
    }),
)

export const ApproveRisk: FC<MitigationPopoverProps> = (props) => {
    const popoverClasses = usePopoverStyles()

    const [anchorEl, setAnchorEl] = useState(null)

    const open = Boolean(anchorEl)

    const showConfirmation = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <Fragment>
            <Tooltip title={props.decisionFriendlyName}>
                <div>
                    <ApproveButton size={'small'} onClick={showConfirmation} />
                </div>
            </Tooltip>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                disableScrollLock={true}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                classes={popoverClasses}
            >
                {open && (
                    <MitigateRisk
                        currentApprovalStepId={props.currentApprovalStepId}
                        decisionFriendlyName={props.decisionFriendlyName}
                        onSubmit={(value) =>
                            props.onSubmit(value).then(() => handleClose())
                        }
                        handleClose={handleClose}
                        isLoading={props.isLoading}
                    />
                )}
            </Popover>
        </Fragment>
    )
}
