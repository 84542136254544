import { useAxios } from 'packages/core'
import { useMutation } from 'react-query'
import { refreshRequestOrRequestItem } from './utils'

export const useClaim = (page, requestId, requestItemId) => {
    const callApi = useAxios()

    return useMutation(
        (stepId) =>
            callApi({
                method: 'POST',
                url: `/api/delegations/claim/${stepId}`,
            }),
        {
            onSettled: () =>
                refreshRequestOrRequestItem(page, requestId, requestItemId),
        },
    )
}

export const useUnclaim = (page, requestId, requestItemId) => {
    const callApi = useAxios()

    return useMutation(
        (stepId) =>
            callApi({
                method: 'POST',
                url: `/api/delegations/unclaim/${stepId}`,
            }),
        {
            onSettled: () =>
                refreshRequestOrRequestItem(page, requestId, requestItemId),
        },
    )
}

export const useAssign = (page, requestId, requestItemId) => {
    const callApi = useAxios()

    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: `/api/delegations/assign/${data.stepId}/${data.personId}`,
            }),
        {
            onSettled: () =>
                refreshRequestOrRequestItem(page, requestId, requestItemId),
        },
    )
}

export const useAddAdditionalApprovers = (
    page,
    requestId,
    requestItemId,
    stepId,
) => {
    const callApi = useAxios()

    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: `/api/delegations/addApprovers/${stepId}`,
                data,
            }),
        {
            onSettled: () =>
                refreshRequestOrRequestItem(page, requestId, requestItemId),
        },
    )
}

export const useCreatePermanentStepDelegation = (
    page,
    requestId,
    requestItemId,
) => {
    const callApi = useAxios()

    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: '/api/delegations/createDelegation',
                data,
            }),
        {
            onSettled: () =>
                refreshRequestOrRequestItem(page, requestId, requestItemId),
        },
    )
}
