import React, { useState } from 'react'
import { Box, InputBase, withStyles, makeStyles } from '@material-ui/core'
import { Comment, Button } from 'components'
import { useTranslation } from 'react-i18next'
import { useAuthState, isEmptyOrWhitespace, isWhitespace } from 'packages/core'
import { useReplyComment } from 'hooks'
import { smallScreenWidth, COMMENT_MAX_LENGTH } from 'utils'
import useSubcomponents from 'useSubcomponents'

const StyledInput = withStyles(() => ({
    root: {
        width: '100% !important',
        borderRadius: '5px',
        border: 'solid 1px #d8d8dd',
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff',
        '&:hover': {
            border: 'solid 1px #307fc1',
        },
        '&:focus-within': {
            boxShadow: '0 4px 8px 0 rgba(48, 127, 193, 0.33)',
            border: 'solid 1px #307fc1 !important',
        },
    },
    input: {
        position: 'relative',
        fontSize: 15,
        lineHeight: 1.33,
        color: '#8b909a',
        padding: '3px 15px',
    },
    error: {
        border: 'solid 1px #f44336 !important',
        '&:focus-within': {
            border: 'solid 1px #f44336 !important',
        },
    },
}))(InputBase)

const useStyles = makeStyles(() => ({
    inputContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px',
        paddingRight: '0px',
        '& .SendButtonContainer': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
        },
        '& .COMMENT_MAX_LENGTH': {
            position: 'absolute',
            bottom: '-20px',
            right: 2,
            fontSize: '10px',
        },
        [`@media (max-width:${smallScreenWidth})`]: {
            flexDirection: 'column',
            '& .SendButtonContainer': {
                paddingTop: '16px',
                maxWidth: '100px',
            },
        },
    },
}))

const AddNewComment = ({ handleAddComment, loading }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [inputText, setInputText] = useState('')

    const handleChange = (event) => {
        const keyword = event.target.value
        setInputText(keyword)
    }

    const notSubmitable =
        inputText.length > COMMENT_MAX_LENGTH ||
        isEmptyOrWhitespace(inputText) ||
        loading

    const handleKeyDown = (event) => {
        if (event.which === 13 && !notSubmitable) {
            handleAddComment(inputText)
            setInputText('')
            event.preventDefault()
        }
    }

    return (
        <Box classes={{ root: classes.inputContainer }}>
            <Box width="100%" marginRight="15px" position="relative">
                <Box className="COMMENT_MAX_LENGTH">
                    {inputText.length}/{COMMENT_MAX_LENGTH}
                </Box>
                <StyledInput
                    fullWidth
                    multiline
                    value={inputText}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder={t('MyTasks_AddAComment')}
                    error={
                        inputText.length > COMMENT_MAX_LENGTH ||
                        isWhitespace(inputText)
                    }
                />
            </Box>
            <Box className="SendButtonContainer">
                <Button.Send
                    loading={loading}
                    disabled={notSubmitable}
                    onClick={() => {
                        handleAddComment(inputText)
                        setInputText('')
                    }}
                >
                    {t('MyTasks_Send')}
                </Button.Send>
            </Box>
        </Box>
    )
}

const CommentsList = (props) => {
    const {
        page,
        loading,
        fallbackMessage = 'MyTasks_NoCommentsFound',
        handleAddComment,
        isSubmitting,
        list,
        requestId,
        itemId,
        commentsContainerProps,
        handleEditComment,
        handleDeleteComment,
    } = props
    
    const [replyComment] = useReplyComment(page, requestId, itemId)

    const [{ currentUserId }] = useAuthState()
    const { t } = useTranslation()

    const {
        hasAccessDeleteCommentControl,
        hasAccessEditCommentControl,
    } = useSubcomponents()

    return (
        <Box width="100%" padding="15px">
            {loading && (
                <>
                    <Box display="flex" justifyContent="flex-end">
                        <Comment loading={true} myComment />
                    </Box>
                    <Box display="flex">
                        <Comment loading={true} />
                    </Box>
                </>
            )}

            <Box width="100%" {...commentsContainerProps}>
                {list && (
                    <>
                        {list.length === 0 ? (
                            <>
                                <Box
                                    padding="15px"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {t(fallbackMessage)}
                                </Box>
                            </>
                        ) : (
                            list
                                .filter((f) => !Boolean(f.parentId))
                                .map((c, index) => {
                                    const commentProps = {
                                        shouldAllowReplies: !Boolean(
                                            c.parentId,
                                        ),
                                        replies: list.filter(
                                            (l) => l.parentId === c.id,
                                        ),
                                        ...c,
                                    }
                                    return c.commentorPersonId ===
                                        currentUserId ? (
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                            key={`${index}${c.id}`}
                                        >
                                            <Comment
                                                {...commentProps}
                                                myComment
                                                handleReply={(d) => {
                                                    replyComment({
                                                        parentId: c.id,
                                                        value: d,
                                                    })
                                                }}
                                                handleDeleteComment={
                                                    hasAccessDeleteCommentControl &&
                                                    handleDeleteComment
                                                }
                                                handleEditComment={
                                                    hasAccessEditCommentControl &&
                                                    handleEditComment
                                                }
                                            />
                                        </Box>
                                    ) : (
                                        <Box
                                            display="flex"
                                            key={`${index}${c.id}`}
                                        >
                                            <Comment
                                                {...commentProps}
                                                handleReply={(d) => {
                                                    replyComment({
                                                        parentId: c.id,
                                                        value: d,
                                                    })
                                                }}
                                                handleDeleteComment={
                                                    hasAccessDeleteCommentControl &&
                                                    handleDeleteComment
                                                }
                                                handleEditComment={
                                                    hasAccessEditCommentControl &&
                                                    handleEditComment
                                                }
                                            />
                                        </Box>
                                    )
                                })
                        )}
                    </>
                )}
            </Box>

            <AddNewComment
                handleAddComment={handleAddComment}
                loading={isSubmitting}
            />
        </Box>
    )
}

export default CommentsList
