import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAllApplications } from 'hooks'
import { GlobalMenu, IconButton } from 'packages/eid-ui'
import { Icon, CloseIcon } from 'packages/eid-icons'
import {
    List,
    Divider as MuiDivider,
    styled,
    Box,
    Typography,
    Link,
} from '@material-ui/core'
import ListItem from './ListItem'
import { useRouteOptions } from 'hooks'
import { useHistory } from 'react-router-dom'
import { SubPathLevel } from 'appConfigurations/index'

const StyledList = styled(List)({
    width: '100%',
    padding: '0px 8px',
})

const Divider = styled(MuiDivider)({
    margin: '10px 0px',
})

const styles = {
    closeIcon: {
        margin: '14px 0px 10px 15px',
        width: '12px',
        padding: 0,
    },
    appTitle: {
        fontSize: '16px',
        color: '#307fc1',
        textTransform: 'uppercase',
        padding: '0 8px',
    },
    appIcon: {},
    listTitle: {
        fontSize: 13,
        lineHeight: 1.33,
        color: '#000000',
        margin: '8px ',
        padding: '0px 6px',
    },
    logOutIcon: {},
    logOutlabel: {
        fontSize: 14,
        padding: '0 10px',
        color: '#000000',
    },

    listContainer: {
        width: '100%',
        height: 'calc(100% - 30px)',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
}

const appNameMaps = {
    EIDEmpowerIDWeb: 'EmpowerID',
    EIDAzureAnalyticsMS: 'AzureAnalytics',
    EIDITShopMS: 'ITShop',
    EIDMyIdentity: 'MyIdentity',
    EIDMyTasks: 'MyTasks',
    EIDResourceAdminMS: 'ResourceAdmin',
    EIDRiskAnalyticsMS: 'RiskAnalytics',
}

const Title = ({ title, icon }) => (
    <Box display="flex" alignItems="center" padding="30px 10px">
        <Icon name={icon} color="#307fc1" />
        <Typography style={styles.appTitle}>{title}</Typography>
    </Box>
)

const Section = ({ section, sectionIndex, handleClose }) => {
    const { t } = useTranslation()

    const history = useHistory()
    const {
        location: { pathname },
    } = history

    const {
        routeOptions,
        hasAccessToRequestView,
        hasAccessToItemView,
    } = useRouteOptions()

    let selectedRoute
    let selectedParentRoute
    const selectedTab = pathname.split('/')[2 + SubPathLevel]

    for (let i = 0; i < routeOptions.length; i++) {
        const foundRoute = routeOptions[i].children.find((c) => {
            return c.route.toLowerCase() === pathname.toLowerCase()
        })

        if (foundRoute) {
            selectedRoute = foundRoute
            selectedParentRoute = routeOptions[i]
            break
        }
    }

    const checkAccess = (areaName, route) => {
        const name = route.split('/')[2].toLowerCase()

        if (name === 'businessrequestitems') {
            return hasAccessToItemView(areaName)
        } else if (name === 'businessrequests') {
            return hasAccessToRequestView(areaName)
        }
    }

    return (
        <>
            <Typography style={styles.listTitle}>{t(section.title)}</Typography>

            <StyledList>
                {section.children.map(
                    (c) =>
                        checkAccess(section.name, c.route) && (
                            <ListItem
                                key={c.title}
                                to={c.route}
                                onClick={handleClose}
                                active={selectedRoute.route === c.route}
                            >
                                {t(c.title)}
                            </ListItem>
                        ),
                )}
                {sectionIndex + 1 < routeOptions.length && (
                    <Divider variant="middle" />
                )}
            </StyledList>
        </>
    )
}

const SignOut = ({ label }) => (
    <Link href={'/signout'} underline="none">
        <Box paddingLeft="16px" display="flex" alignItems="center">
            <Icon name={'LogOut'} color="#307fc1" style={styles.logOutIcon} />
            <Typography style={styles.logOutlabel}>{label}</Typography>
        </Box>
    </Link>
)

const MobileMenu = () => {
    const { t } = useTranslation()

    const { data, isLoading } = useAllApplications()

    const { routeOptions } = useRouteOptions()

    const eidApp = data?.find((a) => a.applicationName === 'EIDEmpowerIDWeb')

    return (
        <GlobalMenu.Mobile
            loading={isLoading}
            eid={
                eidApp
                    ? {
                          name: appNameMaps[eidApp.applicationName],
                          displayName: eidApp.applicationDisplayName,
                          url: eidApp.applicationAbsoluteUrl,
                      }
                    : undefined
            }
            apps={data
                ?.filter((f) => f.applicationName !== 'EIDEmpowerIDWeb')
                .map((a) => ({
                    name: appNameMaps[a.applicationName],
                    displayName: a.applicationDisplayName,
                    url: a.applicationAbsoluteUrl,
                }))}
            current="MyTasks"
        >
            {(handleClose) => (
                <>
                    <Box position="reletive">
                        <IconButton
                            onClick={handleClose}
                            style={styles.closeIcon}
                        >
                            <CloseIcon color="#919193" />
                        </IconButton>
                        <Box style={styles.listContainer}>
                            <Box>
                                <Title
                                    title={t('MyTasks_MyTasks')}
                                    icon={'MyTasks'}
                                />

                                {routeOptions.map((section, sectionIndex) => (
                                    <span key={section.name}>
                                        <Section
                                            section={section}
                                            sectionIndex={sectionIndex}
                                            handleClose={handleClose}
                                        />
                                    </span>
                                ))}
                            </Box>
                            <Box width="100%" paddingY="24px">
                                <SignOut label={t('MyTasks_LogOut')} />
                            </Box>
                        </Box>
                    </Box>
                </>
            )}
        </GlobalMenu.Mobile>
    )
}

export default MobileMenu
