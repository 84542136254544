import React from 'react'
import { Box } from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import IconContainer from './IconContainer'
import IconLabel from './IconLabel'

const NotStartedIcon = ({ label }) => {
    return (
        <Box display="flex" alignItems="center">
            <IconContainer bgcolor="#d8d8dd">
                <Icon name="Pause" color="#ffffff" />
            </IconContainer>
            <IconLabel style={{ color: '#d8d8dd' }}>{label}</IconLabel>
        </Box>
    )
}

export default NotStartedIcon
