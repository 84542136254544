import { Box, createStyles, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useRiskFunctions } from 'hooks'
import { Skeleton } from '@material-ui/lab'
import { mobileScreenWidth } from 'utils'
import { RiskIndicator } from 'packages/eid-ui'

const useStyles = makeStyles(
    createStyles({
        root: {
            marginLeft: '24px',
            marginRight: '24px',
            position: 'relative',
            padding: '16px',
            [`@media (max-width:${mobileScreenWidth})`]: {
                flexDirection: 'column',
            },
        },
        row: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '8px',
        },
        functionsListsContainer: {
            display: 'flex',
        },
        functionsList: {
            marginTop: '4px',
            display: 'flex',
            flexDirection: 'column',
            width: '320px',
            [`@media (max-width:${mobileScreenWidth})`]: {
                marginLeft: 0,
            },
        },
        functionsListItem: {
            display: 'flex',
            alignItems: 'flex-start',
            padding: '8px 0px',
            '& > div:first-child ': {
                marginRight: '28px',
            },
        },
        attribute: {
            display: 'flex',
            flexDirection: 'column',
            padding: '4px 0px',
        },
        label: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: '#9b9b9b',
            padding: '4px 0px',
            textTransform: 'uppercase',
            lineHeight: 1.33,
            wordBreak: 'break-word',
            wordWrap: 'break-word',
            overflow: 'auto',
            minWidth: '80px',
        },
        value: {
            fontSize: '14px',
            color: '#000000',
            padding: '4px 0px',
            lineHeight: 1.33,
            wordBreak: 'break-word',
            wordWrap: 'break-word',
            width: '320px',
        },
        functionsListLabel: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#d0021b',
        },
        riskIndicator: {
            backgroundColor: '#fdf1f3',
            opacity: 1,
            fontWeight: 'bold',
        },
    }),
)

export const FunctionsGranted = ({
    risk,
    fetch,
}: {
    risk: any
    fetch: boolean
}) => {
    const { t } = useTranslation()

    const classes = useStyles()

    let leftSide: any
    let rightSide: any

    if (risk.riskDetailsInversed) {
        leftSide = risk.segregatedRiskDetails
        rightSide = risk.riskDetails
    } else {
        leftSide = risk.riskDetails
        rightSide = risk.segregatedRiskDetails
    }

    const { data: leftSideFunctions, isLoading: isLoadingLeftSideFunctions } =
        useRiskFunctions(
            fetch && leftSide ? leftSide.riskFunctionAssigneeId : '',
            risk.localRiskId,
            true,
            risk.riskDetailsInversed,
        )

    const { data: rightSideFunctions, isLoading: isLoadingRightSideFunctions } =
        useRiskFunctions(
            fetch && rightSide ? rightSide.riskFunctionAssigneeId : '',
            risk.localRiskId,
            false,
            risk.riskDetailsInversed,
        )

    return (
        <Box className={classes.root}>
            <Box>
                <Box className={classes.row}>
                    <Box className={classes.attribute}>
                        <Typography className={classes.label}>
                            {t('MyTasks_RiskType')}
                        </Typography>
                        <Typography className={classes.value}>
                            {t(`MyTasks_${risk.riskType}`)}
                        </Typography>
                    </Box>

                    <Box className={classes.attribute}>
                        <Typography className={classes.label}>
                            {t('MyTasks_LocalRisk')}
                        </Typography>
                        <Typography className={classes.value}>
                            {risk.localRiskFriendlyName}
                        </Typography>
                    </Box>
                </Box>

                <Box className={classes.row}>
                    <Box className={classes.functionsList} marginLeft={0}>
                        <Typography className={classes.functionsListLabel}>
                            {t(
                                rightSide
                                    ? 'MyTasks_FunctionsGrantedLeft'
                                    : 'MyTasks_FunctionsGranted',
                            )}
                        </Typography>

                        {isLoadingLeftSideFunctions && (
                            <Box className={classes.functionsListItem}>
                                <Box width={'100%'}>
                                    <Box my={2}>
                                        <Skeleton
                                            animation="wave"
                                            variant="rect"
                                            height={24}
                                        />
                                    </Box>
                                    <Box my={2}>
                                        <Skeleton
                                            animation="wave"
                                            variant="rect"
                                            height={24}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        {leftSideFunctions &&
                            leftSideFunctions.length === 0 && (
                                <Box my={1}>{t('MyTasks_NoDataFound')}</Box>
                            )}
                        {leftSideFunctions &&
                            leftSideFunctions.length > 0 &&
                            leftSideFunctions.map((f: any) => (
                                <Box
                                    key={f.id}
                                    className={classes.functionsListItem}
                                >
                                    <Box className={classes.label}>
                                        <RiskIndicator
                                            variant={f.riskLevel}
                                            label={t(
                                                `MyTasks_${f.riskLevel}Risk`,
                                            )}
                                            className={
                                                f &&
                                                f.riskLevel !== 'Critical' &&
                                                classes.riskIndicator
                                            }
                                        />
                                    </Box>
                                    <Typography className={classes.value}>
                                        {f.friendlyName}
                                    </Typography>
                                </Box>
                            ))}
                    </Box>

                    {rightSide && (
                        <Box className={classes.functionsList}>
                            <Typography className={classes.functionsListLabel}>
                                {t('MyTasks_FunctionsGrantedRight')}
                            </Typography>

                            {isLoadingRightSideFunctions && (
                                <Box className={classes.functionsListItem}>
                                    <Box width={'100%'}>
                                        <Box my={2}>
                                            <Skeleton
                                                animation="wave"
                                                variant="rect"
                                                height={24}
                                            />
                                        </Box>
                                        <Box my={2}>
                                            <Skeleton
                                                animation="wave"
                                                variant="rect"
                                                height={24}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                            {rightSideFunctions &&
                                rightSideFunctions.length === 0 && (
                                    <Box my={1}>{t('MyTasks_NoDataFound')}</Box>
                                )}
                            {rightSideFunctions &&
                                rightSideFunctions.length > 0 &&
                                rightSideFunctions.map((f: any) => (
                                    <Box
                                        key={f.id}
                                        className={classes.functionsListItem}
                                    >
                                        <Box className={classes.label}>
                                            <RiskIndicator
                                                variant={f.riskLevel}
                                                label={t(
                                                    `MyTasks_${f.riskLevel}Risk`,
                                                )}
                                                className={
                                                    f &&
                                                    f.riskLevel !==
                                                        'Critical' &&
                                                    classes.riskIndicator
                                                }
                                            />
                                        </Box>
                                        <Typography className={classes.value}>
                                            {f.friendlyName}
                                        </Typography>
                                    </Box>
                                ))}
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    )
}
