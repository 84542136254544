import React from 'react'
import { usePageContext } from 'pageContext'
import { PersonFilter } from 'components'
import { useAuthState } from 'packages/core'
import { useTranslation } from 'react-i18next'
import {
    AttributeLabel,
    AttributeContainer,
    AttributeValue,
    getLocationString,
    styles,
    VerticalAlignedLabel,
} from 'components/DetailsComponents'
import { Avatar as MuiAvatar, Box, makeStyles } from '@material-ui/core'
import { getFullImageUrl, smallScreenWidth } from 'utils'
import moment from 'moment'
import useSubcomponents from 'useSubcomponents'

export const Filter = () => {
    const [state, dispatch]: any = usePageContext()
    const [{ currentUserId, friendlyName }]: any = useAuthState()

    return (
        <PersonFilter
            value={state.subject}
            onClear={() =>
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'subject',
                        value: {
                            id: currentUserId,
                            friendlyName: friendlyName,
                        },
                    },
                })
            }
            onChange={(payload: any) => {
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'subject',
                        value: payload,
                    },
                })
            }}
        />
    )
}

const useStyles = makeStyles({
    avatarContainer: {
        display: 'flex',
        justifyContent: 'center',
        [`@media (max-width:${smallScreenWidth})`]: {
            display: 'flex',
            marginLeft: '60px',
        },
    },
    avatar: {
        width: '152px',
        height: '153px',
        boxShadow: 'rgb(110 110 111) 0px 0px 8px 0px',
    },
    viewProfileAnchor: {
        textDecoration: 'none',
        color: 'rgb(48, 127, 193)',
        '&:hover,&:active,&:visted': {
            color: 'rgb(48, 127, 193)',
        },
    },
})

export const Details = ({ accessRecipient, data }: any) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const { canSeeEIDProfileLink } = useSubcomponents()

    const { BASE_EID_URL } = (window as any).config
    return (
        <>
            <Box
                className={classes.avatarContainer}
                marginBottom="24px !important"
            >
                <MuiAvatar
                    src={getFullImageUrl(accessRecipient.personPhotoUrl)}
                    className={classes.avatar}
                />
            </Box>

            <AttributeContainer>
                <VerticalAlignedLabel>
                    {t('MyTasks_AccessRecipient')}
                </VerticalAlignedLabel>

                <AttributeValue
                    value={accessRecipient.friendlyName}
                    style={styles.roleReceiverValue}
                    noWrap
                />
            </AttributeContainer>

            <AttributeContainer>
                <VerticalAlignedLabel>
                    {t('MyTasks_Email')}
                </VerticalAlignedLabel>
                <AttributeValue value={accessRecipient.email} noWrap />
            </AttributeContainer>

            <AttributeContainer>
                <VerticalAlignedLabel>
                    {t('MyTasks_Login')}
                </VerticalAlignedLabel>

                <AttributeValue
                    value={accessRecipient.login}
                    noWrap
                    style={styles.locationValue}
                />
            </AttributeContainer>

            <AttributeContainer>
                <VerticalAlignedLabel>
                    {t('MyTasks_Location')}
                </VerticalAlignedLabel>

                <AttributeValue
                    value={getLocationString(
                        accessRecipient.city,
                        accessRecipient.country,
                    )}
                    noWrap
                />
            </AttributeContainer>

            <AttributeContainer>
                <VerticalAlignedLabel>
                    {t('MyTasks_Manager')}
                </VerticalAlignedLabel>
                <AttributeValue value={accessRecipient.managerName} noWrap />
            </AttributeContainer>

            <AttributeContainer>
                <VerticalAlignedLabel>
                    {t('MyTasks_JobFunction')}
                </VerticalAlignedLabel>
                <AttributeValue value={accessRecipient.jobTitle} noWrap />
            </AttributeContainer>

            <AttributeContainer>
                <VerticalAlignedLabel>
                    {t('MyTasks_EmployeeId')}
                </VerticalAlignedLabel>
                <AttributeValue value={accessRecipient.employeeId} noWrap />
            </AttributeContainer>

            {data && data.startDateUtc && (
                <AttributeContainer>
                    <AttributeLabel>
                        {t('MyTasks_AccessStartDate')}
                    </AttributeLabel>

                    <AttributeValue
                        value={moment
                            .utc(data.startDateUtc)
                            .local()
                            .format('LLL')}
                    />
                </AttributeContainer>
            )}

            {data && data.endDateUtc && (
                <AttributeContainer>
                    <AttributeLabel>
                        {t('MyTasks_AccessEndDate')}
                    </AttributeLabel>

                    <AttributeValue
                        value={moment
                            .utc(data.endDateUtc)
                            .local()
                            .format('LLL')}
                    />
                </AttributeContainer>
            )}

            {canSeeEIDProfileLink && (
                <AttributeContainer style={{ marginTop: 15 }}>
                    <a
                        className={classes.viewProfileAnchor}
                        target="_blank"
                        href={BASE_EID_URL + accessRecipient.eidPersonUrl}
                    >
                        {' '}
                        {t('MyTasks_ViewUserProfile')}
                    </a>
                </AttributeContainer>
            )}
        </>
    )
}
