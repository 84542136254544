export const initialState = {}

const reducer = (state, { type }) => {
    switch (type) {
        default:
            return state
    }
}

export default reducer
