import React, { FC, Fragment, MouseEvent, useRef, useState } from 'react'
import useSubcomponents from 'useSubcomponents'
import { Box, makeStyles, MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { createStyles } from '@material-ui/core/styles'
import { Icon } from 'packages/eid-icons'
import { Dropdown } from 'packages/eid-ui'

const useStyles = makeStyles(
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
        },
        arrowContainer: {
            marginLeft: '8px',
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                color: '#D8D8DD',
            },
            '&:hover': {
                '& svg': {
                    color: '#307fc1',
                },
            },
        },
        dropdownAnchor: {
            position: 'relative',
            top: '30px',
        },
        menuItem: {
            color: '#000000',
            borderLeft: '1.5px solid transparent',
            '&.Mui-selected': {
                borderLeft: '1.5px solid #307fc1',
                backgroundColor: 'rgba(48, 127, 193, 0.07) !important',
                '&:hover': {
                    backgroundColor: 'rgba(48, 127, 193, 0.07) !important',
                },
                '& svg': {
                    color: '#000000',
                },
            },
            '&:hover': {
                color: '#307fc1',
                backgroundColor: 'inherit !important',
                borderLeft: '1.5px solid #307fc1',
                '& svg': {
                    color: '#307fc1',
                },
            },
        },
    }),
)

export const RiskViolationsTabLabel: FC<any> = (props) => {
    const { t } = useTranslation()

    const classes = useStyles()

    const {
        canSeeRiskViolationsByItems,
        canSeeRiskViolationsByRisks,
    } = useSubcomponents()

    const hasAccessToBothViews =
        canSeeRiskViolationsByItems && canSeeRiskViolationsByRisks

    const [open, setOpen] = useState<boolean>(false)

    const anchorRef: any = useRef(null)

    const handleClose = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    const handleListKeyDown = (event: any) => {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }

    let key = ''
    if (props.selected === 'ByRisks') {
        key = 'MyTasks_RiskViolationsByRisks'
    }
    if (props.selected === 'ByItems') {
        key = 'MyTasks_RiskViolationsByItems'
    }

    return (
        <Box className={classes.root}>
            <span ref={anchorRef} className={classes.dropdownAnchor} />
            <Box>{t(key)}</Box>
            {hasAccessToBothViews && (
                <Fragment>
                    <Box
                        className={classes.arrowContainer}
                        onClick={(event) => {
                            event.stopPropagation()
                            setOpen(true)
                        }}
                    >
                        <Icon name="Arrow" />
                    </Box>
                    <Dropdown
                        open={open}
                        anchorEl={anchorRef.current}
                        handleClose={handleClose}
                        handleListKeyDown={handleListKeyDown}
                        menuHeight="50vh"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem
                            className={classes.menuItem}
                            selected={props.selected === 'ByRisks'}
                            onClick={(e) => {
                                setOpen(false)
                                props.setSelected('ByRisks')
                            }}
                        >
                            {t('MyTasks_ByRisks')}
                        </MenuItem>
                        <MenuItem
                            className={classes.menuItem}
                            selected={props.selected === 'ByItems'}
                            onClick={(e) => {
                                setOpen(false)
                                props.setSelected('ByItems')
                            }}
                        >
                            {t('MyTasks_ByItems')}
                        </MenuItem>
                    </Dropdown>
                </Fragment>
            )}
        </Box>
    )
}
