import { useAxios } from 'packages/core'
import { usePageContext } from 'pageContext'
import {
    queryCache,
    useMutation,
    usePaginatedQuery,
    useQuery,
} from 'react-query'
import useApiWithInfiniteScroll from './useApiWithInfiniteScroll'
import {
    augmentRisksWithDecisions,
    groupByItems,
    groupByRisks,
    refreshRequest,
    refreshRequestItemsByItemType,
    refreshRequestOrRequestItem,
    transformApprovalStepsResponse,
} from './utils'
import {
    IAccessRequestPolicy,
    IItemRisk,
    IMitigatingControl,
    IRiskFunction,
} from 'types'
import * as R from 'ramda'
export const REQUESTS_KEY = 'REQUESTS_KEY'

export const useMyRequests = () => {
    const [state]: any = usePageContext()
    const {
        searchTerm,
        startDate,
        endDate,
        sortOrder,
        sortBy,
        requestedBy,
        approver,
        showOnlyWhereNoApprovers,
        showOnlyPreApproved,
        approverType,
        status,
        subjectType,
        subject,
        audit,
        itemType,
        riskLevels,
        businessRequestType,
    } = state

    const description = state['advancedSearch.forms.description']
    const requestNumber = state['advancedSearch.forms.requestNumber']

    const callApi = useAxios()

    const queryData: any = {}

    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (startDate) {
        queryData.startDateUtc = startDate
    }
    if (endDate) {
        queryData.endDateUtc = endDate
    }
    if (requestedBy) {
        queryData.initiatorPersonId = requestedBy.id
    }
    if (approver) {
        if (approverType === 'past') {
            queryData.pastApproverPersonId = approver.id
        } else {
            queryData.approverPersonId = approver.id
        }
    }
    if (showOnlyWhereNoApprovers) {
        queryData.showOnlyWhereNoApprovers = true
    }
    if (showOnlyPreApproved) {
        queryData.showOnlyPreApproved = true
    }
    if (status && status.length > 0) {
        queryData.status = status.map((i: any) => i.id)
    }
    if (itemType && itemType.length > 0) {
        queryData.itemType = itemType.map((i: any) => i.id)
    }
    if (riskLevels && riskLevels.length > 0) {
        queryData.riskLevels = riskLevels
    }
    if (subjectType) {
        queryData.subjectTypeId = subjectType.id
    }
    if (subject) {
        queryData.subjectId = subject.id
    }
    if (audit) {
        queryData.auditId = audit.id
    }
    if (businessRequestType) {
        queryData.type = businessRequestType.id
    }
    const advancedSearch = []
    if (description) {
        advancedSearch.push({
            name: 'FriendlyName',
            type: 'string',
            value: description,
        })
    }
    if (requestNumber) {
        advancedSearch.push({
            name: 'Number',
            type: 'int',
            value: requestNumber,
        })
    }

    queryData.advancedSearch = advancedSearch

    return useApiWithInfiniteScroll(
        [
            REQUESTS_KEY,
            'LIST_MY_REQUESTS',
            searchTerm,
            sortBy,
            sortOrder,
            startDate,
            endDate,
            requestedBy,
            approver,
            showOnlyWhereNoApprovers,
            showOnlyPreApproved,
            approverType,
            status,
            itemType,
            riskLevels,
            description,
            requestNumber,
            subjectType,
            subject,
            audit,
            businessRequestType?.id,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: '/api/businessRequests/my',
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useMyRequestsTasks = () => {
    const [state]: any = usePageContext()
    const {
        searchTerm,
        startDate,
        endDate,
        sortOrder,
        sortBy,
        requestedBy,
        itemType,
        riskLevels,
        subjectType,
        subject,
        audit,
        businessRequestType,
    } = state

    const description = state['advancedSearch.forms.description']
    const requestNumber = state['advancedSearch.forms.requestNumber']

    const callApi = useAxios()

    const queryData: any = {}
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (startDate) {
        queryData.startDateUtc = startDate
    }
    if (endDate) {
        queryData.endDateUtc = endDate
    }
    if (requestedBy) {
        queryData.initiatorPersonId = requestedBy.id
    }
    if (itemType.length > 0) {
        queryData.itemType = itemType.map((i: any) => i.id)
    }
    if (riskLevels && riskLevels.length > 0) {
        queryData.riskLevels = riskLevels
    }
    if (subjectType) {
        queryData.subjectTypeId = subjectType.id
    }
    if (subject) {
        queryData.subjectId = subject.id
    }
    if (audit) {
        queryData.auditId = audit.id
    }
    if (businessRequestType) {
        queryData.type = businessRequestType.id
    }
    const advancedSearch = []
    if (description) {
        advancedSearch.push({
            name: 'FriendlyName',
            type: 'string',
            value: description,
        })
    }
    if (requestNumber) {
        advancedSearch.push({
            name: 'Number',
            type: 'int',
            value: requestNumber,
        })
    }

    queryData.advancedSearch = advancedSearch

    return useApiWithInfiniteScroll(
        [
            REQUESTS_KEY,
            'LIST_MY_TASKS',
            searchTerm,
            sortBy,
            sortOrder,
            startDate,
            endDate,
            requestedBy,
            itemType,
            riskLevels,
            description,
            requestNumber,
            subjectType,
            subject,
            audit,
            businessRequestType?.id,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: '/api/businessRequests/todo',
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useAllRequests = () => {
    const [state]: any = usePageContext()
    const {
        searchTerm,
        sortBy,
        sortOrder,
        startDate,
        endDate,
        approver,
        showOnlyWhereNoApprovers,
        showOnlyPreApproved,
        approverType,
        requestedBy,
        status,
        itemType,
        riskLevels,
        subjectType,
        subject,
        audit,
        businessRequestType,
    } = state

    const description = state['advancedSearch.forms.description']
    const requestNumber = state['advancedSearch.forms.requestNumber']

    const callApi = useAxios()

    const queryData: any = {}
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (startDate) {
        queryData.startDateUtc = startDate
    }
    if (endDate) {
        queryData.endDateUtc = endDate
    }
    if (requestedBy) {
        queryData.initiatorPersonId = requestedBy.id
    }
    if (approver) {
        if (approverType === 'past') {
            queryData.pastApproverPersonId = approver.id
        } else {
            queryData.approverPersonId = approver.id
        }
    }
    if (showOnlyWhereNoApprovers) {
        queryData.showOnlyWhereNoApprovers = true
    }
    if (showOnlyPreApproved) {
        queryData.showOnlyPreApproved = true
    }
    if (status && status.length > 0) {
        queryData.status = status.map((i: any) => i.id)
    }
    if (itemType && itemType.length > 0) {
        queryData.itemType = itemType.map((i: any) => i.id)
    }
    if (riskLevels && riskLevels.length > 0) {
        queryData.riskLevels = riskLevels
    }
    if (subjectType) {
        queryData.subjectTypeId = subjectType.id
    }
    if (subject) {
        queryData.subjectId = subject.id
    }
    if (audit) {
        queryData.auditId = audit.id
    }
    if (businessRequestType) {
        queryData.type = businessRequestType.id
    }
    const advancedSearch = []

    if (description) {
        advancedSearch.push({
            name: 'FriendlyName',
            type: 'string',
            value: description,
        })
    }
    if (requestNumber) {
        advancedSearch.push({
            name: 'Number',
            type: 'int',
            value: requestNumber,
        })
    }

    queryData.advancedSearch = advancedSearch

    return useApiWithInfiniteScroll(
        [
            REQUESTS_KEY,
            'LIST_ALL',
            searchTerm,
            sortBy,
            sortOrder,
            startDate,
            endDate,
            approver,
            showOnlyWhereNoApprovers,
            showOnlyPreApproved,
            approverType,
            requestedBy,
            status,
            itemType,
            riskLevels,
            description,
            requestNumber,
            subjectType,
            subject,
            audit,
            businessRequestType?.id,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: 'api/businessRequests/all',
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useRequestItemTypeSummaryByItemType = (
    requestId: any,
    itemTypeId: any,
) => {
    const callApi = useAxios()
    return useQuery(
        [REQUESTS_KEY, requestId, 'ITEM_TYPE_SUMMARY', itemTypeId],
        () =>
            callApi({
                url: `api/businessRequests/itemTypesSummary/${requestId}?itemTypeId=${itemTypeId}`,
                method: 'GET',
            }).then((data) => data.data),
        {
            enabled: Boolean(requestId) && Boolean(itemTypeId),
        },
    )
}

export const useRequest = (requestId: any) => {
    const callApi = useAxios()
    return useQuery(
        [REQUESTS_KEY, requestId],
        () =>
            callApi({
                url: `api/businessRequests/${requestId}`,
                method: 'GET',
            }).then((data) => data.data),
        {
            enabled: Boolean(requestId),
        },
    )
}

export const useRequestApprovalSteps = (requestId: any) => {
    const callApi = useAxios()
    const { data: request } = useRequest(requestId)

    const url = `api/businessRequests/approvalSteps/${requestId}`
    return useQuery(
        [REQUESTS_KEY, requestId, 'APPROVAL_STEPS'],
        () =>
            callApi({
                url,
                method: 'GET',
            }).then((data) =>
                transformApprovalStepsResponse(request, data.data),
            ),
        {
            enabled: Boolean(request),
        },
    )
}

export const useRequestAccessRecipient = (requestId: any) => {
    const callApi = useAxios()
    return useQuery(
        [REQUESTS_KEY, requestId, 'ACCESS_RECIPIENT'],
        () =>
            callApi({
                url: `api/businessRequests/subjectDetails/${requestId}`,
                method: 'GET',
            }).then((data) => data.data),
        {
            enabled: Boolean(requestId),
        },
    )
}

export const useRequestApprovers = (
    requestId: any,
    skip: any,
    take: any,
    searchTerm: any,
) => {
    const callApi = useAxios()

    return usePaginatedQuery(
        [REQUESTS_KEY, requestId, 'APPROVERS', skip, take, searchTerm],
        () =>
            callApi({
                url: `/api/businessRequests/approvers/${requestId}`,
                method: 'POST',
                data: {
                    searchTerm: searchTerm,
                    skip: skip,
                    take: take,
                },
            }).then((data) => data),
        {
            enabled: Boolean(requestId),
        },
    )
}

export const useRequestStepApprovers = (
    requestId: any,
    stepId: any,
    skip: any,
    take: any,
    searchTerm: any,
) => {
    const callApi = useAxios()

    return usePaginatedQuery(
        [REQUESTS_KEY, requestId, stepId, 'APPROVERS', skip, take, searchTerm],
        () =>
            callApi({
                url: `/api/businessRequests/approvers/${requestId}/${stepId}`,
                method: 'POST',
                data: {
                    searchTerm: searchTerm,
                    skip: skip,
                    take: take,
                },
            }).then((data) => data),
        {
            enabled: Boolean(requestId) && Boolean(stepId),
        },
    )
}

export const useRequestApprovalDecisions = (
    requestId: any,
    isApprovable: any,
) => {
    const callApi = useAxios()
    return useQuery(
        [REQUESTS_KEY, requestId, 'APPROVAL_DECISIONS'],
        () =>
            callApi({
                url: `api/businessRequests/approvalDecisions/${requestId}`,
                method: 'GET',
            }).then((data) => data.data),
        {
            enabled: Boolean(requestId) && isApprovable,
        },
    )
}

export const useSubmitRequestDecision = (requestId: any) => {
    const callApi = useAxios()

    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: '/api/businessRequests/submitDecision',
                data,
            }),
        {
            onSettled: () => refreshRequest(requestId),
        },
    )
}

export const useRequestPendingItems = (
    requestId: any,
    skip: any,
    take: any,
    searchKey: any,
) => {
    const callApi = useAxios()
    const queryObj: any = {
        skip: skip,
        take: take,
    }
    if (searchKey) {
        queryObj.searchTerm = searchKey
    }

    return usePaginatedQuery(
        [REQUESTS_KEY, requestId, 'PENDING_ITEMS', skip, take, searchKey],
        () =>
            callApi({
                url: `/api/businessRequestItems/${requestId}/pendingItems`,
                method: 'POST',
                data: queryObj,
            }),
        {
            enabled: Boolean(requestId),
        },
    )
}

export const useRequestRisks = (
    requestId: string,
    groupBy: undefined | 'Items' | 'Risks' = undefined,
) => {
    const callApi = useAxios()
    return useQuery(
        [REQUESTS_KEY, requestId, 'RISKS', groupBy],
        () =>
            callApi({
                url: `api/businessRequests/risks/${requestId}`,
            }).then((data) => {
                switch (groupBy) {
                    case 'Items':
                        return groupByItems(requestId, data.data)
                    case 'Risks':
                        return groupByRisks(data.data)
                    default:
                        return data.data
                }
            }),
        {
            enabled: Boolean(requestId),
        },
    )
}

const _useRequestPendingRiskItems = (
    requestId: string,
    skip: number,
    take: number,
    searchKey?: string,
) => {
    const callApi = useAxios()
    const queryObj: any = {
        skip: skip,
        take: take,
    }
    if (searchKey) {
        queryObj.searchTerm = searchKey
    }

    return usePaginatedQuery(
        [REQUESTS_KEY, requestId, 'PENDING_RISK_ITEMS', skip, take, searchKey],
        () =>
            callApi({
                url: `/api/businessRequestItems/${requestId}/pendingRiskItems`,
                method: 'POST',
                data: queryObj,
            }).then((data) => data.data),
        {
            enabled: Boolean(requestId),
        },
    )
}

export const useRequestPendingRiskItems = (requestId: any) => {
    const {
        data: items,
        isLoading: isLoadingItems,
    } = _useRequestPendingRiskItems(requestId, 0, 50)

    const { data: risks, isLoading: isLoadingRisks } = useRequestRisks(
        requestId,
    )

    if (isLoadingItems || isLoadingRisks || !items || !risks) {
        return {
            data: undefined,
            totalCount: undefined,
            isLoading: true,
        }
    }

    const pendingRiskItems = groupByRisks(
        augmentRisksWithDecisions(items, risks),
    )

    return {
        data: pendingRiskItems,
        totalCount: pendingRiskItems.length,
        isLoading: false,
    }
}

export const useSaveRequestToDoItemDecision = (requestId: any) => {
    const callApi = useAxios()

    return useMutation(
        (data) =>
            callApi({
                url: `/api/businessRequestItems/submitDecisions/${requestId}`,
                method: 'POST',
                data,
            }),
        {
            onSettled: () => refreshRequest(requestId),
        },
    )
}

export const useRequestItemTypes = (requestId: any) => {
    const callApi = useAxios()
    return useQuery(
        [REQUESTS_KEY, requestId, 'ITEM_TYPES'],
        () =>
            callApi({
                url: `api/businessRequests/itemTypesSummary/${requestId}`,
                method: 'GET',
            }).then((data) => data.data),
        {
            enabled: Boolean(requestId),
        },
    )
}

export const useRequestItemsByItemType = (
    requestId: any,
    itemTypeId: any,
    isFulfillmentStatus: boolean = false,
    status: any,
    skip: any,
    take: any,
) => {
    const callApi = useAxios()

    return usePaginatedQuery(
        [
            REQUESTS_KEY,
            requestId,
            'REQUEST_ITEMS_BY_ITEM_TYPE',
            itemTypeId,
            status,
            skip,
            take,
        ],
        () =>
            callApi({
                url: '/api/businessRequestItems/byItemType',
                method: 'POST',
                data: {
                    requestId,
                    itemTypeId,
                    itemStatus: isFulfillmentStatus ? undefined : status,
                    fulfillmentStatus: isFulfillmentStatus ? status : undefined,
                    skip,
                    take,
                },
            }).then((data) => data),
        {
            enabled: Boolean(requestId) && Boolean(itemTypeId),
        },
    )
}

export const useRequestItemSummaryEditFulfillmentDate = (
    itemId: string,
    requestId: any,
    itemTypeId: any,
) => {
    const callApi = useAxios()

    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: `/api/BusinessRequestItems/updateFulfilmentDate/${itemId}`,
                data: data,
            }),
        {
            onSuccess: () => {
                refreshRequestItemsByItemType(requestId, itemTypeId)
            },
        },
    )
}

export const useRequestItemApprovers = (
    requestId: any,
    itemId: any,
    skip: any,
    take: any,
    searchTerm: any,
) => {
    const callApi = useAxios()
    return usePaginatedQuery(
        [
            REQUESTS_KEY,
            requestId,
            'REQUEST_ITEM_APPROVERS',
            itemId,
            skip,
            take,
            searchTerm,
        ],
        () =>
            callApi({
                url: `/api/businessRequestItems/globalStepApprovers/${itemId}`,
                method: 'POST',
                data: {
                    searchTerm: searchTerm,
                    skip: skip,
                    take: take,
                },
            }).then((data) => data),
        {
            enabled: Boolean(itemId),
        },
    )
}

export const useRequestComments = (requestId: any) => {
    const callApi = useAxios()

    return useQuery(
        [REQUESTS_KEY, requestId, 'REQUEST_COMMENTS'],
        () =>
            callApi({
                url: `api/comments/all/${requestId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(requestId),
        },
    )
}

export const useRequestViewItemComments = (requestId: any, itemId: any) => {
    const callApi = useAxios()

    return useQuery(
        [REQUESTS_KEY, requestId, 'REQUEST_View_ITEM_COMMENTS', itemId],

        () =>
            callApi({
                url: `api/comments/all/${requestId}/${itemId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(requestId) && Boolean(itemId),
        },
    )
}

export const useRequestDelegationsHistory = (requestId: any) => {
    const callApi = useAxios()
    return useQuery(
        [REQUESTS_KEY, requestId, 'DELEGATIONS_HISTORY'],
        () =>
            callApi({
                url: `api/delegations/manualDelegations/${requestId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(requestId),
        },
    )
}

export const useRequestViewItemRisks = (requestId: string, itemId: string) => {
    const callApi = useAxios()
    return useQuery<IItemRisk[]>(
        [REQUESTS_KEY, requestId, itemId, 'RISKS'],
        () =>
            callApi({
                url: `api/businessRequestItems/risks/${itemId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(requestId) && Boolean(itemId),
        },
    )
}

export const useRiskFunctions = (
    assigneeId: any,
    localRiskId: any,
    isLeftSide: boolean,
    inverted: boolean,
) => {
    const callApi = useAxios()
    return useQuery<IRiskFunction[]>(
        [
            REQUESTS_KEY,
            assigneeId,
            localRiskId,
            isLeftSide,
            inverted,
            'RISKS_FUNCTIONS',
        ],
        () =>
            callApi({
                method: 'GET',
                url: `/api/risks/riskFunctions/${assigneeId}/${localRiskId}/${
                    isLeftSide ? 'left' : 'right'
                }?riskDetailsInversed=${inverted}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(assigneeId) && Boolean(localRiskId),
        },
    )
}

export const useCancelRequest = (requestId: string) => {
    const callApi = useAxios()

    return useMutation(
        () =>
            callApi({
                method: 'POST',
                url: `/api/businessRequests/cancel/${requestId}`,
            }),
        {
            onSettled: () => refreshRequest(requestId),
        },
    )
}

export const useMitigatingControls = (
    globalRiskId: string,
    enabled: boolean,
    riskViolationId?: string,
) => {
    const callApi = useAxios()
    return useQuery<IMitigatingControl[]>(
        [
            REQUESTS_KEY,
            globalRiskId,
            enabled,
            riskViolationId,
            'MITIGATING_CONTROLS',
        ],
        () =>
            callApi({
                method: 'POST',
                url: `/api/risks/mitigatingControls/${globalRiskId}`,
                data: {
                    skip: 0,
                    take: 15,
                    fetchSelectedMitigationControls: Boolean(riskViolationId),
                    riskViolationId,
                },
            }).then((data) => data.data),
        {
            enabled: Boolean(globalRiskId) && enabled,
        },
    )
}

export const useSubmitRiskItemDecision = (
    page: string,
    requestId: string,
    itemId?: string,
) => {
    const callApi = useAxios()

    return useMutation(
        (data: any) =>
            callApi({
                method: 'POST',
                url: `/api/risks/submitDecision`,
                data,
            }),
        {
            onSettled: () =>
                refreshRequestOrRequestItem(page, requestId, itemId),
        },
    )
}

export const useRefreshRequestRisks = (requestId: string) => {
    const callApi = useAxios()

    return useMutation(
        () =>
            callApi({
                method: 'POST',
                url: `/api/BusinessRequests/refreshRisks/${requestId}`,
            }),
        {
            onSettled: () => refreshRequest(requestId),
        },
    )
}

export const useItemMitigatingControlsRequestView = (stepId: string) => {
    const callApi = useAxios()

    return useQuery(
        [REQUESTS_KEY, stepId, 'ITEM_MITIGATING_CONTROLS'],
        () =>
            callApi({
                method: 'POST',
                url: `/api/risks/violationItemMitigatingControls/${stepId}`,
                data: {
                    skip: 0,
                    take: 15,
                },
            }).then((data) => data.data),
        {
            enabled: Boolean(stepId),
        },
    )
}

export const useSubmitViolationItemDecision = (
    page: string,
    requestId: string,
    itemId?: string,
) => {
    const callApi = useAxios()

    return useMutation(
        (data: any) =>
            callApi({
                method: 'POST',
                url: `/api/risks/submitViolationItemDecision`,
                data,
            }),
        {
            onSettled: () =>
                refreshRequestOrRequestItem(page, requestId, itemId),
        },
    )
}

export const useAccessRequestPolicies = () => {
    const callApi = useAxios()
    return useQuery<Record<string, IAccessRequestPolicy>>(
        'REQUEST_POLICIES_KEY',
        () =>
            callApi({
                method: 'GET',
                url: '/api/common/requestPolicies?take=50',
            }).then((data) => _reducePolicies(data.data)),
        {
            staleTime: Infinity,
        },
    )
}

const _reducePolicies: (
    policies: IAccessRequestPolicy[],
) => Record<string, IAccessRequestPolicy> = (policies) => {
    return R.reduce<IAccessRequestPolicy, Record<string, IAccessRequestPolicy>>(
        (result, policy) => {
            result[policy.id] = policy
            return result
        },
        {},
        policies,
    )
}

export const useAccessRequestPolicy = (policyId: string) => {
    const { data: requestAccessPolicies } = useAccessRequestPolicies()

    if (requestAccessPolicies === undefined) return undefined

    const policy: IAccessRequestPolicy = requestAccessPolicies[policyId]

    return policy
}

export const SaveTimeConstraintForRequest = (requestData: any, id: string) => {
    const callApi = useAxios()
    return useMutation(
        () =>
            callApi({
                method: 'PATCH',
                url: '/api/BusinessRequestItems/timeConstraint',
                data: requestData,
            }),
        {
            onSuccess: () => {
                queryCache.refetchQueries([
                    REQUESTS_KEY,
                    id,
                    'PENDING_ITEMS',
                    0,
                    10,
                    '',
                ])
            },
        },
    )
}
