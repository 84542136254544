import React from 'react'
import { Box } from '@material-ui/core'
import {
    ListItemSkeleton,
    Label,
    InfoContainer,
    InfoLabelsContainer,
    getDecisionUiData,
    DisplayNamesContainer,
} from './utils'
import moment from 'moment'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'

const CommentDetails = ({
    classes,
    loading,
    commentorPersonFriendlyName,
    participantDecisionFriendlyName,
    participantTypeFriendlyName,
    dateTimeToShow,
    lastEditDateUtc,
    editIcon,
    deleteIcon,
}) => {
    const { t } = useTranslation()
    const commentorPersonLabel = (
        <Label className={classes.label}>
            {loading ? (
                <ListItemSkeleton animation="wave" variant="rect" />
            ) : (
                commentorPersonFriendlyName
            )}
        </Label>
    )

    const participantDecisionLabel = participantDecisionFriendlyName &&
        getDecisionUiData(participantDecisionFriendlyName) && (
            <>
                <Icon
                    name={
                        getDecisionUiData(participantDecisionFriendlyName).icon
                    }
                    color={
                        getDecisionUiData(participantDecisionFriendlyName).color
                    }
                    width="16px"
                    height="16px"
                    style={{ marginRight: '8px' }}
                ></Icon>
                <Label className={classes.decisionLabel}>
                    {participantDecisionFriendlyName}
                </Label>
            </>
        )

    const dateTime = (
        <Label className={classes.date}>
            {loading ? (
                <ListItemSkeleton animation="wave" variant="rect" />
            ) : (
                dateTimeToShow &&
                moment
                    .utc(dateTimeToShow)
                    .local()
                    .format('MMMM Do YYYY, h:mm a')
            )}
            {lastEditDateUtc && (
                <Box component="span" marginLeft="4px">
                    ({t('MyTasks_Edited')})
                </Box>
            )}
        </Label>
    )

    return (
        <>
            <InfoContainer
                display="flex"
                justifyContent="space-between"
                paddingY="5px"
            >
                <DisplayNamesContainer>
                    <Box display="flex" alignItems="center">
                        {commentorPersonLabel}
                        {participantTypeFriendlyName && (
                            <Label className={classes.typeLabel}>
                                ({participantTypeFriendlyName})
                            </Label>
                        )}
                    </Box>
                    <Box display="flex" alignItems="center">
                        {editIcon}

                        {deleteIcon}
                    </Box>
                </DisplayNamesContainer>

                <InfoLabelsContainer>
                    <Box width="1px" height="1px" />
                    {participantDecisionLabel}
                    {dateTime}
                </InfoLabelsContainer>
            </InfoContainer>
        </>
    )
}

export default CommentDetails
