import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import Button from '../Button'
import { useTranslation } from 'react-i18next'
import { isEmptyOrWhitespace, isWhitespace } from 'packages/core'
import { StyledInput, TextButtonContainer } from './utils'
import { COMMENT_MAX_LENGTH } from 'utils'

const AddReplyComponent = ({ handleAddReply, handleCancel, loading }) => {
    const { t } = useTranslation()
    const [inputText, setInputText] = useState('')

    const notSubmitable =
        inputText.length > COMMENT_MAX_LENGTH ||
        isEmptyOrWhitespace(inputText) ||
        loading

    const handleChange = (event) => {
        const keyword = event.target.value
        setInputText(keyword)
    }

    const handleKeyDown = (event) => {
        if (event.which === 13 && !notSubmitable) {
            handleAddReply(inputText)
            setInputText('')
            event.preventDefault()
        }
    }

    return (
        <Box display="flex" justifyContent="space-between">
            <Box width="100%" marginRight="15px" position="relative">
                <Box
                    position="absolute"
                    bottom="-20px"
                    right={2}
                    fontSize="10px"
                >
                    {inputText.length}/{COMMENT_MAX_LENGTH}
                </Box>
                <StyledInput
                    fullWidth
                    multiline
                    value={inputText}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder={t('MyTasks_AddAComment')}
                    error={
                        inputText.length > COMMENT_MAX_LENGTH ||
                        isWhitespace(inputText)
                    }
                />
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
            >
                <Button.Send
                    disabled={notSubmitable}
                    loading={loading}
                    withOutLable
                    onClick={() => {
                        handleAddReply(inputText)
                        setInputText('')
                    }}
                />
            </Box>
            <TextButtonContainer
                paddingX="10px"
                onClick={() => {
                    handleCancel()
                    setInputText('')
                }}
            >
                {t('MyTasks_Cancel')}
            </TextButtonContainer>
        </Box>
    )
}

export default AddReplyComponent
