import { useState } from 'react'
import { Box, Collapse, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ShowHideButton from '../Button/ShowHideButton'

const styles = {
    headingStyles: {
        fontWeight: 'bold',
        fontSize: '16px',
        marginRight: '10px',
        textTransform: 'uppercase',
    },
    countStyles: {
        marginLeft: '8px',
        fontSize: '16px',
        color: '#aab0b4',
    },
}

const CommentsSectionContainer = ({ children, comments }) => {
    const { t } = useTranslation()

    const [expanded, setExpanded] = useState(false)

    return (
        <Box width="100%">
            <Box
                paddingLeft="30px"
                paddingRight="32px"
                paddingY="12px"
                display="flex"
                alignItems="center"
                borderBottom="solid 1px #d8dadd"
                justifyContent="space-between"
            >
                <Typography style={styles.headingStyles}>
                    {t('MyTasks_Comments')}
                    {comments && (
                        <span style={styles.countStyles}>
                            {
                                comments.filter((c) => !Boolean(c.parentId))
                                    .length
                            }
                        </span>
                    )}
                </Typography>

                <ShowHideButton
                    expanded={expanded}
                    onClick={() => setExpanded((prev) => !prev)}
                />
            </Box>

            <Collapse in={expanded} timeout={300} unmountOnExit>
                <Box
                    borderBottom="solid 1px #d8dadd"
                    paddingRight="18px"
                    paddingTop="10px"
                >
                    {children}
                </Box>
            </Collapse>
        </Box>
    )
}

export default CommentsSectionContainer
