import React from 'react'
import { EidAutocomplete } from 'packages/eid-controls'
import { Box, makeStyles } from '@material-ui/core'
import { Icon, CheckIfIconExists } from 'packages/eid-icons'
import { isNilOrEmpty } from 'packages/core'
import { usePageContext } from 'pageContext'
import { useTranslation } from 'react-i18next'
import {
    AttributeContainer,
    AttributeValue,
    styles,
    VerticalAlignedLabel,
} from 'components/DetailsComponents'
import { smallScreenWidth } from 'utils'

const OptionComponent = EidAutocomplete.getOptionComponent({
    type: 'label',
    labelProp: 'friendlyName',
})

const queryParams = {
    take: 10,
}

const getOptionLabel = (option: any) => option.friendlyName ?? ''

export const Filter = ({ subjectType }: any) => {
    const [state, dispatch]: any = usePageContext()
    const value = state.subject

    const onClear = () =>
        dispatch({
            type: 'SET_PROP',
            payload: {
                key: 'subject',
                value: null,
            },
        })

    const onChange = (payload: any) => {
        dispatch({
            type: 'SET_PROP',
            payload: {
                key: 'subject',
                value: payload,
            },
        })
    }

    return (
        <Box padding="0px 20px 30px 20px">
            <EidAutocomplete.New
                url={`/api/subjectTypes/${subjectType?.id}/subjects`}
                placeholder={subjectType?.friendlyName}
                getOptionLabel={getOptionLabel}
                optionComponent={OptionComponent}
                value={value ? value : null}
                onChange={(_: any, value: any) => {
                    const valueToDispatch = value ? value : null
                    onChange(valueToDispatch)
                }}
                clearIcon={
                    !isNilOrEmpty(value) && (
                        <Icon name="Close" color="#959598" onClick={onClear} />
                    )
                }
                queryParams={queryParams}
                filterOptions={undefined}
            />
        </Box>
    )
}

const useStyles = makeStyles({
    avatarContainer: {
        display: 'flex',
        justifyContent: 'center',
        [`@media (max-width:${smallScreenWidth})`]: {
            display: 'flex',
            marginLeft: '60px',
        },
    },
    avatar: {
        width: '152px',
        height: '153px',
    },
})

export const Details = ({ accessRecipient }: any) => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <>
            <Box
                className={classes.avatarContainer}
                marginBottom="24px !important"
            >
                <Icon
                    name={
                        CheckIfIconExists(accessRecipient.subjectType)
                            ? accessRecipient.subjectType
                            : 'Unknown'
                    }
                    height={153}
                    width={152}
                    color="#848992"
                />
            </Box>

            <AttributeContainer>
                <VerticalAlignedLabel>
                    {t('MyTasks_AccessRecipient')}
                </VerticalAlignedLabel>

                <AttributeValue
                    value={accessRecipient.friendlyName}
                    style={styles.roleReceiverValue}
                    noWrap
                />
            </AttributeContainer>
            <AttributeContainer>
                <VerticalAlignedLabel>{t('MyTasks_Name')}</VerticalAlignedLabel>

                <AttributeValue value={accessRecipient.name} noWrap />
            </AttributeContainer>
            {accessRecipient.email && (
                <AttributeContainer>
                    <VerticalAlignedLabel>
                        {t('MyTasks_Email')}
                    </VerticalAlignedLabel>

                    <AttributeValue value={accessRecipient.email} noWrap />
                </AttributeContainer>
            )}
            {accessRecipient.groupType && (
                <AttributeContainer>
                    <VerticalAlignedLabel>
                        {t('MyTasks_GroupType')}
                    </VerticalAlignedLabel>

                    <AttributeValue value={accessRecipient.groupType} noWrap />
                </AttributeContainer>
            )}
            {accessRecipient.highLevelClassification && (
                <AttributeContainer>
                    <VerticalAlignedLabel>
                        {t('MyTasks_HighLevelClassification')}
                    </VerticalAlignedLabel>

                    <AttributeValue
                        value={accessRecipient.highLevelClassification}
                        noWrap
                    />
                </AttributeContainer>
            )}
            {accessRecipient.accountStore && (
                <AttributeContainer>
                    <VerticalAlignedLabel>
                        {t('MyTasks_AccountStore')}
                    </VerticalAlignedLabel>

                    <AttributeValue
                        value={accessRecipient.accountStore}
                        noWrap
                    />
                </AttributeContainer>
            )}
            {accessRecipient.roleType_1 && (
                <AttributeContainer>
                    <VerticalAlignedLabel>
                        {t('MyTasks_RoleType')}
                    </VerticalAlignedLabel>

                    <AttributeValue value={accessRecipient.roleType_1} noWrap />
                </AttributeContainer>
            )}
            {accessRecipient.roleType_2 && (
                <AttributeContainer>
                    <VerticalAlignedLabel>
                        {t('MyTasks_RoleType')}
                    </VerticalAlignedLabel>

                    <AttributeValue value={accessRecipient.roleType_2} noWrap />
                </AttributeContainer>
            )}
            {accessRecipient.riskType && (
                <AttributeContainer>
                    <VerticalAlignedLabel>
                        {t('MyTasks_RiskType')}
                    </VerticalAlignedLabel>

                    <AttributeValue
                        value={t(`MyTasks_${accessRecipient.riskType}`)}
                        noWrap
                    />
                </AttributeContainer>
            )}
            {accessRecipient.riskLevel && (
                <AttributeContainer>
                    <VerticalAlignedLabel>
                        {t('MyTasks_RiskLevel')}
                    </VerticalAlignedLabel>

                    <AttributeValue
                        value={t(`MyTasks_${accessRecipient.riskLevel}Risk`)}
                        noWrap
                    />
                </AttributeContainer>
            )}
        </>
    )
}
