import { Skeleton } from '@material-ui/lab'
import { Box } from '@material-ui/core'

export const PersonSkeleton = ({ ...rest }: any) => (
    <Box position="relative" width="100%" {...rest}>
        <Box marginY="7.5px" display="flex" alignItems="center">
            <Box >
                <Skeleton variant="circle" width={24} height={24} />
            </Box>

            <Box
                marginLeft="10px"
                minWidth='100px'
            >
                <Skeleton variant="text" />
            </Box>
        </Box>
    </Box>
)

