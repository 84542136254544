import { FC, Fragment, useCallback, useState } from 'react'
import { Box, createStyles, makeStyles, styled } from '@material-ui/core'
import { useRequestItemStepApprovers, useRequestStepApprovers } from 'hooks'
import { RequestedByNode } from './RequestedByNode'
import { StepNode } from './StepNode'
import { RequestItemNode } from './RequestItemNode'
import { Loader, StatusIndicator } from 'packages/eid-ui'
import ProgressIndicator from '../ProgressIndicator'
import { useTranslation } from 'react-i18next'
import { IRequestWithApprovalSteps } from 'types'
import { DiagramArrows } from './DiagramArrows'
import { Container } from 'components/DetailsComponents'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            maxWidth: '1000px',
            backgroundColor: theme.palette.common.white,
            paddingLeft: '20px',
            paddingRight: '20px',
            minWidth: '1000px',
            position: 'relative',
        },
    }),
)

const ProcessSteps = ({ useDataHook, requestId, itemId, isCanceled }: any) => {
    const { t } = useTranslation()

    const classes = useStyles()
    const {
        data: request,
        isFetching,
    }: {
        data: IRequestWithApprovalSteps
        isFetching: boolean
    } = useDataHook(requestId, itemId)

    const [showAllApprovers, setShowAllApprovers] = useState<{
        index: null | number
        stepNumber: null | string
    }>({
        index: null,
        stepNumber: null,
    })

    const [showArrows, setShowArrows] = useState(false)
    const lastStepDivDrawn = useCallback(
        (node) => {
            if (node != null) {
                setShowArrows(true)
            }
        },
        [setShowArrows],
    )
    // TODO: Need to refine this to rerender the arrows
    const rerenderArrows = (time: number) => {
        setShowArrows(false)
        setTimeout(() => {
            setShowArrows(true)
        }, time)
    }

    if (!request || isFetching) return <Loader />

    const progressIndicator = (
        <Fragment>
            <Box color="#5d6870" fontWeight="600" fontSize="12px">
                {t('MyTasks_PercentageCompleted', {
                    value: `${request.percentageCompleted} %`,
                })}
            </Box>
            <ProgressIndicator
                percentageCompleted={request?.percentageCompleted}
            />
        </Fragment>
    )

    return (
        <Container style={{overflow:"inherit",}}>
            <Box className={classes.root}>
                <Box position="absolute" right={45} top={32}>
                    {isCanceled ? (
                        <StatusIndicator
                            variant={'Canceled'}
                            label={t(`MyTasks_Canceled`)}
                            size="large"
                        />
                    ) : (
                        progressIndicator
                    )}
                </Box>

                <RequestedByNode request={request} id={'requestedByNode'} />

                {request.globalApprovalSteps.map((s, index) => {
                    const divProps: any = {}
                    if (index === request.globalApprovalSteps.length - 1) {
                        divProps.ref = lastStepDivDrawn
                    }
                    return (
                        <div key={`${s.stepNumber}_${index}`} {...divProps}>
                            <StepNode
                                rerenderArrows={() => rerenderArrows(200)}
                                id={`global_${s.stepNumber}`}
                                childId={`global_${s.stepNumber}-child`}
                                globalApprover={true}
                                requestId={requestId}
                                approverCount={s.lastApprovalCount}
                                useDataHook={useRequestStepApprovers}
                                showAllApprovers={
                                    showAllApprovers.index === index &&
                                    showAllApprovers.stepNumber === s.stepNumber
                                }
                                setShowAllApprovers={(show: any) => {
                                    if (!show) {
                                        setShowAllApprovers({
                                            index: null,
                                            stepNumber: null,
                                        })
                                    } else {
                                        setShowAllApprovers({
                                            index,
                                            stepNumber: s.stepNumber,
                                        })
                                    }
                                }}
                                step={s}
                            />
                        </div>
                    )
                })}
                <RequestItemProcessSteps
                    request={request}
                    renrederArrows={() => rerenderArrows(200)}
                />
                {(showArrows || request.globalApprovalSteps.length === 0) && (
                    <DiagramArrows arrows={request.arrows} />
                )}
            </Box>
        </Container>
   
   )
}

const initialExpandState = {
    index: null,
    itemId: null,
    stepNumber: null,
}

const ShowMoreLink = styled(Box)({
    color: '#307fc1',
    fontSize: '10px',
    '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
})

export type RequestItemProcessStepsProps = {
    request: IRequestWithApprovalSteps
    renrederArrows: () => void
}

const RequestItemProcessSteps: FC<RequestItemProcessStepsProps> = (props) => {
    const { t } = useTranslation()

    const [showMax, setShowMax] = useState(50)

    const [showAllApprovers, setShowAllApprovers] = useState<{
        index: null | number
        itemId: null | string
        stepNumber: null | string
    }>(initialExpandState)

    const [showArrows, setShowArrows] = useState(false)
    const lastStepDivDrawn = useCallback(
        (node) => {
            if (node != null) {
                setShowArrows(true)
            }
        },
        [setShowArrows],
    )
    // TODO: Need to refine this to rerender the arrows
    const rerenderArrows = (time: number) => {
        props.renrederArrows()
        setShowArrows(false)
        setTimeout(() => {
            setShowArrows(true)
        }, time)
    }

    return (
        <Fragment>
            {props.request.items.slice(0, showMax).map((i) => {
                return (
                    <Fragment key={i.id}>
                        <RequestItemNode id={`item_${i.id}`} item={i}>
                            {i.approvalSteps.map((s: any, index: any) => {
                                const divProps: any = {}
                                if (index === i.approvalSteps.length - 1) {
                                    divProps.ref = lastStepDivDrawn
                                }
                                return (
                                    <div
                                        key={`${i.id}_${s.stepNumber}_${index}`}
                                        {...divProps}
                                    >
                                        <StepNode
                                            rerenderArrows={() =>
                                                rerenderArrows(200)
                                            }
                                            id={`item_${i.id}_${s.stepNumber}`}
                                            childId={`item_${i.id}_${s.stepNumber}-child`}
                                            globalApprover={false}
                                            requestId={props.request.id}
                                            itemId={i.id}
                                            approverCount={s.lastApprovalCount}
                                            useDataHook={
                                                useRequestItemStepApprovers
                                            }
                                            showAllApprovers={
                                                showAllApprovers.index ===
                                                    index &&
                                                showAllApprovers.itemId ===
                                                    i.id &&
                                                showAllApprovers.stepNumber ===
                                                    s.stepNumber
                                            }
                                            setShowAllApprovers={(
                                                show: any,
                                            ) => {
                                                if (!show) {
                                                    setShowAllApprovers(
                                                        initialExpandState,
                                                    )
                                                } else {
                                                    setShowAllApprovers({
                                                        index: index,
                                                        itemId: i.id,
                                                        stepNumber:
                                                            s.stepNumber,
                                                    })
                                                }
                                            }}
                                            step={s}
                                        />
                                    </div>
                                )
                            })}
                        </RequestItemNode>
                        {(showArrows || i.approvalSteps.length === 0) && (
                            <DiagramArrows arrows={i.arrows} />
                        )}
                    </Fragment>
                )
            })}
            {showMax < props.request.items.length && (
                <Box my={4} display="flex" justifyContent="center">
                    <ShowMoreLink
                        onClick={() => setShowMax((prev) => prev + 20)}
                    >
                        {t('MyTasks_ShowMore')}
                    </ShowMoreLink>
                </Box>
            )}
        </Fragment>
    )
}

export default ProcessSteps
