import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { Box, styled } from '@material-ui/core'

const Container = styled('div')(({ theme }) => ({
    padding: '12px',
    height: 'max-content',
    width: '50%',

    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}))

const ItemCardSkeleton = () => {
    return (
        <Container>
            <div
                style={{
                    backgroundColor: '#fff',
                    borderRadius: '8px',
                    overflow: 'hidden',
                }}
            >
                <Skeleton animation="wave" variant="rect" height={80} />
                <Box marginTop={2} padding={2} width="100%">
                    <Skeleton variant="rect" animation="wave" height={8} />
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        height={8}
                        style={{ marginTop: 12 }}
                    />
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        height={8}
                        style={{ marginTop: 12 }}
                    />
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        style={{ height: 96, marginTop: 12 }}
                    />
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        height={48}
                        style={{ marginTop: 16 }}
                    />
                </Box>
            </div>
        </Container>
    )
}

export default ItemCardSkeleton
