import React from 'react'
import { getFullImageUrl } from '../../utils'
import { Person } from '../index'
import { Icon } from 'packages/eid-icons'
import { Box } from '@material-ui/core'
import { supportedSubjectTypes } from './supportedSubjectTypes'
import { Tooltip } from 'packages/eid-ui'

const tooltipProps = {
    color: '#5D6870',
    fontColor: '#ffffff',
    enterDelay: 500,
    enterNextDelay: 500,
    interactive: true,
    tooltipStyles: {
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        padding: '8px',
        maxWidth: '500px',
    },
}

const personStyles = {
    fontSize: '13px',
}

const SubjectTypeAttribute = ({ subjectType, item, personComponentProps }) => {
    switch (subjectType) {
        case 'Person':
            return (
                <Person
                    name={item['subjectFriendlyName']}
                    imgSrc={getFullImageUrl(item['personSubjectThumbnailUrl'])}
                    style={personStyles}
                    tooltipProps={tooltipProps}
                    {...personComponentProps}
                />
            )
        default:
            return (
                <Box display="flex" alignItems="center">
                    {supportedSubjectTypes.includes(item.subjectType) ? (
                        <Tooltip
                            enterDelay={800}
                            enterNextDelay={800}
                            title={item.subjectTypeFriendlyName ?? ''}
                            {...tooltipProps}
                        >
                            <div>
                                <Icon color="#919193" name={item.subjectType} />
                            </div>
                        </Tooltip>
                    ) : (
                        <Tooltip
                            enterDelay={800}
                            enterNextDelay={800}
                            title={item.subjectTypeFriendlyName ?? ''}
                            {...tooltipProps}
                        >
                            <Box
                                height="16px"
                                width="16px"
                                border="4px solid #d8d8dd"
                                bgcolor="transparent"
                                borderRadius="50%"
                            />
                        </Tooltip>
                    )}
                    <Box component="span" marginLeft="10px">
                        {item.subjectFriendlyName}
                    </Box>
                </Box>
            )
    }
}

export default SubjectTypeAttribute
