import React, { useState } from 'react'
import { Avatar, WithCloseButton } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import {
    Box,
    Collapse,
    styled,
    Typography,
    Radio,
    FormControlLabel,
    makeStyles,
} from '@material-ui/core'
import PeopleSearch from 'components/Filters/PeopleSearch'
import { useAuthState } from 'packages/core'
import { useTranslation } from 'react-i18next'
import config from 'config'

const useFormControlStyles = makeStyles(() => ({
    formLabelMargin: {
        marginRight: '30px',
        padding: '10px',
    },

    radio: {
        padding: '0px 12px 0px 0px',
    },

    checked: {
        '&, & + $label': {
            color: '#000000',
        },
    },
    formControlLabel: {
        padding: '6px',
    },

    label: {
        color: '#767676',
    },
}))

const StyledRadio = ({ checked, label, onChange }) => {
    const classes = useFormControlStyles()
    return (
        <FormControlLabel
            classes={{
                root: classes.formControlLabel,
                label: classes.label,
            }}
            checked={checked}
            label={label}
            labelPlacement="end"
            control={
                <Radio
                    icon={<Icon name="Radio" />}
                    classes={{
                        root: classes.radio,
                        checked: classes.checked,
                    }}
                    checkedIcon={<Icon name="RadioFilled" />}
                />
            }
            onChange={onChange}
        />
    )
}

const PersonFilterSomeoneElseIndicator = styled(WithCloseButton)({
    borderRadius: '5px',
    backgroundColor: '#535b62',
    padding: '10px 22px 10px 16px',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
})

const PersonFilter = ({ value, onChange, onClear, ...rest }) => {
    const { t } = useTranslation()
    const [{ currentUserId }] = useAuthState()

    const isForMe = currentUserId === value?.id

    const [someoneElse, setSomeoneElse] = useState(!isForMe && value !== null)

    const changePerson = (payload) => {
        onChange(payload)
    }
    return (
        <>
            {someoneElse && value ? (
                <>
                    <Box padding="0px 20px 0px 20px">
                        <PersonFilterSomeoneElseIndicator
                            onClose={() => {
                                setSomeoneElse(false)
                                changePerson(null)
                            }}
                        >
                            <Avatar
                                src={`${config.BASE_EID_URL}${value.imageThumbUrl}`}
                            />
                            <Typography
                                style={{
                                    marginLeft: '21px',
                                    wordBreak: 'break-all',
                                }}
                                variant="body1"
                            >
                                {value.friendlyName}
                            </Typography>
                        </PersonFilterSomeoneElseIndicator>
                    </Box>
                </>
            ) : (
                <>
                    <Box
                        padding="0px 20px 0px 20px"
                        display="flex"
                        alignItems="center"
                        flexWrap="wrap"
                    >
                        <StyledRadio
                            label={t('MyTasks_Anybody')}
                            checked={value === null && !someoneElse}
                            onChange={() => {
                                changePerson(null)
                                setSomeoneElse(false)
                            }}
                        />
                        <StyledRadio
                            label={t('MyTasks_Myself')}
                            checked={isForMe}
                            onChange={() => {
                                setSomeoneElse(false)
                                onClear()
                            }}
                        />
                        <StyledRadio
                            label={t('MyTasks_SomeoneElse')}
                            checked={someoneElse}
                            onChange={() => {
                                changePerson(null)
                                setSomeoneElse(true)
                            }}
                        />
                    </Box>
                    <Collapse in={someoneElse}>
                        <Box padding="20px">
                            <PeopleSearch
                                label={t('MyTasks_SomeoneElse')}
                                onChange={(_, value) => changePerson(value)}
                                {...rest}
                            />
                        </Box>
                    </Collapse>
                </>
            )}
        </>
    )
}

export default PersonFilter
