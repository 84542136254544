import React from 'react'
import { Box, styled } from '@material-ui/core'
import { useApplicationSettings } from 'hooks'
import ItemCard from 'components/ItemCard'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'packages/core'
import { mobileScreenWidth } from 'utils'

const Container = styled(Box)({
    height: 'inline-block',
    width: '50%',

    overflow: 'hidden',
    [`@media (max-width:${mobileScreenWidth})`]: {
        width: '100%',
    },
})

const RequestItemCard = ({ item, attributes }) => {
    const { data: appConfig } = useApplicationSettings()
    const history = useHistory()
    const query = useQuery()

    return (
        <Container>
            <ItemCard
                cardStyles={{
                    margin: '14px',
                }}
                headerStyles={{
                    backgroundImage: appConfig.style.colors.secondary?.gradient,
                    backgroundColor: appConfig.style.colors.primary?.header,
                    padding: '20px',
                }}
                buttonStyles={{
                    backgroundColor: `${appConfig.style.colors.primary?.button} !important`,
                }}
                attributes={attributes}
                item={item}
                onItemClick={() => {
                    query.set('id', item.id)
                    history.push(
                        `${history.location.pathname}?${query.toString()}`,
                    )
                }}
            />
        </Container>
    )
}

export default RequestItemCard
