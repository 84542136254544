import React, { FC, Fragment } from 'react'
import { RiskWithViolations } from './RiskWithViolations'
import { Box, createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(
    createStyles({
        riskDivider: {
            borderImageSource:
                'linear-gradient(to right, rgba(208, 2, 27, 0), #d0021b 8%, #d00238 91%, rgba(208, 2, 69, 0))',
            borderImageSlice: 1,
            borderBottom: '1px solid',
            opacity: '0.25',
        },
    }),
)

export type PendingRiskItemsProps = {
    page: string
    requestId?: string
    itemId?: string
    data: any
}

export const PendingRiskItems: FC<PendingRiskItemsProps> = (props) => {
    const classes = useStyles()
    return (
        <Fragment>
            {props.data.map((risk: any) => (
                <Box
                    key={risk.id}
                    width={'100%'}
                    paddingX={'24px'}
                    marginY={'32px'}
                >
                    <RiskWithViolations
                        page={props.page}
                        requestId={props.requestId}
                        itemId={props.itemId}
                        risk={risk}
                        showDecisions={true}
                    />
                    <Box className={classes.riskDivider} />
                </Box>
            ))}
        </Fragment>
    )
}
