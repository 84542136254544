import React from 'react'
import Button from './index'
import { Icon } from 'packages/eid-icons'

const styles = {
    normal: {
        minWidth: '145px',
        backgroundColor: '#d0021b;',
        color: '#ffffff',
        '&:hover': {
            boxShadow: '0 2px 8px 0 rgba(208, 2, 27, 0.33)',
            backgroundColor: '#d0021b;',
            color: '#ffffff',
            '& svg': {
                color: '#ffffff',
            },
        },
        '&:active': {
            backgroundColor: '#d0021b;',
            color: '#ffffff',
            '& svg': {
                color: '#ffffff',
            },
            boxShadow:
                '0 2px 8px 0 rgba(208, 2, 27, 0.33), inset 2px 2px 4px 0 rgba(0, 0, 0, 0.11)',
        },
    },
    disabled: {
        backgroundColor: '#aab0b4 !important',
        color: '#ffffff !important',
    },
}

const RevokeButton = (props) => {
    const { endIcon, ...rest } = props

    return (
        <Button
            size="large"
            style={styles.normal}
            disabledStyles={styles.disabled}
            startIcon={
                !endIcon && (
                    <Icon
                        name="Close"
                        color="#ffffff"
                        width="14px"
                        height="14px"
                    />
                )
            }
            endIcon={
                endIcon && (
                    <Icon
                        name="Close"
                        color="#ffffff"
                        width="14px"
                        height="14px"
                    />
                )
            }
            {...rest}
        />
    )
}

export default RevokeButton
