import React, { useState } from 'react'
import {
    Box,
    makeStyles,
    MenuItem as MuiMenuItem,
    styled,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'packages/eid-ui'
import Button from '../Button'
import { Icon } from 'packages/eid-icons'

const MenuItem = styled(MuiMenuItem)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '5px',
    color: '#919193',
    border: '1px solid transparent',
    minHeight: '40px',
    padding: '10px 16px',
    '& svg': {
        width: '16px',
        height: '16px',
        marginBottom: '4px',
    },
})

const decisionActionColors = {
    Continue: {
        primary: '#1eb77b',
        secondary: '#e1fee6',
    },
    End: {
        primary: '#d0021b',
        secondary: '#f9dada',
    },
    DoNothing: {
        primary: '#000000',
    },
    Clear: {
        primary: '#000000',
    },
}

const decisionActionIcons = {
    Continue: 'Approve',
    End: 'Close',
    DoNothing: 'Delete',
    Clear: 'Delete',
}

const useStyles = makeStyles(() => ({
    Continue: {
        color: decisionActionColors.Continue.primary,
        '&:hover': {
            backgroundColor: `${decisionActionColors.Continue.secondary} !important`,
            border: `1px solid ${decisionActionColors.Continue.primary} !important`,
        },
    },
    End: {
        color: decisionActionColors.End.primary,
        '&:hover': {
            backgroundColor: `${decisionActionColors.End.secondary} !important`,
            border: `1px solid  ${decisionActionColors.End.primary} !important`,
        },
    },
    DoNothing: {
        color: decisionActionColors.DoNothing.primary,
        '&:hover': {
            border: `1px solid  ${decisionActionColors.DoNothing.primary} !important`,
        },
    },
    Clear: {
        color: decisionActionColors.DoNothing.primary,
        '&:hover': {
            border: `1px solid  ${decisionActionColors.DoNothing.primary} !important`,
        },
    },
}))

const styles = {
    rootMenu: {
        paddingRight: '0px',
        marginTop: '10px',
        width: '150px',
    },
    list: {
        padding: '0px 6px',
    },
}

const getDecisionLabel = (decision) =>
    decision.decisionFriendlyName ?? decision.decisionName

const ContinueActionMenuItem = ({ o, onChange }) => {
    const classes = useStyles()
    return (
        <MenuItem onClick={onChange} className={classes[o.decisionAction]}>
            {getDecisionLabel(o)}
            <Icon
                name={decisionActionIcons[o.decisionAction]}
                color={decisionActionColors[o.decisionAction].primary}
            />
        </MenuItem>
    )
}

const EndActionMenuItem = ({ o, onChange }) => {
    const classes = useStyles()
    return (
        <MenuItem onClick={onChange} className={classes[o.decisionAction]}>
            {getDecisionLabel(o)}
            <Icon
                name={decisionActionIcons[o.decisionAction]}
                color={decisionActionColors[o.decisionAction].primary}
            />
        </MenuItem>
    )
}

const DoNothingActionMenuItem = ({ o, onChange }) => {
    const classes = useStyles()
    return (
        <MenuItem
            onClick={() => onChange(o.decisionId)}
            className={classes[o.decisionAction]}
        >
            {getDecisionLabel(o)}
        </MenuItem>
    )
}

const actionMenuComponents = {
    Continue: ContinueActionMenuItem,
    End: EndActionMenuItem,
    DoNothing: DoNothingActionMenuItem,
}

const DecisionMenu = ({ value, options, onChange, isClearable = true }) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClose = () => {
        setAnchorEl(null)
        setOpen(false)
    }

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }

    const selectedValue = options.find((o) => o.decisionId === value)

    return (
        <Box>
            <Button.Decision
                endIcon
                variant={
                    selectedValue ? selectedValue.decisionAction : 'default'
                }
                onClick={(e) => {
                    setOpen((prev) => !prev)
                    setAnchorEl(e.currentTarget)
                }}
            >
                {selectedValue
                    ? getDecisionLabel(selectedValue)
                    : t('MyTasks_Decision')}
            </Button.Decision>
            <Dropdown
                open={open}
                anchorEl={anchorEl}
                width="160px"
                rootStyles={styles.rootMenu}
                listStyles={styles.list}
                handleClose={handleClose}
                handleListKeyDown={handleListKeyDown}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {options.map((o, index) => {
                    const ActionComponent = Object.keys(
                        actionMenuComponents,
                    ).includes(o.decisionAction)
                        ? actionMenuComponents[o.decisionAction]
                        : actionMenuComponents['DoNothing']
                    return (
                        <ActionComponent
                            key={`${o.decisionId}_${index}`}
                            o={o}
                            onChange={() => {
                                setOpen(false)
                                onChange(o)
                            }}
                        />
                    )
                })}
                {isClearable && (
                    <MenuItem
                        onClick={() => {
                            setOpen(false)
                            onChange(null)
                        }}
                        className={classes['Clear']}
                    >
                        {t('MyTasks_Clear')}
                        <Icon
                            name={decisionActionIcons['Clear']}
                            color={decisionActionColors['Clear'].primary}
                        />
                    </MenuItem>
                )}
            </Dropdown>
        </Box>
    )
}

DecisionMenu.ContinueActionMenuItem = ContinueActionMenuItem
DecisionMenu.EndActionMenuItem = EndActionMenuItem
DecisionMenu.DoNothingActionMenuItem = DoNothingActionMenuItem

export default DecisionMenu
