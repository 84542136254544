import { Box, createStyles, makeStyles } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(
    createStyles({
        root: {
            margin: '24px 24px 24px 24px',
            width: '320px',
        },
        heading: {
            fontWeight: 'bold',
        },
        body: {
            marginTop: '16px',
        },
    }),
)

export const DecisionDetails = () => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <Box className={classes.root}>
            <Box className={classes.heading}>{t('MyTasks_Decision')}</Box>
            <Box className={classes.body}></Box>
        </Box>
    )
}
