import React from 'react'
import * as Icons from './'

const Icon = ({ icon, ...rest }) => {
    const IconComponent = Icons[`${icon}Icon`]
    if (IconComponent === undefined) {
        console.warn(`Icon with name: ${icon} not found.`)
        return <></>
    }
    return <IconComponent {...rest} />
}

export default Icon
