import { FC } from 'react'
import { useSubmitViolationItemDecision } from 'hooks'
import { ApproveRisk } from './ApproveRisk'
import { Box, makeStyles } from '@material-ui/core'
import { createStyles } from '@material-ui/core/styles'
import { RejectRisk } from './RejectRisk'
import { DoNothing } from './DoNothing'

const useStyles = makeStyles(
    createStyles({
        root: {
            display: 'flex',
        },
        button: {
            '& button': {
                minHeight: '47px',
            },
        },
    }),
)

export const ViolatingItemDecision: FC<{ item: any; decisions: any }> = (
    props,
) => {
    const classes = useStyles()
    const { item, decisions } = props

    const [
        submitRiskDecision,
        { isLoading: isSubmitting },
    ] = useSubmitViolationItemDecision(
        'RequestItems',
        item.businessRequestId,
        item.id,
    )

    return (
        <Box>
            {decisions && (
                <Box className={classes.root}>
                    {decisions.map((d: any) => (
                        <Box component="span" key={d.decisionId} marginX="12px">
                            {d.decisionAction === 'Continue' ? (
                                <Box className={classes.button}>
                                    <ApproveRisk
                                        currentApprovalStepId={
                                            item.currentApprovalStepId
                                        }
                                        onSubmit={(value) => {
                                            const approveReq = {
                                                ...value,
                                                stepId:
                                                    item.currentApprovalStepId,
                                                decisionId: d.decisionId,
                                            }
                                            return submitRiskDecision(
                                                approveReq,
                                            )
                                        }}
                                        decisionFriendlyName={
                                            d.decisionFriendlyName
                                        }
                                        isLoading={isSubmitting}
                                    />
                                </Box>
                            ) : d.decisionAction === 'DoNothing' ? (
                                <Box className={classes.button}>
                                    <DoNothing d={d} item={item} />
                                </Box>
                            ) : (
                                <Box className={classes.button}>
                                    <RejectRisk d={d} item={item} />
                                </Box>
                            )}
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    )
}
