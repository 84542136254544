import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import GenericFilter from '../GenericFilter'
import { useTranslation } from 'react-i18next'
import { usePageContext } from 'pageContext'
import { Button } from 'components'

const defaultValues = {
    text: '',
    checkbox: false,
    autocomplete: null,
    tree: null,
    checklist: [],
}

const FormsFilter = ({ filters }) => {
    const { t } = useTranslation()

    const [state, dispatch] = usePageContext()

    const initialState = {}
    for (let i = 0; i < filters.length; i++) {
        initialState[filters[i].contextProp] = defaultValues[filters[i].type]
    }
    const [fieldsState, setFieldsState] = useState(initialState)

    useEffect(() => {
        for (let i = 0; i < filters.length; i++) {
            const currentFilter = filters[i]
            if (
                state[currentFilter.contextProp] !==
                fieldsState[currentFilter.contextProp]
            ) {
                setFieldsState((prev) => ({
                    ...prev,
                    [currentFilter.contextProp]:
                        state[currentFilter.contextProp],
                }))
            }
        }
    }, [state])

    return (
        <>
            {filters.map((f) => (
                <Box key={f.name} padding="20px 20px 0px 20px">
                    <GenericFilter
                        value={fieldsState[f.contextProp]}
                        onChange={(val) =>
                            setFieldsState({
                                ...fieldsState,
                                [f.contextProp]: val,
                            })
                        }
                        {...f}
                    />
                </Box>
            ))}

            <Box
                padding="30px 20px 0px 20px "
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Box>
                    <Button.Search
                        onClick={() =>
                            dispatch({
                                type: 'SET_MULTIPLE_PROPS',
                                payload: fieldsState,
                            })
                        }
                    >
                        {t('MyTasks_Search')}
                    </Button.Search>
                </Box>
            </Box>
        </>
    )
}

export default FormsFilter
