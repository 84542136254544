import React from 'react'
import PropTypes from 'prop-types'
import { CardContent, Box, Divider, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Attribute from './Attribute'
import { RiskIndicator, StatusIndicator } from 'packages/eid-ui'
import { filter } from 'ramda'
import Person from 'components/Person'
import moment from 'moment'
import { getFullImageUrl } from 'utils'
import { ItemTypeCount, SubjectTypeAttribute } from 'components'

const CellDateTime = ({ value }) => (
    <Box fontWeight={700}>
        {value ? moment.utc(value).local().format('LLL') : '-'}
    </Box>
)

const useCardContentStyles = makeStyles({
    cardContent: (props) => ({
        padding: '0 20px 0px 20px',
        paddingLeft: props.mobile && '20px',
        marginTop: '20px',
    }),

    divider: {
        backgroundColor: '#e8e8e8',
        margin: '8px 0px',
        height: '0.5px',
    },
})

const Attributes = ({ item, cardStyles, mobile, attributes }) => {
    const { t } = useTranslation()

    const getNonHeaderAttributes = filter(
        (x) =>
            !x.isHeader &&
            x.name !== 'number' &&
            x.component?.name !== 'status',
    )

    const contentClasses = useCardContentStyles({ mobile })

    const itemStatus = item.preApproved ? 'PreApproved' : item.status
    const brStatus = item.businessRequestStatus

    return (
        <CardContent className={contentClasses.cardContent}>
            {item.itemTypeCount && (
                <>
                    <Box display="flex" width="100%" flexWrap="wrap">
                        <ItemTypeCount
                            itemTypeCount={item.itemTypeCount}
                            requestId={item.id}
                        />
                    </Box>
                    <Divider
                        orientation="horizontal"
                        variant="fullWidth"
                        className={contentClasses.divider}
                    />
                </>
            )}

            <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                {attributes
                    .filter((i) => i.name === 'number')
                    .map((a) => (
                        <Attribute
                            key={a.name}
                            label={t(a.label)}
                            value={item[a.name]}
                            orientation="horizontal"
                            labelProps={{
                                minWidth: '90px !important',
                                paddingRight: '10px',
                                fontWeight: 'bold !important',
                            }}
                            valueProps={{
                                fontWeight: 'bold !important',
                            }}
                            minWidth="185px"
                            alignItems="center"
                            style={{ minWidth: '150px' }}
                        />
                    ))}

                {/* {attributes
                    .filter((i) => i.name === 'status')
                    .map((a) => (
                        <Attribute
                            key={a.name}
                            label={t(`MyTasks_Status`)}
                            value={
                                <StatusIndicator
                                    variant={item[a.name]}
                                    label={t(`MyTasks_${item[a.name]}`)}
                                    styles={{ minWidth: '102px' }}
                                    {...a.component.props}
                                />
                            }
                            orientation="horizontal"
                            labelProps={{
                                minWidth: '70px !important',
                                fontWeight: 'bold !important',
                            }}
                            minWidth="136px"
                            alignItems="center"
                        />
                    ))} */}

                {attributes
                    .filter((i) => i.component && i.component.name === 'status')
                    .map((a, index) => (
                        <Attribute
                            key={index}
                            label={t(`MyTasks_Status`)}
                            value={
                                <StatusIndicator
                                    variant={itemStatus}
                                    label={t(`MyTasks_${itemStatus}`)}
                                    styles={{ minWidth: '102px' }}
                                    {...a.component.props}
                                />
                            }
                            orientation="horizontal"
                            labelProps={{
                                minWidth: '70px !important',
                                fontWeight: 'bold !important',
                            }}
                            minWidth="136px"
                            alignItems="center"
                        />
                    ))}
                {brStatus === 'Expired' && (
                    <StatusIndicator
                        size="large"
                        variant={brStatus}
                        label={t('MyTasks_BusinessRequestExpired')}
                        styles={{ minWidth: '200px' }}
                    />
                )}
            </Box>
            <Divider
                orientation="horizontal"
                variant="fullWidth"
                className={contentClasses.divider}
            />

            {item.currentApprovalStepName && (
                <Attribute
                    label={t('MyTasks_CurrentStep')}
                    value={item.currentApprovalStepName}
                    orientation="horizontal"
                    valueProps={{
                        display: 'flex !important',
                        alignItems: 'center',
                    }}
                    labelProps={{ fontWeight: 'bold !important' }}
                />
            )}

            {getNonHeaderAttributes(attributes).map((a) => {
                if (a.component && a.component.name === 'riskLevel') {
                    return (
                        <Box style={{ paddingTop: '15px' }}>
                            <Attribute
                                key={a.component.name}
                                label={t(a.label)}
                                value={
                                    item[a.component.name] ? (
                                        <RiskIndicator
                                            variant={item[a.component.name]}
                                            label={t(
                                                `MyTasks_${
                                                    item[a.component.name]
                                                }Risk`,
                                            )}
                                            {...a.component.props}
                                        />
                                    ) : (
                                        '-'
                                    )
                                }
                                valueProps={{ maxWidth: '80px' }}
                                labelProps={{ fontWeight: 'bold !important' }}
                                orientation="horizontal"
                                alignItems="center"
                            />
                        </Box>
                    )
                }

                const value = (
                    <>
                        {a.avatar ? (
                            <Person
                                name={item[a.name]}
                                imgSrc={getFullImageUrl(item[a.imgProp])}
                                size="medium"
                                style={{ fontSize: '14px', color: ' #307fc1' }}
                                page="my_request_card"
                            />
                        ) : !a.link ? (
                            item[a.name]
                        ) : (
                            <Link>{item[a.name]}</Link>
                        )}
                    </>
                )
                return (
                    <Attribute
                        style={{ paddingTop: '11px' }}
                        key={a.name}
                        label={t(a.label)}
                        value={
                            a.name === 'createdDateUtc' ? (
                                <CellDateTime value={item[a.name]} />
                            ) : a.name === 'targetPersonFriendlyName' ? (
                                <SubjectTypeAttribute
                                    subjectType={item.subjectType}
                                    item={item}
                                    personComponentProps={{
                                        style: {
                                            fontSize: '14px',
                                            color: '#307fc1',
                                        },
                                        size: 'medium',
                                    }}
                                />
                            ) : (
                                value
                            )
                        }
                        orientation="horizontal"
                        valueProps={{
                            display: 'flex !important',
                            alignItems: 'center',
                            paddingTop: '10px !important',
                            ...a.cardStyles,
                        }}
                        labelProps={{ fontWeight: 'bold !important' }}
                    />
                )
            })}
        </CardContent>
    )
}

Attributes.propTypes = {
    item: PropTypes.object,
    assignmentDetails: PropTypes.object,
    className: PropTypes.string,
}

export default Attributes
