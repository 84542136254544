import React from 'react'
import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'
import { Box, styled, useMediaQuery } from '@material-ui/core'
import { mobileScreenWidth, tabletScreenWidth } from 'utils'
import { useIsSmallScreen } from 'packages/core'

const Container = styled(Box)({
    [`@media (max-width:${mobileScreenWidth}) `]: {
        display: 'none',
    },
})

const AppGlobalMenu = () => {
    const isSmallScreen = useIsSmallScreen()
    const isTablet = useMediaQuery(`(min-width:${tabletScreenWidth})`)
    return (
        <>
            {isTablet ? (
                <Container>
                    <DesktopMenu />
                </Container>
            ) : isSmallScreen ? (
                <MobileMenu />
            ) : (
                <Container>
                    <DesktopMenu />
                </Container>
            )}
        </>
    )
}

export default AppGlobalMenu
